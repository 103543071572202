import React, { useEffect } from "react";
import { withRouter } from "react-router";
import { useServices } from "../../../services/service-providers/service-provider";

const LogOutPage = () => {
  const { authService } = useServices();

  useEffect(() => {
    authService.logout();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <>loggin out</>;
};

export default withRouter(LogOutPage);
