import React, { FunctionComponent, FormEvent, useState } from "react";
import styles from "./LogIn.module.scss";
import { checkValidity } from "../../../../utils/utils";
import { Button } from "../../../../components/Button/Button";
import { Icon } from "../../../../components/Icon";
import { LoginForm } from "../../../../types/auth";
import { FormElement } from "../../../../types/form-types";
import { Input } from "../../../../components/shadcn/Input";
import { useTranslation } from "react-i18next";

type Props = {
  logInError?: string;
  onLogIn: (username: string, password: string) => void;
};

type LogInState = {
  login_form: LoginForm;
  formIsValid: boolean;
};

const initialState: LogInState = {
  formIsValid: false,
  login_form: {
    username_field: {
      elementType: "input",
      elementConfig: {
        type: "text",
        placeholder: "Username",
      },
      value: "",
      validation: {
        required: true,
      },
      valid: false,
      touched: false,
    },
    password_field: {
      elementType: "input",
      elementConfig: {
        type: "password",
        placeholder: "Password",
      },
      value: "",
      validation: {
        required: true,
        minLength: 6,
      },
      valid: false,
      touched: false,
    },
  },
};

const LogIn: FunctionComponent<Props> = ({ onLogIn, logInError }) => {
  const [state, setState] = useState(initialState);

  const { t } = useTranslation();

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    const updatedForm = {
      ...state.login_form,
      [fieldName]: {
        ...state.login_form[fieldName as keyof LoginForm],
        value: event.target.value,
        valid: checkValidity(
          event.target.value,
          state.login_form[fieldName as keyof LoginForm].validation
        ),
        touched: true,
      },
    };
    setState({ ...state, login_form: updatedForm });
  };

  const submitHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onLogIn(
      state.login_form.username_field.value,
      state.login_form.password_field.value
    );
  };

  const formElementsArray: FormElement[] = [];
  Object.keys(state.login_form).forEach((key) => {
    formElementsArray.push({
      id: key,
      config: state.login_form[key as keyof LoginForm],
    });
  });

  const extendedFormElementsArray = formElementsArray.map((formElement, i) => ({
    ...formElement,
    extendedData: {
      placeholder:
        i === 0 ? t("authPage.logInPage.usernamePlaceholder") : "**********",
      label:
        i === 0
          ? t("authPage.logInPage.username")
          : t("authPage.logInPage.password"),
    },
  }));

  let form = extendedFormElementsArray.map((formElement) => (
    <div className="flex flex-col gap-y-1 items-start">
      <p className="text-sm font-medium text-[#102833] leading-5">
        {formElement.extendedData.label}
      </p>
      <Input
        placeholder={formElement.extendedData.placeholder}
        className={"mb-4 w-full"}
        key={formElement.id}
        value={formElement.config.value}
        type={formElement.config.elementConfig.type}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          inputChangedHandler(event, formElement.id)
        }
      />
    </div>
  ));

  return (
    <div className="grid grid-cols-2 gap-4 min-h-screen">
      <div className="relative h-full">
        <img
          src="/login-nandi.webp"
          alt="Background Image"
          className="absolute inset-0 w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-[#140E3CCC]">
          <div className="absolute top-10 left-10">
            <Icon iconName={"login_logo_v2"} />
          </div>
          <p className="text-white text-[38px] font-medium absolute bottom-10 left-10 max-w-[500px]">
            {t("authPage.logInPage.introText")}
          </p>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <form
          className={"flex flex-col  w-[80%] max-w-[480px]"}
          onSubmit={submitHandler}
        >
          <h1 className="text-[#09090B] font-semibold text-2xl leading-9 text-left mb-4">
            {t("authPage.logInPage.welcome")}
          </h1>
          <div className={styles.formInputs}>{form}</div>
          {logInError ? (
            <div className={styles.errorBox}>
              <p className={styles.errorMessage}>{logInError}</p>
            </div>
          ) : null}
          <button
            className=" w-full inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-[#C24685] text-destructive-foreground hover:bg-[#C24685]/90 h-10 px-4 py-2"
            type="submit"
          >
            {t("authPage.logInPage.logInButton")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default LogIn;
