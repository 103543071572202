import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Alert } from "../../types/alerts";

import styles from "./AlertCard.module.scss";
import { AlertCardRow } from "./AlertCardRow/AlertCardRow";

interface AlertCardProps {
  alert: Alert;
  className?: string;
}

export const AlertCard: FunctionComponent<AlertCardProps> = ({
  alert,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <div className={[styles.alertCard, className].join(" ")}>
      <AlertCardRow
        className={styles.rowItem}
        iconName={"area"}
        title={alert.field_name}
      />
      <AlertCardRow
        className={styles.rowItem}
        iconName={"time"}
        title={`${alert.date.toLocaleTimeString(navigator.language, {
          hour: "2-digit",
          minute: "2-digit",
        })} - ${alert.date.toLocaleDateString()}`}
      />
      <AlertCardRow
        className={styles.rowItem}
        iconName={alert.entity_type.toLowerCase()}
        title={alert.entity_description}
      />
      <AlertCardRow
        className={styles.rowItem}
        iconName={"problem"}
        title={t(
          `alertsTable.description.${alert.alert_type} - ${alert.entity_type}`,
          { fieldName: alert.field_name }
        )}
      />
    </div>
  );
};
