import React, { FunctionComponent } from 'react';
import { Icon } from '../Icon';

import styles from './AlertPageButton.module.scss';

interface ButtonProps {
  title: string;
  iconName: string;
  onClick?: () => void;
  className: string;
}

export const AlertPageButton: FunctionComponent<ButtonProps> = (props) => (
  <div
    className={[styles.button, props.className].join(' ')}
    onClick={props.onClick}
  >
    <div className={styles.titleButtonContainer}>
      <div className={styles.title}>{props.title}</div>
      <div className={styles.titleIconContainer}>
        <Icon iconName={props.iconName} />
      </div>
    </div>
    <div className={styles.goToIconContainer}>
      <Icon iconName={'arrow_forward'} />
    </div>
  </div>
);
