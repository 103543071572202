export const parseMemberType = (typeString: string): MemberType => {
  const type = typeString.toUpperCase() as MemberType;
  if (type === undefined) {
    return "ALL";
  }

  return type;
};

export type MemberWithInteraction = {
  member_type: MemberType;
  member_id: number;
  increased_performance: boolean;
  amount: number;
};

export type OldMemberCount = {
  member_type: MemberType;
  count: number;
  members_in_alert_status: number;
};

export type NextMemberResponse = {
  nextMemberId: number;
};

export const getMembersTypeName = (sensorType: string) => {
  switch (sensorType) {
    case "BULL":
      return "Bulls";
    case "COW":
      return "Cows";
    case "DRINKER":
      return "Drinkers";
    default:
      return "";
  }
};

export const sortMembersByAlert = (x: Member) => {
  // true values first
  return x.status === "ALERT" ? -1 : 1;
  // false values first
  // return (x === y)? 0 : x? 1 : -1;
};

export const sortMembersAlphabetically = (a: Member, b: Member) => {
  if (a.tag_id < b.tag_id) {
    return -1;
  }
  if (b.tag_id < a.tag_id) {
    return 1;
  }
  return 0;
};
