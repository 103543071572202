import React, { FunctionComponent } from "react";
import { FieldSummaryWithExtraDetails } from "../../../types/fields";
import classNames from "classnames";
import { InventoryDetailComponent } from "../InventoryDetail";
import { useTranslation } from "react-i18next";
import { AccountAttribute } from "../../types/Account";
import { Image } from "../../../components/Image/Image";

type Props = {
  fields: Array<FieldSummaryWithExtraDetails>;
  members: Member[];
  className?: string;
  climateData?: AccountAttribute | null;
};

export const ReducedInventoryMonitor: FunctionComponent<Props> = ({
  members,
  fields,
  className,
  climateData,
}) => {
  const { t } = useTranslation();

  const totalNandiBulls =
    members.filter((m) => m.member_type === "BULL").length ?? 0;

  const totalNandiCows =
    members.filter((m) => m.member_type === "COW").length ?? 0;

  const [temp, description, icon] =
    climateData?.attribute_value?.split("/") ?? [];

  const imageUrl = `https://openweathermap.org/img/wn/${icon}@2x.png`;
  return (
    <section
      className={classNames(className, "flex flex-col items-start w-full")}
    >
      <div className="rounded bg-white  pl-4 py-4 flex gap-8 w-full">
        <InventoryDetailComponent
          label={t("entityTypes.CowsWithSensors")}
          count={String(totalNandiCows)}
          iconName="inventory-cow"
        />
        <InventoryDetailComponent
          label={t("entityTypes.BullsWithSensors")}
          count={String(totalNandiBulls)}
          iconName="inventory-bull"
        />
        {climateData ? (
          <div className="flex flex-col flex-start items-start w-full">
            <div className="flex w-full">
              <Image
                imageLink={imageUrl}
                alt="climate status"
                className={"h-8 w-8"}
              />
              <p
                className={
                  "text-[#0F172A] text-[1.5rem] font-bold tracking-[0.015rem]"
                }
              >
                {Math.round(Number(temp)) + "°"}
              </p>
            </div>
            <p
              className={
                "text-[#0F172A] text-[0.75rem] font-normal tracking-[0.015rem] whitespace-nowrap text-center"
              }
            >
              {description}
            </p>
          </div>
        ) : null}
      </div>
    </section>
  );
};
