import classNames from "classnames";
import React, { FunctionComponent } from "react";
import { Icon } from "../../Icon";

import styles from "./AlertCardRow.module.scss";

interface AlertCardRowProps {
  iconName: string;
  title: string;
  className: string;
}

export const AlertCardRow: FunctionComponent<AlertCardRowProps> = (props) => {
  return (
    <div className={classNames(styles.alertCardRow, props.className)}>
      <div className={styles.iconContainer}>
        <Icon iconName={props.iconName} />
      </div>
      <div className={styles.title}>{props.title}</div>
    </div>
  );
};
