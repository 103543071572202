import React, { FunctionComponent, ReactNode } from "react";
import classNames from "classnames";
import { Icon } from "../../../../components/Icon";
import { useTranslation } from "react-i18next";
import { AlertGralStatus } from "../../../../types/alerts";

type MetricDetailData = {
  type: "change-rate";
  value: number;
  valueLabel?: string;
  nandiCattles?: { bulls: number; cows: number };
  description?: string;
  renderableContent?: ReactNode;
};

type Props = {
  title: string;
  className?: string;
  data?: MetricDetailData;
  disabled?: boolean;
  lastAlerts?: number;
  gralStatus?: AlertGralStatus;
  iconName?: string;
};

type MetricDetailCardSectionProps = {
  className?: string;
  disabled?: boolean;
};

type MetricDetailCardDataProps = {
  data: MetricDetailData;
  lastAlerts?: number;
  gralStatus?: AlertGralStatus;
};

export const MetricDetailCardSection: FunctionComponent<
  MetricDetailCardSectionProps
> = ({ children, className, disabled = false }) => (
  <section
    className={classNames(
      " p-4 rounded-lg border bg-card text-card-foreground shadow-sm flex justify-between",
      className,
      "bg-white"
    )}
  >
    {children}
  </section>
);

export const MetricDetailCardTitle: FunctionComponent = ({ children }) => (
  <p className="text-[#09090B] text-base leading-5 font-medium">{children}</p>
);

export const MetricDetailCardData: FunctionComponent<
  MetricDetailCardDataProps
> = ({ data, lastAlerts, gralStatus }) => {
  const gralStatusColor: { [key: string]: string } = {
    [AlertGralStatus.HEALTHY]: "text-[#2A9D90]",
    [AlertGralStatus.WARNING]: "text-[#FBCE5B]",
    [AlertGralStatus.CRITICAL]: "text-[#DC2626]",
  };

  const { t } = useTranslation();
  return (
    <section className="mt-2">
      <div className="flex gap-1 items-baseline">
        {gralStatus ? (
          <p
            className={classNames(
              "font-semibold text-3xl",
              gralStatusColor[gralStatus]
            )}
          >
            {gralStatus}
          </p>
        ) : null}
        {!gralStatus ? (
          <p
            className={classNames(
              "font-semibold text-3xl leading-9 text-[#09090B]"
            )}
          >
            {data.value}
          </p>
        ) : null}
        {data.valueLabel ? (
          <span className="text-[#586A84] font-normal text-sm wrap">
            {data.valueLabel}
          </span>
        ) : null}
        {data.nandiCattles ? (
          <p className="text-[#586A84] font-normal ml-5 text-[20px] flex gap-[4px]">
            <span className="text-blue-600">{data.nandiCattles.bulls}</span>/
            <span className="text-[#8B008B]">{data.nandiCattles.cows}</span>
          </p>
        ) : null}
      </div>
      {data.description ? (
        <p className="mt-3 text-[#586A84]">{data.description}</p>
      ) : null}
      {lastAlerts || lastAlerts === 0 ? (
        <span className="mt-3 text-[#71717A] flex gap-1 text-sm">
          <p className="font-semibold">{lastAlerts}</p>
          {t("admin.mainPage.alerts.lastAlerts.description")}
        </span>
      ) : null}
    </section>
  );
};

const MetricDetailCardComponent: FunctionComponent<Props> = ({
  className,
  title,
  data,
  disabled,
  lastAlerts,
  gralStatus,
  iconName = "alerts-v2",
}) => (
  <MetricDetailCardSection className={className} disabled={disabled ?? false}>
    <div>
      <MetricDetailCardTitle>{title}</MetricDetailCardTitle>
      {data ? (
        <MetricDetailCardData
          data={data}
          lastAlerts={lastAlerts}
          gralStatus={gralStatus}
        />
      ) : null}
    </div>
    <Icon iconName={iconName} />
  </MetricDetailCardSection>
);

export default MetricDetailCardComponent;
