import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { connect, ConnectedProps } from "react-redux";
import * as XLSX from "xlsx";

// Components
import { Button } from "../../../../components/Button/Button";
import { Loader } from "../../../../components/Loader/Loader";
import { Table, TableColumn } from "../../../components/Table/Table";

// Lib
import { isMsgError } from "../../../../lib/error";
import { ServicesContext } from "../../../../services/service-providers/service-provider";
import { RootState } from "../../../../store/store";
import withLoaderHOC, {
  WithLoaderProps,
} from "../../../../utils/hocs/WithLoaderHOC";

type UploadSensorsPageState = {
  rows: Array<SensorCreate>;
};

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & WithLoaderProps;

const tableConstants = (): Array<TableColumn> => {
  return [
    {
      title: "BTN ID",
      key: "btn_id",
      render: (sensorCreate: SensorCreate) => {
        return <span>{sensorCreate.name}</span>;
      },
    },
    {
      title: "Sensor Type",
      key: "type",
      render: (sensorCreate: SensorCreate) => {
        return <span>{sensorCreate.sensor_type}</span>;
      },
    },
  ];
};

const UploadSensorsPage: FunctionComponent<Props> = ({
  selectedAccount,
  setLoading,
  setError,
  isLoading,
}) => {
  const { sensorsService } = useContext(ServicesContext);

  const [state, setState] = useState<UploadSensorsPageState>({ rows: [] });

  useEffect(() => {
    setLoading(false);
  }, []);

  const fileHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    parseExcel(event.target.files!![0]);
  };

  const parseExcel = (file: File) => {
    var reader = new FileReader();

    reader.onload = (e: any) => {
      var data = e.target.result;
      var workbook = XLSX.read(data, {
        type: "binary",
      });

      workbook.SheetNames.forEach((sheetName) => {
        const json_object = XLSX.utils.sheet_to_json(
          workbook.Sheets[sheetName]
        );
        setState({ rows: json_object as SensorCreate[] });
      });
    };

    reader.onerror = (ex) => {
      console.log(ex);
    };

    reader.readAsBinaryString(file);
  };

  const onClickUploadSensors = async () => {
    const uploadArray: Array<SensorCreate> = state.rows.map((row) => ({
      name: row.name,
      sensor_type: row.sensor_type,
      status: "NORMAL",
      account_id: String(selectedAccount.id),
    }));
    try {
      setLoading(true);
      const data = await sensorsService.createSensors(uploadArray);
      if (data) {
        setLoading(false);
      }
    } catch (error) {
      if (isMsgError(error)) setError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Loader isLoading={isLoading}>
      <input
        type="file"
        onChange={fileHandler}
        accept=".xlsx,.csv"
        className="p-3"
      />
      <Table columns={tableConstants()} items={state.rows} />
      <div className="mt-4 w-40">
        <Button
          disabled={state.rows.length === 0}
          onClick={onClickUploadSensors}
        >
          Upload Sensors
        </Button>
      </div>
    </Loader>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    selectedAccount: state.accounts.selectedAccount,
  };
};

const connector = connect(mapStateToProps);

export default connector(withLoaderHOC(UploadSensorsPage));
