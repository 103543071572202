import classNames from "classnames";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "../Icon";

interface MemberCardProps {
  className?: string;
  member: MemberWithDetails;
}

export const MemberCard: FunctionComponent<MemberCardProps> = ({
  className,
  member,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(className, "flex items-center p-6 rounded shadow")}
    >
      <div>
        <div>
          <div
            className={[
              "rounded-full w-3 h-3",
              member.status === "ALERT" ? "bg-red-700" : "bg-green-700",
            ].join(" ")}
          ></div>
        </div>
        <div>
          <Icon
            className="fill-current	text-color-blue w-[32px] h-[32px]"
            iconName={member.member_type.toLowerCase()}
          />
        </div>
      </div>
      <div className={"w-full ml-4"}>
        <div className={"w-full flex justify-between	"}>
          <div className="font-semibold">{t("memberCard.caravanId")}</div>
          <div>{member.tag_id}</div>
        </div>
        <div className={"w-full flex justify-between	"}>
          <div className="font-semibold">{t("memberCard.device")}</div>
          <div>{member.sensors?.name}</div>
        </div>
      </div>
    </div>
  );
};
