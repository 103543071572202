import { Alert } from "../types/alerts";
import {
  FieldAttachment,
  FieldCreate,
  FieldSummary,
  FieldSummaryWithExtraDetails,
  NdviData,
} from "../types/fields";
import request from "./request";
import { IFieldsService } from "./services-types";

export class ProductionFieldsService implements IFieldsService {
  getFieldsList = async (
    include_members: boolean,
    account_id: string
  ): Promise<Array<FieldSummary>> => {
    return await request({
      method: "get",
      url: "/api/fields",
      params: {
        include_members: include_members,
        account_id,
      },
    });
  };

  getFields = async (
    account_id: string
  ): Promise<Array<FieldSummaryWithExtraDetails>> => {
    return await request({
      method: "GET",
      url: "/fields",
      baseURL: process.env.REACT_APP_NEW_API_URL,
      params: {
        account_id,
      },
    });
  };

  getFieldAttachments = async (
    account_id: string
  ): Promise<Array<FieldAttachment>> => {
    return await request({
      method: "GET",
      url: `/fields/attachments/${account_id}`,
      baseURL: process.env.REACT_APP_NEW_API_URL,
    });
  };

  getFieldsWithMembersInventory = async (
    account_id: string
  ): Promise<Array<FieldSummaryWithExtraDetails>> => {
    return await request({
      method: "GET",
      url: "/fields",
      baseURL: process.env.REACT_APP_NEW_API_URL,
      params: {
        account_id,
        includeMembersInventory: true,
      },
    });
  };

  getNvdiByTile = async (tile: string): Promise<NdviData[]> => {
    return await request({
      method: "GET",
      url: "/fields/nvdi/getnvdi",
      baseURL: process.env.REACT_APP_NEW_API_URL,
      params: {
        tile,
      },
    });
  };

  getFieldById = async (
    fieldId: string
  ): Promise<FieldSummaryWithExtraDetails> => {
    return await request({
      method: "GET",
      url: `/fields/${fieldId}`,
      baseURL: process.env.REACT_APP_NEW_API_URL,
    });
  };

  getFieldByIdWithMembersInventory = async (
    fieldId: string
  ): Promise<FieldSummaryWithExtraDetails> => {
    return await request({
      method: "GET",
      url: `/fields/${fieldId}`,
      params: {
        includeMembersInventory: true,
      },
      baseURL: process.env.REACT_APP_NEW_API_URL,
    });
  };

  getMembersByFieldIdAndMemberType = async (
    fieldId: string,
    memberType: string
  ): Promise<Array<MemberWithDetails>> => {
    if (memberType !== "ALL") {
      return await request({
        method: "get",
        url: `/api/fields/${fieldId}/members/${memberType}`,
      });
    } else {
      return await request({
        method: "get",
        url: `/api/fields/${fieldId}/members`,
      });
    }
  };

  getFieldAlerts = async (fieldId: string): Promise<Array<Alert>> => {
    return await request({
      method: "GET",
      url: `/fields/${fieldId}/alerts`,
      baseURL: process.env.REACT_APP_NEW_API_URL,
    });
  };

  createAccountFields = async (fields: Array<FieldCreate>) => {
    return await request({
      method: "post",
      data: fields,
      url: `/api/fields`,
    });
  };

  createFieldInventory = async (inventory: Inventory) => {
    return await request({
      method: "post",
      url: `/fields/inventory`,
      data: inventory,
      baseURL: process.env.REACT_APP_NEW_API_URL,
    });
  };
  getFieldsInventory = async (accountId: string) => {
    return await request({
      method: "get",
      url: `/fields/inventory/${accountId}`,
      baseURL: process.env.REACT_APP_NEW_API_URL,
    });
  };
}
