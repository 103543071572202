import classNames from "classnames";
import React, { FormEvent, FunctionComponent } from "react";
import { Input } from "../../../components/Input/Input";
import { Loader } from "../../../components/Loader/Loader";
import { FormElement } from "../../../types/form-types";

import styles from "./FormModal.module.scss";

type FormModalProps = {
  displayModal: boolean;
  title: string;
  formElementsArray: Array<FormElement>;
  formSubmitErrorMessage: string;
  inputChangedHandler: (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => void;
  onTouchEventHandler: (
    event: React.FocusEvent<Element>,
    fieldId: string
  ) => void;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  onClose: () => void;
  isLoading: boolean;
};

export const FormModal: FunctionComponent<FormModalProps> = ({
  children,
  isLoading,
  inputChangedHandler,
  onTouchEventHandler,
  onClose,
  onSubmit,
  title,
  displayModal,
  formElementsArray,
}) => {
  let form = formElementsArray.map((formElement) => (
    <React.Fragment key={formElement.id}>
      <div className={styles.formInput}>
        <Input
          elementType={formElement.config.elementType}
          elementConfig={formElement.config.elementConfig}
          value={formElement.config.value}
          invalid={!formElement.config.valid && formElement.config.touched}
          touched={formElement.config.touched}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            inputChangedHandler(event, formElement.id)
          }
          onBlur={(event: React.FocusEvent<Element>) => {
            onTouchEventHandler(event, formElement.id);
          }}
        />
        {!formElement.config.valid ? (
          <div className={styles.inputValidationMessage}>
            {formElement.config.validation.message}
          </div>
        ) : null}
      </div>
    </React.Fragment>
  ));

  return (
    <>
      <div
        onClick={() => onClose()}
        className={classNames(styles.modalBackground, {
          [`${styles.modalDisplay}`]: displayModal,
        })}
      />
      <div
        className={classNames(styles.modalContent, {
          [`${styles.modalDisplay}`]: displayModal,
        })}
      >
        <div className={styles.modalTitle}>{title}</div>
        <form className={styles.formBodyContainer} onSubmit={onSubmit}>
          <Loader isLoading={isLoading}>
            <div className={styles.formInputs}>{form}</div>
            {children}
          </Loader>
        </form>
      </div>
    </>
  );
};
