import {
  CreateSanitaryReportDto,
  SanitaryReport,
} from "../types/sanitary-reports";
import request from "./request";
import { ISanitaryReportsService } from "./services-types";

export class SanitaryReportsService implements ISanitaryReportsService {
  create = async (
    createSanitaryReportDto: CreateSanitaryReportDto
  ): Promise<any> => {
    return await request({
      method: "POST",
      url: "/sanitary-reports",
      data: createSanitaryReportDto,
      baseURL: process.env.REACT_APP_NEW_API_URL,
    });
  };
  findByPaddock = async (fieldId: number): Promise<SanitaryReport[]> => {
    return await request({
      method: "GET",
      url: `/sanitary-reports/${fieldId}`,
      baseURL: process.env.REACT_APP_NEW_API_URL,
    });
  };
  remove = async (id: number): Promise<any> => {
    return await request({
      method: "DELETE",
      url: `/sanitary-reports/${id}`,
      baseURL: process.env.REACT_APP_NEW_API_URL,
    });
  };
}
