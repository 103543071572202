import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const alertTypeTooltipStyles = makeStyles((theme: Theme) =>
  createStyles({
    "RUN AWAY": {
      backgroundColor: "#00FF00",
      color: "white",
      fontWeight: "bold",
      borderRadius: "20px",
      "&:hover": {
        backgroundColor: "#FF00A0",
      },
    },
    "NO RECEPTION": {
      backgroundColor: "#0000FF",
      color: "white",
      fontWeight: "bold",
      borderRadius: "20px",
      "&:hover": {
        backgroundColor: "#0000A0",
      },
    },
    CATTLE_WITHOUT_EVENTS_24_HS: {
      backgroundColor: "#663399",
      color: "white",
      fontWeight: "bold",
      borderRadius: "20px",
      "&:hover": {
        backgroundColor: "#6633CC",
      },
    },
    CATTLE_WITHOUT_EVENTS_48_HS: {
      backgroundColor: "#FF0000",
      color: "white",
      fontWeight: "bold",
      borderRadius: "20px",
      "&:hover": {
        backgroundColor: "#E19989",
      },
    },
    CATTLE_LOW_ACTIVITY_24_HS: {
      backgroundColor: "#FFA500",
      color: "white",
      fontWeight: "bold",
      borderRadius: "20px",
      "&:hover": {
        backgroundColor: "#FFA540",
      },
    },
  })
);
