import React, { FunctionComponent } from "react";

//Types
import { HealthCalendarEvent as HealthCalendarEventType } from "../../../../../types/health-calendar";
//Utils
import { useTranslation } from "react-i18next";
import { Icon } from "../../../../../components/Icon";

type Props = {
  event: HealthCalendarEventType;
  handleViewDetail: (event: HealthCalendarEventType) => void;
};

export const HealthCalendarEvent: FunctionComponent<Props> = ({
  event,
  handleViewDetail,
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex justify-between items-center w-full py-4">
      <div className="flex  gap-2 w-3/4 justify-between items-center">
        <div className="flex justify-start items-center gap-x-2">
          <div className="min-w-[42px] min-h-[42px] bg-[#F7F7F7] rounded-full relative">
            <Icon
              iconName="calendar"
              className="absolute top-[8.5px] left-[8.5px]"
            />
          </div>
          <p className="font-inter font-medium text-sm leading-[21px] text-[#09090B]">
            {event.name}
          </p>
        </div>
        <p className="text-sm max-w-[381px] font-medium leading-[21px]  text-[#71717A]">
          {event.detail}
        </p>
      </div>
      <button
        onClick={() => handleViewDetail(event)}
        className={
          "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2"
        }
      >
        {t("admin.mainPage.healthCalendar.event.viewDetail")}
      </button>
    </div>
  );
};
