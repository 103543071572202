import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const buttonStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      backgroundColor: "#6668A4",
      color: "white",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#2F3080",
        border: "none",
      },
    },
  })
);
