import request from "./request";
import { IMessagesService } from "./services-types";

export class ProductionMessagesService implements IMessagesService {
  getMessages = async (
    accountid?: string | number,
    rodeoid?: string | number,
    fieldid?: string | number
  ): Promise<Message[]> => {
    return await request({
      method: "GET",
      url: "/messages",
      baseURL: process.env.REACT_APP_NEW_API_URL,
      params: {
        accountid,
        rodeoid,
        fieldid,
      },
    });
  };
  createMessage = async (
    rodeo_id: string | number,
    field_id: string | number,
    text: string
  ): Promise<Message> => {
    console.log(rodeo_id, field_id);
    return await request({
      method: "POST",
      url: "/messages",
      baseURL: process.env.REACT_APP_NEW_API_URL,
      data: {
        rodeo_id,
        field_id,
        text,
        type: "INFORMATION",
      },
    });
  };
}
