import React, { FC, useEffect, useState } from "react";

import { Modal } from "../../../../../components/Modal/Modal.component";
import { Image } from "../../../../../components/Image/Image";

import { useServices } from "../../../../../services/service-providers/service-provider";
import { Icon } from "../../../../../components/Icon";

interface Props {
  member: Member;
  onClose: () => void;
  pictures: string[];
}

const MemberProfilePicturesModal: FC<Props> = ({
  member,
  onClose,
  pictures,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % pictures.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + pictures.length) % pictures.length
    );
  };

  return (
    <Modal
      onClose={() => {
        onClose();
      }}
    >
      <div className="relative w-full max-w-lg">
        <div className="overflow-hidden">
          <div
            className="flex transition-transform duration-300 ease-in-out"
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            {pictures.map((picture, index) => (
              <div key={index} className="w-full flex-shrink-0 p-1">
                <div className="bg-white rounded-lg shadow-md">
                  <div className="flex aspect-square pictures-center justify-center p-6">
                    <Image
                      className="w-full max-w-[600px] h-full max-h-[400px]"
                      imageLink={picture}
                      alt={member.tag_id}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {pictures.length > 1 ? (
          <>
            <button
              onClick={prevSlide}
              className="absolute top-1/2 left-1 -translate-y-1/2 bg-white/80 text-gray-800 p-2 rounded-full shadow hover:bg-white"
              aria-label="Anterior"
            >
              <Icon iconName="arrow_right" className="w-6 h-6 rotate-180" />
            </button>
            <button
              onClick={nextSlide}
              className="absolute top-1/2 right-1 -translate-y-1/2 bg-white/80 text-gray-800 p-2 rounded-full shadow hover:bg-white"
              aria-label="Siguiente"
            >
              <Icon iconName="arrow_right" className="w-6 h-6" />
            </button>
          </>
        ) : null}
      </div>
    </Modal>
  );
};

export default MemberProfilePicturesModal;
