import React from "react";
import { FunctionComponent } from "react";

interface Props {
  checkedFields?: string[];
  fields?: { id: string; name: string }[];
  onCheck: (checked: string[]) => void;
}

const CheckBoxList: FunctionComponent<Props> = ({
  checkedFields = [],
  fields = [],
  onCheck,
}) => {
  return (
    <ol>
      {fields.map((field) => (
        <li key={field.id}>
          <input
            type="checkbox"
            checked={checkedFields?.includes(field.id)}
            onChange={(e) => {
              if (e.target.checked) {
                onCheck([...checkedFields!, field.id]);
              } else {
                onCheck(checkedFields.filter((_field) => _field !== field.id));
              }
            }}
          />
          <span className="ml-1">{field.name}</span>
        </li>
      ))}
    </ol>
  );
};

export default CheckBoxList;
