import React, { FunctionComponent, InputHTMLAttributes } from "react";
import { Icon } from "../../Icon";

type SelectOption = {
  value: any;
  displayValue: string;
};

interface SelectProps extends InputHTMLAttributes<HTMLSelectElement> {
  className?: string;
  options: Array<SelectOption>;
  selectedOption: SelectOption;
  iconType?: "chevron-down" | "chevron-up-down";
  defaultLabel?: string;
}

export const Select: FunctionComponent<SelectProps> = ({
  className,
  options,
  selectedOption,
  iconType = "chevron-down",
  defaultLabel,
  ...props
}) => {
  return (
    <div className="relative">
      <button
        type="button"
        className="flex h-10 items-center justify-between rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
      >
        <span style={{ pointerEvents: "none" }}>
          {!selectedOption.value && defaultLabel
            ? defaultLabel
            : selectedOption.displayValue}
        </span>
        {iconType === "chevron-up-down" ? (
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="lucide lucide-chevron-down h-4 w-4 opacity-50"
          >
            <path
              d="M8.25 3.75L6 1.5L3.75 3.75"
              stroke="#0F172A"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8.25 8.25L6 10.5L3.75 8.25"
              stroke="#0F172A"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        ) : iconType === "chevron-down" ? (
          <Icon iconName="chevron-right" className="rotate-90" />
        ) : null}
      </button>
      <select
        value={selectedOption.value}
        {...props}
        aria-hidden="true"
        className="absolute bottom-0 p-0 m-[-1px] w-[92%] h-full opacity-0"
      >
        {options.map((option, index) => {
          return (
            <option value={option.value} key={index}>
              {option.displayValue}
            </option>
          );
        })}
      </select>
    </div>
  );
};
