import classNames from "classnames";
import React, { FunctionComponent, ReactNode } from "react";
import styles from "./tab.module.scss";

type TabProps = {
  onClick: () => any;
  label: any;
  selected: boolean;
  mode?: string;
};

type TabsProps = {
  children: ReactNode;
};

const Tab = ({ onClick, mode = "tab", label, selected }: TabProps) => (
  <div
    className={classNames({
      [`${styles.buttonTab}`]: mode === "button",
      [`${styles.tab}`]: mode === "tab",
      [`${styles.tabSelected}`]: selected,
    })}
    onClick={onClick}
  >
    <div className={classNames({ [`${styles.labelSelected}`]: selected })}>
      {label}
    </div>
  </div>
);

export const Tabs: FunctionComponent<TabsProps> = ({ children }) => (
  <div className={styles.tabsContainer}>{children}</div>
);

export default Tab;
