import React, { FunctionComponent, FormEvent, useState } from "react";
import { Button } from "../../components/Button/Button";
import { Input } from "../../components/Input/Input";
import { checkValidity } from "../../utils/utils";
import logo from "../../assets/logo.svg";
import { Image } from "../../components/Image/Image";
import styles from "./Auth.module.scss";
import { RouteComponentProps, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { LoginForm } from "../../types/auth";
import { WithTranslation, withTranslation } from "react-i18next";
import classNames from "classnames";

interface LogInProps {
  logInError?: string;
  onLogIn: (username: string, password: string) => void;
}

interface LogInState {
  login_form: LoginForm;
  formIsValid: boolean;
}

type Props = LogInProps & RouteComponentProps & WithTranslation;

const initialState = {
  formIsValid: false,
  login_form: {
    username_field: {
      elementType: "input",
      elementConfig: {
        type: "text",
        autoComplete: "username",
        placeholder: "username",
      },
      value: "",
      validation: {
        required: true,
      },
      valid: false,
      touched: false,
    },
    password_field: {
      elementType: "input",
      elementConfig: {
        autoComplete: "current-password",
        type: "password",
        placeholder: "password",
      },
      value: "",
      validation: {
        required: true,
        minLength: 6,
      },
      valid: false,
      touched: false,
    },
  },
};

const LogIn: FunctionComponent<Props> = ({ t, match, logInError, onLogIn }) => {
  const [state, setState] = useState<LogInState>(initialState);

  const onInputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    const updatedForm = {
      ...state.login_form,
      [fieldName]: {
        ...state.login_form[fieldName as keyof LoginForm],
        value: event.target.value,
        valid: checkValidity(
          event.target.value,
          state.login_form[fieldName as keyof LoginForm].validation
        ),
        touched: true,
      },
    };
    setState({ ...state, login_form: updatedForm });
  };

  const onSubmitHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onLogIn(
      state.login_form.username_field.value,
      state.login_form.password_field.value
    );
  };

  return (
    <div className={styles.loginPage}>
      <Image className={styles.headerLogin} imageLink={logo} />
      <form onSubmit={onSubmitHandler}>
        <div className={styles.body}>
          <div>
            <Input
              className={styles.formInput}
              elementType={state.login_form.username_field.elementType}
              elementConfig={{
                ...state.login_form.username_field.elementConfig,
                placeholder: t("authPage.logInPage.username"),
              }}
              value={state.login_form.username_field.value}
              invalid={logInError ? true : false}
              touched={state.login_form.username_field.touched}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onInputChangedHandler(event, "username_field")
              }
            />
            <Input
              className={styles.formInput}
              elementType={state.login_form.password_field.elementType}
              elementConfig={{
                ...state.login_form.password_field.elementConfig,
                placeholder: t("authPage.logInPage.password"),
              }}
              value={state.login_form.password_field.value}
              invalid={logInError ? true : false}
              touched={state.login_form.password_field.touched}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onInputChangedHandler(event, "password_field")
              }
            />
          </div>
          {logInError ? (
            <div className={styles.errorBox}>
              <p className={styles.errorMessage}>
                {t(`authPage.logInPage.${logInError}`)}
              </p>
            </div>
          ) : null}
        </div>
        <div className={classNames(styles.btnForm, "mt-4")}>
          <Button>{t("authPage.logInPage.logInButton")}</Button>
          <div className={styles.forgotPassword}>
            <Link to={`${match.url}passwordRecovery`}>
              {t("authPage.logInPage.forgotPassword")}
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default withTranslation()(withRouter(LogIn));
