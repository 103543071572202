import { IDevToolsService } from "./services-types";

export class DevToolsService implements IDevToolsService {
  getIsDebug = () => {
    return Promise.resolve().then(() => {
      const isDebug = localStorage.getItem("is_debug");
      return Boolean(isDebug);
    });
  };
  setIsDebug = (isDebug: boolean) => {
    return Promise.resolve().then(() => {
      return localStorage.setItem("is_debug", String(isDebug));
    });
  };
}
