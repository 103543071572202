import moment from "moment";
import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { BoxPlotChart } from "@sgratzl/chartjs-chart-boxplot";

// Components
import { DatePicker } from "../../../../components/DatePicker";
import { LoaderWithRender } from "../../../../components/Loader/Loader";
// Services
import { useServices } from "../../../../services/service-providers/service-provider";
// Utils
import withLoaderHOC, {
  WithLoaderProps,
} from "../../../../utils/hocs/WithLoaderHOC";

type NDVIChartProps = {
  paddockName: string;
  polygonId: string;
};

const getToday = (): number => Number(moment.utc().format("X"));

const NDVIChartBase: FunctionComponent<NDVIChartProps & WithLoaderProps> = ({
  paddockName,
  polygonId,
  isLoading,
  setLoading,
}) => {
  const { agroService } = useServices();
  const [startDate, setStartDate] = useState(1593108387);
  const [endDate, setEndDate] = useState(getToday());
  const [agroStats, setAgroStats] = useState<AgroStatsInfo[] | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const loadInfo = async () => {
    try {
      setLoading(true);
      const agroStats = await agroService.getByPolygonId(
        polygonId,
        startDate,
        endDate
      );

      setAgroStats(agroStats);
    } catch (e) {
      setAgroStats(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadInfo();
    return () => {};
  }, [polygonId, startDate, endDate]);

  const getDataSets = (agroStats: AgroStatsInfo[]) => {
    const getDefault = () => [
      {
        label: paddockName,
        backgroundColor: "rgba(0,255,125,0.4)",
        data: agroStats
          .sort((a, b) => (Number(a.dt) > Number(b.dt) ? 1 : -1))
          .map((a) => ({ ...a.data })),
      },
    ];

    return getDefault();
  };

  const getLabels = (agroStats: AgroStatsInfo[]) => {
    const getDefault = () => {
      return agroStats
        .sort((a, b) => (Number(a.dt) > Number(b.dt) ? 1 : -1))
        .map((x) => moment.unix(x.dt).format("DD/MM/YYYY"));
    };

    return getDefault();
  };

  useEffect(() => {
    let myChart: any;
    if (canvasRef && canvasRef.current) {
      console.log("got canvas");
      const ctx = canvasRef.current.getContext("2d");
      if (ctx) {
        const data = agroStats
          ? {
              labels: getLabels(agroStats),
              datasets: getDataSets(agroStats),
            }
          : null;
        console.log("trying to draw", data);
        if (data) {
          ctx.clearRect(
            0,
            0,
            canvasRef.current.width,
            canvasRef.current.height
          );
          myChart = new BoxPlotChart(ctx, {
            data: data,
          });
        }
      }
    }

    return () => {
      if (myChart) (myChart as any).destroy();
    };
  }, [agroStats]);

  const handleStartDateChange: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    setStartDate(Number(moment(e.target.value).unix()));
  };

  const handleEndDateChange: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    setEndDate(Number(moment(e.target.value).unix()));
  };

  const handleStartYearChange: React.ChangeEventHandler<HTMLSelectElement> = (
    e
  ) => {
    setStartDate(Number(moment(e.target.value).unix()));
  };

  const handleEndYearChange: React.ChangeEventHandler<HTMLSelectElement> = (
    e
  ) => {
    setEndDate(Number(moment(e.target.value).unix()));
  };

  return (
    <>
      <header className="flex p-4">
        <div>
          <p>Start Date</p>

          <DatePicker
            value={moment.unix(startDate).format("YYYY-MM-DD")}
            onChange={handleStartDateChange}
          />
        </div>
        <div className="ml-2">
          <p>End Date</p>
          <DatePicker
            value={moment.unix(endDate).format("YYYY-MM-DD")}
            onChange={handleEndDateChange}
          />
        </div>
      </header>
      <LoaderWithRender isLoading={isLoading}>
        {!agroStats ? (
          <p>
            No NDVI information for this period or try recharging the page for
            long periods of time.
          </p>
        ) : null}
      </LoaderWithRender>
      <section className="mt-4 max-h-[350px]">
        <canvas ref={canvasRef} id="canvas"></canvas>
      </section>
    </>
  );
};

export const NDVIChart = withLoaderHOC(NDVIChartBase);
