import { Color } from "./color";

const MemberEvent = {
  getByType: (memberEvents: Array<MemberEvent>, type: MemberEventType) => {
    return memberEvents.filter((x) => x.type === type);
  },

  sortByTs: (memberEventA: MemberEvent, memberEventB: MemberEvent) => {
    if (memberEventA.ts > memberEventB.ts) return 1;
    else {
      return -1;
    }
  },

  getAttribute: (memberEvent: MemberEvent, attribute: string) => {
    return memberEvent.attributes.find((mE) => mE.attribute === attribute);
  },

  generateContactsWithCattleDataset: (
    memberEvents: Array<MemberEvent>,
    dates: Array<number>
  ) => {
    const cattle: {
      [key: string]: { label: string; valuesPerDate: any; data: Array<number> };
    } = {};

    memberEvents.forEach((me) => {
      if (cattle[me.value as string]) {
        cattle[me.value] = {
          ...cattle[me.value],
          valuesPerDate: {
            ...cattle[me.value].valuesPerDate,
            [`${me.ts}`]:
              Number(MemberEvent.getAttribute(me, "TIME")?.value) / 60,
          },
        };
      } else {
        cattle[me.value] = {
          label: me.value,
          valuesPerDate: {
            [`${me.ts}`]:
              Number(MemberEvent.getAttribute(me, "TIME")?.value) / 60,
          },
          data: [],
        };
      }
    });

    dates.forEach((date) => {
      Object.keys(cattle).forEach((cattleU) => {
        if (cattle[cattleU].valuesPerDate[date]) {
          cattle[cattleU].data.push(cattle[cattleU].valuesPerDate[date]);
        } else {
          cattle[cattleU].data.push(0);
        }
      });
    });

    return Object.keys(cattle)
      .map((k) => cattle[k])
      .map((e) => ({
        label: e.label,
        data: e.data,
        backgroundColor: Color.generateRandom(),
      }));
  },
};

export { MemberEvent };
