import moment from "moment";
import React, { FunctionComponent } from "react";
import { Bar } from "react-chartjs-2";
import CattlesTable from "./CattlesTable";

type Props = {
  memberEventsCount: Array<{
    date: string;
    member_events: number;
    member_messages: number;
  }>;
  memberEventsLastDay: Array<MemberEventsLastDay>;
};

const DashboardPage: FunctionComponent<Props> = ({
  memberEventsCount,
  memberEventsLastDay,
}) => {
  const days = memberEventsCount.map((x) => {
    const date = moment(x.date);
    return date.format("DD/MM/YYYY");
  });

  return (
    <section className="w-full pt-11  pl-11">
      {memberEventsCount.length > 0 ? (
        <>
          <div className="w-1/2 max-w-[1300px] pt-11">
            <Bar
              className="mt-4 h-[284px]"
              data={{
                labels: days,
                datasets: [
                  {
                    label: "Member events",
                    data: memberEventsCount.map((x) => x.member_events),
                    backgroundColor: "#2a363b",
                  },
                  {
                    label: "Member messages",
                    data: memberEventsCount.map((x) => x.member_messages),
                    backgroundColor: "#6d7e84",
                  },
                ],
              }}
            />
          </div>
          <CattlesTable cattles={memberEventsLastDay} />
        </>
      ) : null}
    </section>
  );
};

export { DashboardPage };
