import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import NANDI_URL from "../../../lib/url";
import { getMembersTypeName, OldMemberCount } from "../../../types/members";
import { Icon } from "../../Icon";
import { HeaderMembersInfo } from "../HeaderMembersInfo/HeaderMembersInfo";

import styles from "./HeaderMembersInfoContainer.module.scss";

interface HeaderMembersInfoContainerProps {
  membersCount: Array<OldMemberCount>;
}

export const HeaderMembersInfoContainer: FunctionComponent<
  HeaderMembersInfoContainerProps
> = ({ membersCount }) => {
  return (
    <div className={styles.headerMembersInfoContainer}>
      {membersCount.map((member, index) => (
        <HeaderMembersInfo
          key={index}
          className={styles.membersInfoItem}
          memberType={member.member_type?.toLowerCase()}
          membersCount={member.count}
          membersInAlertStatus={member.members_in_alert_status}
          memberName={getMembersTypeName(member.member_type)}
        />
      ))}
      <Link to={NANDI_URL.APP.MEMBERS} className={styles.arrowIcon}>
        <Icon iconName={"arrow_forward"} />
      </Link>
    </div>
  );
};
