import React from "react";
import { FunctionComponent } from "react";
import NANDI_URL from "../../../lib/url";
import { Icon } from "../../Icon";
import "./FooterGauchoApp.scss";
import { FooterGauchoAppLink } from "./FooterGauchoAppLink/FooterGauchoAppLink";

type FooterProps = {};

export const FooterGauchoApp: FunctionComponent<FooterProps> = ({}) => {
  const menuLinks = [
    {
      linkName: "Home",
      linkIcon: "home_gauchoapp",
      linkTo: NANDI_URL.APP.GAUCHOAPPDOWNLOAD,
    },
    {
      linkName: "Carga",
      linkIcon: "home_gauchoapp",
      linkTo: NANDI_URL.APP.GAUCHOAPPUPLOAD,
    },
  ];

  return (
    <div className="footerGauchoApp_container">
      {menuLinks.map((link) => {
        return (
          <FooterGauchoAppLink
            key={link.linkTo}
            linkName={link.linkName}
            linkIcon={link.linkIcon}
            linkTo={link.linkTo}
            className={"fotterGauchoApp_icon"}
          />
        );
      })}
    </div>
  );
};
