import React, { FunctionComponent, useEffect, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

// Components
import MetricsSectionComponent from "../common/metrics-section";
import MetricDetailCardComponent from "../common/metric-detail-card";
import { useServices } from "../../../services/service-providers/service-provider";
import { Alert, AlertGralStatus } from "../../../types/alerts";
import { isMsgError } from "../../../lib/error";
import { Link } from "react-router-dom";
import NANDI_URL from "../../../lib/url";
import { Theme, createStyles, makeStyles } from "@material-ui/core";
import moment from "moment";

type Props = {
  className?: string;
  selectedAccount: any;
  alertsPerPaddock?: boolean;
  fieldId?: string;
  memberIds: { id: number }[];
  membersWithConnectivityAlerts?: number;
};

const AlertsMonitorContainer: FunctionComponent<Props> = ({
  className,
  selectedAccount,
  alertsPerPaddock,
  fieldId,
  memberIds,
  membersWithConnectivityAlerts,
}) => {
  const [alerts, setAlerts] = useState<Alert[] | []>([]);
  const [gralStatus, setGralStatus] = useState<{
    activity: number;
    connectivity: number;
  } | null>(null);
  const { alertsService } = useServices();
  const { t } = useTranslation();
  const getAlerts = async () => {
    if (selectedAccount && selectedAccount.id) {
      if (alertsPerPaddock && fieldId) {
        await alertsService
          .getAlertsV2({
            account_id: selectedAccount.id,
            field_id: fieldId,
            current_status: ["RENEWED", "CREATED"],
          })
          .then((alertsData) => setAlerts(alertsData))
          .catch((err) => {
            if (isMsgError(err)) throw new Error(err);
          });
      } else {
        await alertsService
          .getAlertsV2({
            account_id: selectedAccount.id,
            current_status: ["RENEWED", "CREATED"],
          })
          .then((alertsData) => setAlerts(alertsData))
          .catch((err) => {
            if (isMsgError(err)) throw new Error(err);
          });
      }
    }
  };

  const connectivityAlertQuantity = () =>
    alerts.filter(
      (alert) => alert.alert_type === "CATTLE_WITHOUT_EVENTS_48_HS"
    );

  const connectivityAlertLast24Hs = () =>
    connectivityAlertQuantity().filter((x) => {
      return (
        moment(x.date).unix() > moment().subtract(24, "hours").unix() &&
        x.current_status === "CREATED"
      );
    });

  const totalAlertsLast24Hs = () =>
    alerts.filter((x) => {
      return (
        moment(x.date).unix() > moment().subtract(24, "hours").unix() &&
        x.current_status === "CREATED"
      );
    });

  const lowActivityAlertQuantity = () =>
    alerts.filter((alert) => alert.alert_type === "CATTLE_LOW_ACTIVITY_24_HS");

  const lowActivityAlertLast24Hs = () =>
    lowActivityAlertQuantity().filter((x) => {
      return (
        moment(x.date).unix() > moment().subtract(24, "hours").unix() &&
        x.current_status === "CREATED"
      );
    });

  const alertConnectivityRoute = fieldId
    ? `${NANDI_URL.ADMIN.ALERTS}?alert_type=CATTLE_WITHOUT_EVENTS_48_HS&field_id=${fieldId}&current_status=RENEWED+CREATED`
    : `${NANDI_URL.ADMIN.ALERTS}?alert_type=CATTLE_WITHOUT_EVENTS_48_HS&current_status=RENEWED+CREATED`;

  const alertLowActivityRoute = fieldId
    ? `${NANDI_URL.ADMIN.ALERTS}?alert_type=CATTLE_LOW_ACTIVITY_24_HS&field_id=${fieldId}&current_status=RENEWED+CREATED`
    : `${NANDI_URL.ADMIN.ALERTS}?alert_type=CATTLE_LOW_ACTIVITY_24_HS&current_status=RENEWED+CREATED`;

  useEffect(() => {
    getAlerts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const lowActivityFilteredAlerts = () => {
    const setMemberIds = new Set<number>();
    return lowActivityAlertQuantity().filter((alert) => {
      if (!setMemberIds.has(alert.entity_id)) {
        setMemberIds.add(alert.entity_id);
        return true;
      }
      return false;
    });
  };

  useEffect(() => {
    setGralStatus({
      connectivity:
        ((membersWithConnectivityAlerts ?? 0) * 100) / memberIds.length,
      activity: (lowActivityFilteredAlerts().length * 100) / memberIds.length,
    });
  }, [memberIds, membersWithConnectivityAlerts, alerts]);

  const gralStatusLabel = (value: number) =>
    value < 10
      ? AlertGralStatus.HEALTHY
      : value >= 10 && value < 40
      ? AlertGralStatus.WARNING
      : AlertGralStatus.CRITICAL;

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      alertButton: {
        backgroundColor: "red",
        color: "white",
        textTransform: "none",
        "&:hover": {
          backgroundColor: "#d32f2f",
          border: "none",
        },
      },
      healthButton: {
        backgroundColor: "green",
        color: "white",
        textTransform: "none",
        cursor: "default",
        "&:hover": {
          backgroundColor: "green",
        },
      },
    })
  );

  const MUIStyles = useStyles();

  return (
    <section className={classNames(className, "bg-transparent")}>
      <MetricsSectionComponent>
        <Link to={NANDI_URL.ADMIN.ALERTS}>
          <MetricDetailCardComponent
            lastAlerts={totalAlertsLast24Hs().length}
            className="cursor-pointer"
            title={t("admin.mainPage.alerts.totalAlerts.title")}
            disabled={connectivityAlertQuantity().length <= 0}
            data={{
              type: "change-rate",
              value: alerts.length,
            }}
            // gralStatus={AlertGralStatus.HEALTHY}
          />
        </Link>
        <Link to={alertLowActivityRoute}>
          <MetricDetailCardComponent
            gralStatus={
              gralStatus !== null
                ? gralStatusLabel(gralStatus.activity)
                : undefined
            }
            lastAlerts={lowActivityAlertLast24Hs().length}
            disabled={lowActivityAlertQuantity().length === 0}
            title={t("admin.mainPage.alerts.activity.title")}
            data={{
              type: "change-rate",
              value: lowActivityAlertQuantity().length,
            }}
            iconName="activity-alerts"
          />
        </Link>
        <Link to={alertConnectivityRoute}>
          <MetricDetailCardComponent
            gralStatus={
              gralStatus !== null
                ? gralStatusLabel(gralStatus.connectivity)
                : undefined
            }
            lastAlerts={connectivityAlertLast24Hs().length}
            className="cursor-pointer"
            title={t("admin.mainPage.alerts.connectivity.title")}
            disabled={connectivityAlertQuantity().length <= 0}
            data={{
              type: "change-rate",
              value: connectivityAlertQuantity().length,
            }}
            iconName="connectivity-alerts"
          />
        </Link>
      </MetricsSectionComponent>
    </section>
  );
};

export default AlertsMonitorContainer;
