import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  InputLabel,
  TextField,
  TextareaAutosize,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import moment from "moment";
import React, { ChangeEvent, FormEvent, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  openModal: boolean;
  setOpenModal: (openModal: boolean) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  handleChange: (
    event: ChangeEvent<{ name?: unknown; value: unknown }>
  ) => void;
  handleCancel: () => void;
};
export const CreateSanitaryReportModal: FunctionComponent<Props> = ({
  openModal,
  setOpenModal,
  handleSubmit,
  handleChange,
  handleCancel,
}) => {
  const { t } = useTranslation();

  const currentDate = moment().format("YYYY-MM");
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      form: {
        width: 400,
        backgroundColor: "white",
        gap: "24px",
        display: "flex",
        flexDirection: "column",
        padding: "32px",
      },
      label: {
        textAlign: "left",
        width: "100%",
        fontSize: "13px",
      },
      input: {
        width: "100%",
      },
      textarea: {
        resize: "none",
        border: "1px solid grey",
      },
    })
  );

  const MUIStyles = useStyles();
  return (
    <Dialog
      open={openModal}
      onClose={() => setOpenModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t("monitorPage.newSanitaryReport.title")}
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent className={MUIStyles.form}>
          <TextField
            id="date"
            label={t("monitorPage.newSanitaryReport.date")}
            type="month"
            className={MUIStyles.input}
            defaultValue={currentDate}
            onChange={handleChange}
            name={"date"}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Box>
            <InputLabel id="label" className={MUIStyles.label}>
              {t("monitorPage.newSanitaryReport.accountable")}
            </InputLabel>
            <Input
              className={MUIStyles.input}
              onChange={handleChange}
              name={"accountable"}
            />
          </Box>
          <TextareaAutosize
            aria-label="minimum height"
            minRows={3}
            className={MUIStyles.textarea}
            onChange={handleChange}
            name={"detail"}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            {t("monitorPage.newSanitaryReport.cancel")}
          </Button>
          <Button color="primary" type="submit">
            {t("monitorPage.newSanitaryReport.accept")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
