import React, { FunctionComponent, useContext } from "react";
import { ProductionAccountsService } from "../accounts-service";
import { ProductionAlertsService } from "../alerts-service";
import { ProductionAuthService } from "../auth-service";
import { DevToolsService } from "../dev-tools-service";
import { ProductionFieldsService } from "../fields-service";
import { ProductionFilesService } from "../files-service";
import { ProductionMembersService } from "../members-service";
import { ProductionSensorsService } from "../sensors-service";
import { UsersService } from "../users-service";
import { AgroService } from "../agro-service";
import { TimelineService } from "../timeline-service";
import {
  IAccountsService,
  IAlertsService,
  IAuthService,
  IDevToolsService,
  IFieldsService,
  IFilesService,
  IMembersService,
  ISensorsService,
  IUsersService,
  IAgroService,
  ITimelineService,
  IInsightsService,
  IHealthCalendarService,
  ISanitaryReportsService,
  IRodeosService,
  IMessagesService,
  IStorageService,
} from "../services-types";
import { InsightsService } from "../insights-service";
import { HealthCalendarService } from "../health-calendar-service";
import { SanitaryReportsService } from "../sanitary-reports-service";
import { ProductionRodeosService } from "../rodeos-service";
import { ProductionMessagesService } from "../messages-service";
import { ProductionStorageService } from "../storage-service";

export type ServiceProviderInterface = {
  accountsService: IAccountsService;
  alertsService: IAlertsService;
  authService: IAuthService;
  fieldsService: IFieldsService;
  membersService: IMembersService;
  sensorsService: ISensorsService;
  filesService: IFilesService;
  devToolsService: IDevToolsService;
  usersService: IUsersService;
  agroService: IAgroService;
  timelineService: ITimelineService;
  insightsService: IInsightsService;
  healthCalendarEventsService: IHealthCalendarService;
  sanitaryReportsService: ISanitaryReportsService;
  rodeoService: IRodeosService;
  messageService: IMessagesService;
  storageService: IStorageService;
};

const productionServices: ServiceProviderInterface = {
  accountsService: new ProductionAccountsService(),
  alertsService: new ProductionAlertsService(),
  authService: new ProductionAuthService(),
  fieldsService: new ProductionFieldsService(),
  membersService: new ProductionMembersService(),
  sensorsService: new ProductionSensorsService(),
  filesService: new ProductionFilesService(),
  devToolsService: new DevToolsService(),
  usersService: new UsersService(),
  agroService: new AgroService(),
  timelineService: new TimelineService(),
  insightsService: new InsightsService(),
  healthCalendarEventsService: new HealthCalendarService(),
  sanitaryReportsService: new SanitaryReportsService(),
  rodeoService: new ProductionRodeosService(),
  messageService: new ProductionMessagesService(),
  storageService: new ProductionStorageService(),
};

export const ServicesContext =
  React.createContext<ServiceProviderInterface>(productionServices);

export const ServicesProvider: FunctionComponent = ({ children }) => {
  return (
    <ServicesContext.Provider value={productionServices}>
      {children}
    </ServicesContext.Provider>
  );
};

export const useServices = () => {
  const context = useContext(ServicesContext);

  if (context === undefined) {
    throw new Error("using services outside of provider");
  }

  return context;
};
