import React, { FunctionComponent, useContext, useEffect } from "react";
import { BrowserRouter, Route, RouteProps, Switch } from "react-router-dom";

import { AdminHomePage, AdminHomePageV2 } from "./pages/home";
import UsersPage from "./containers/UsersPage/UsersPage";
import SensorsPage from "./containers/SensorsPage/SensorsPage";
import ReportsPage from "./containers/ReportsPage/ReportsPage";
import AccountsPage, {
  AccountPage,
} from "./containers/AccountsPage/AccountsPage";
import CattlePageContainer from "./pages/cattle";
import withLoaderHOC, { WithLoaderProps } from "../utils/hocs/WithLoaderHOC";
import { Loader } from "../components/Loader/Loader";
import { connect, ConnectedProps } from "react-redux";
import { Account } from "./types/Account";
import { Dispatch } from "react";
import { PayloadAction } from "@reduxjs/toolkit";
import { accountsActions } from "../store/slices/accounts-slice";
import { ServicesContext } from "../services/service-providers/service-provider";
import { RootState } from "../store/store";
import { UserRole } from "../types/auth";
import { genericError, isMsgError } from "../lib/error";
import UserPage from "./pages/user";
import NANDI_URL from "../lib/url";
import { CattleMemberPageContainer } from "./pages/cattle/CattleMemberPageContainer";
import { AlertsPageContainer } from "./pages/alerts/AlertsPageContainer";
import { AlertPageContainer } from "./pages/alerts/AlertPageContainer";
import { Notification } from "../components/Notification/Notification";
import { PaddockByIdPage } from "./pages/paddock/PaddockById.page";
import { PaddockByIdV2Page } from "./pages/paddock/PaddockByIdV2.page";
import { useFields } from "../context/fields";
import { NotificationStatus } from "../types/notifications";
import TopNavbarContainer from "../components/TopNavbar/TopNavbar.Container";
import { InsightsPage } from "./pages/insights";
import { MemberProvider } from "../context/member";
import { DashboardPageContainer } from "./pages/dashboard/DashboardPageContainer";
import { InventoryPageContainer } from "./pages/inventory/InventoryPageContainer";

type Props = WithLoaderProps & PropsFromRedux;

const RouteWithAccounts: FunctionComponent<
  RouteProps & { selectedAccount: any }
> = ({ selectedAccount, ...rest }) => (
  <>
    {selectedAccount ? (
      <Route {...rest} />
    ) : (
      <Notification
        status={NotificationStatus.INFORMATION}
        className="m-2 w-max"
      >
        No accounts created for this user
      </Notification>
    )}
  </>
);

const AdminApp: FunctionComponent<Props> = ({
  selectedAccount,
  setLoading,
  user,
  saveAccounts,
  setSelectedAccount,
  setError,
  isLoading,
}) => {
  const { accountsService, authService, fieldsService } =
    useContext(ServicesContext);
  const fieldsContext = useFields();

  const getAccounts = async () => {
    try {
      if (user?.role === UserRole.SUPERUSER) {
        let data: Account[] | null = await accountsService.getAccounts();
        data = user?.CustomerRep ? data.filter((a) => !a.demo) : data;
        console.log("accounts for ", data, user);
        if (data) {
          try {
            const accountId = localStorage.getItem("account_id");
            if (accountId !== null) {
              setSelectedAccount(
                data.find((x) => x.id === Number(accountId)) ?? user.account
              );
            } else {
              setSelectedAccount(user.account);
            }
            saveAccounts(data);
          } catch (err) {
            setSelectedAccount(user.account);
            if (isMsgError(err)) {
              setError(err);
            } else {
              setError(genericError());
            }
          }
        }
      } else {
        const data = await accountsService.getAccountById(user?.account_id!!);
        setSelectedAccount(data);
        saveAccounts([data]);
      }
    } catch (error) {
      if (isMsgError(error)) {
        setError(error);
      } else {
        setError(genericError());
      }
    } finally {
      setLoading(false);
    }
  };

  const getFields = async () => {
    if (selectedAccount && selectedAccount.id) {
      fieldsContext.dispatch({ type: "request" });
      try {
        const fields = await fieldsService.getFields(selectedAccount.id);
        fieldsContext.dispatch({ type: "success", fields: fields });
      } catch (e) {
        fieldsContext.dispatch({
          type: "failure",
          error: "Couldn't get the fields",
        });
      }
    }
  };

  useEffect(() => {
    getAccounts();
    getFields();
  }, []);

  const onLogout = () => {
    authService.logout();
  };

  return (
    <BrowserRouter>
      <Loader isLoading={isLoading}>
        <main className="h-full">
          <TopNavbarContainer onLogout={onLogout} />
          <MemberProvider>
            <Switch>
              <Route
                exact
                path={NANDI_URL.ADMIN.MAIN}
                component={AdminHomePageV2}
              />
              {/**
               * Old route and version, just keeping it as a reference
               *  */}
              <Route
                exact
                path={NANDI_URL.ADMIN.MAIN_OLD}
                component={AdminHomePage}
              />
              <Route exact path={NANDI_URL.ADMIN.USER} component={UserPage} />
              <RouteWithAccounts
                selectedAccount={selectedAccount}
                exact
                path={NANDI_URL.ADMIN.ACCOUNT_BY_ID}
                render={({ match }) => (
                  <AccountPage
                    accountId={match.params.accountId}
                    signUpFlow={false}
                  />
                )}
              />
              <Route
                exact
                path={NANDI_URL.ADMIN.ACCOUNTS}
                component={AccountsPage}
              />

              <RouteWithAccounts
                selectedAccount={selectedAccount}
                exact
                path={NANDI_URL.ADMIN.ALERTS}
                component={AlertsPageContainer}
              />

              <RouteWithAccounts
                selectedAccount={selectedAccount}
                exact
                path={NANDI_URL.ADMIN.ALERT_BY_ID}
                render={({ match }) => (
                  <AlertPageContainer alertId={match.params.alertId} />
                )}
              />
              <RouteWithAccounts
                selectedAccount={selectedAccount}
                exact
                path={NANDI_URL.ADMIN.USERS}
                component={UsersPage}
              />
              <RouteWithAccounts
                selectedAccount={selectedAccount}
                path={NANDI_URL.ADMIN.SENSORS}
                component={SensorsPage}
              />
              <RouteWithAccounts
                selectedAccount={selectedAccount}
                exact
                path={NANDI_URL.ADMIN.INVENTORY}
                component={InventoryPageContainer}
              />
              <RouteWithAccounts
                selectedAccount={selectedAccount}
                exact
                path={NANDI_URL.ADMIN.CATTLE_BY_ID}
                render={({ match }) => (
                  <CattleMemberPageContainer memberId={match.params.memberId} selectedAccount={selectedAccount}/>
                )}
              />
              <RouteWithAccounts
                selectedAccount={selectedAccount}
                exact
                path={NANDI_URL.ADMIN.CATTLE}
                component={CattlePageContainer}
              />
              <RouteWithAccounts
                selectedAccount={selectedAccount}
                exact
                path={NANDI_URL.ADMIN.REPORTS}
                component={ReportsPage}
              />
              <RouteWithAccounts
                selectedAccount={selectedAccount}
                exact
                path={NANDI_URL.ADMIN.PADDOCK_BY_ID}
                render={({ match }) => (
                  <PaddockByIdV2Page
                    paddockId={match.params.paddockId}
                    selectedAccount={selectedAccount}
                  />
                )}
              />
              <RouteWithAccounts
                selectedAccount={selectedAccount}
                exact
                path={NANDI_URL.ADMIN.PADDOCK_BY_ID_OLD}
                render={({ match }) => (
                  <PaddockByIdPage paddockId={match.params.paddockId} />
                )}
              />
              <RouteWithAccounts
                selectedAccount={selectedAccount}
                exact
                path={NANDI_URL.ADMIN.INSIGHTS}
                component={InsightsPage}
              />
              <RouteWithAccounts
                selectedAccount={selectedAccount}
                exact
                path={NANDI_URL.ADMIN.DASHBOARD}
                component={DashboardPageContainer}
              />
            </Switch>
          </MemberProvider>
        </main>
      </Loader>
    </BrowserRouter>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    selectedAccount: state.accounts.selectedAccount
      ? {
          ...state.accounts.selectedAccount,
          id: String(state.accounts.selectedAccount.id),
        }
      : null,
    user: state.users.user,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<PayloadAction<Array<Account> | Account>>
) => {
  return {
    saveAccounts: (accounts: Array<Account>) =>
      dispatch(accountsActions.saveAccounts(accounts)),
    setSelectedAccount: (account: Account) =>
      dispatch(accountsActions.setSelectedAccount(account)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withLoaderHOC(AdminApp));
