import request from "./request";
import { IInsightsService } from "./services-types";

export class InsightsService implements IInsightsService {
  getInsights = async (
    accountId: string | number,
    fields: string[],
    filters?: { member_type?: "COW" | "BULL" | "ALL"; member_field_id?: string }
  ): Promise<any> => {
    return await request({
      method: "GET",
      url: `/insights/${accountId}`,
      baseURL: process.env.REACT_APP_NEW_API_URL,
      params: {
        fields,
        member_type: filters?.member_type,
        member_field_id: filters?.member_field_id,
      },
    });
  };
}
