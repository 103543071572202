import React, { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Pill } from "../../../components/Pill/Pill";
import NANDI_URL from "../../../lib/url";
import { useServices } from "../../../services/service-providers/service-provider";
import { Alert, AlertEvent } from "../../../types/alerts";
import {
  PageSubTitle,
  PageTitle,
} from "../../../components/PageTitle/PageTitle.component";
import { Table } from "../../components/Table/Table";
import { Button } from "@material-ui/core";
import withLoaderHOC, {
  WithLoaderProps,
} from "../../../utils/hocs/WithLoaderHOC";
import { Loader } from "../../../components/Loader/Loader";

const AlertPageContainerL: FunctionComponent<
  WithLoaderProps & { alertId?: string }
> = ({ alertId, isLoading, setLoading }) => {
  const [state, setState] = useState<{
    alert: Alert | null;
    alertEvents: Array<AlertEvent> | null;
  }>({
    alert: null,
    alertEvents: null,
  });
  const { alertsService } = useServices();
  const { t } = useTranslation();

  const loadPageInfo = async () => {
    if (alertId) {
      setLoading(true);
      const alertData = await alertsService.getAlertById(alertId);
      const alertEventsData = await alertsService.getAlertByIdEvents(alertId);

      setState((prevState) => ({
        ...prevState,
        alert: alertData,
        alertEvents: alertEventsData,
      }));
      setLoading(false);
    }
  };

  const closeAlert = async (e: any) => {
    e.preventDefault();
    if (alertId) {
      setLoading(true);
      alertsService
        .closeAlert(alertId)
        .then(() => {
          loadPageInfo();
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    loadPageInfo();
  }, []);

  if (!state.alert || !state.alertEvents) return null;

  const d = new Date(state.alert.date);

  return (
    <Loader isLoading={isLoading}>
      <header className="bg-gray-100 p-4">
        <div className="flex items-center justify-between">
          <p className="text-gray-500">#{state.alert.id}</p>
          <Button
            disabled={state.alert.current_status === "CLOSED"}
            type="button"
            onClick={closeAlert}
            variant="contained"
          >
            Archive
          </Button>
        </div>
        <p className="text-gray-500">
          {`${d.toLocaleDateString()} - ${d.toLocaleTimeString()}`}
        </p>
        <div className="flex items-center justify-between">
          <PageTitle>{`${t(
            `alertsTable.description.${state.alert.alert_type} - ${state.alert.entity_type}`,
            { fieldName: state.alert.field_name }
          )}`}</PageTitle>
          <Pill>{t(`alertsTable.status.${state.alert.current_status}`)}</Pill>
        </div>
      </header>
      <section className="ml-4 mt-8">
        <PageSubTitle>{t(`alertsTable.involvedCattleTitle`)}</PageSubTitle>
        {state.alert.entity_type === "FIELD" ? (
          "All the cattle"
        ) : (
          <Link
            className="block w-max p-2 mt-2 shadow rounded"
            to={NANDI_URL.ADMIN.GENERATE_CATTLE_BY_ID(
              String(state.alert.entity_id)
            )}
          >
            EID: {state.alert.entity_description}
          </Link>
        )}
      </section>
      <section className="ml-4 mr-4 mt-4 p-4">
        <Table
          items={state.alertEvents}
          isPaginationEnabled={false}
          columns={[
            {
              title: t("alertsTable.statusTitle"),
              key: "status",
              render: (alertEvent: AlertEvent) => (
                <>{t(`alertsTable.status.${alertEvent.status}`)}</>
              ),
            },
            {
              title: t("alertsTable.lastUpdatedDateTitle"),
              key: "date",
              render: (alertEvent: AlertEvent) => {
                const d = new Date(alertEvent.date);
                const date = d.toLocaleDateString();
                const time = d.toLocaleTimeString();
                return <>{`${date} - ${time}`}</>;
              },
            },
            {
              key: "user_involved",
              title: t("alertsTable.userTitle"),
              render: (alertEvent: AlertEvent) =>
                alertEvent.user ? alertEvent.user.full_name : "-",
            },
            {
              key: "note",
              title: t("alertsTable.noteTitle"),
              render: (alertEvent: AlertEvent) =>
                alertEvent.note ? alertEvent.note : "-",
            },
          ]}
        ></Table>
      </section>
    </Loader>
  );
};
export const AlertPageContainer = withLoaderHOC(AlertPageContainerL);
