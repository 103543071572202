import React, { FunctionComponent, useRef, useState } from "react";
import { Icon } from "../../Icon";
import { User } from "../../../types/auth";
import useClickOutside from "../../../utils/hooks/useClickOutside";

type Props = {
  onLogout: () => void;
  user: User | null;
};

export const UserDropdown: FunctionComponent<Props> = ({ onLogout, user }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);
  useClickOutside(ref, () => setIsVisible(false));
  return (
    <div className="relative" ref={ref}>
      <div onClick={() => setIsVisible(!isVisible)} className="cursor-pointer">
        <Icon iconName="user_icon" />
      </div>
      {isVisible ? (
        <ul className="absolute bg-white rounded-lg border bg-card text-card-foreground shadow-sm flex flex-col justify-between right-2">
          {user?.full_name ? (
            <li className={"px-3 pt-2 pb-1font-semibold"}>{user?.full_name}</li>
          ) : null}
          {user?.email ? (
            <li
              className={
                "text-[#71717A] px-3 pb-2 border-b-solid border-b-[1px] border-b-[#E4E4E7]"
              }
            >
              {user?.email}
            </li>
          ) : null}
          <li
            className={"px-3 py-2 text-[#18181B] flex gap-2 cursor-pointer"}
            onClick={onLogout}
          >
            <Icon iconName="logout" />
            Log out
          </li>
        </ul>
      ) : null}
    </div>
  );
};
