import React, {
  FunctionComponent,
  Dispatch,
  useState,
  useContext,
  useEffect,
} from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import withIsLoading from "../../../utils/hocs/withIsLoading";
import LogIn from "./LogIn/LogIn";
import AdminApp from "../../AdminApp";
import { connect, ConnectedProps } from "react-redux";
import { User } from "../../../types/auth";
import { PayloadAction } from "@reduxjs/toolkit";
import { usersActions } from "../../../store/slices/users-slice";
import { ServicesContext } from "../../../services/service-providers/service-provider";
import { isApiError } from "../../../lib/error";

type AdminAuthPageState = {
  isLoggedIn: boolean;
  logInError?: string;
  isLoading: boolean;
};

type AdminAuthPageProps = {
  setLoading: (isLoading: boolean) => void;
};

type Props = AdminAuthPageProps & PropsFromRedux;

const initialState: AdminAuthPageState = {
  isLoggedIn: false,
  isLoading: true,
};

const AdminAuthPage: FunctionComponent<Props> = ({ setLoading, saveUser }) => {
  const [state, setState] = useState(initialState);
  const { authService } = useContext(ServicesContext);

  useEffect(() => {
    setLoading(false);
    onTrySignedIn();
  }, []);

  const logIn = async (username: string, password: string) => {
    setLoading(true);
    try {
      const logInApiResponse = await authService.adminLogIn(username, password);
      localStorage.setItem("access_token", logInApiResponse.access_token);
      localStorage.setItem("token_type", logInApiResponse.token_type);
      onTrySignedIn();
    } catch (error) {
      if (isApiError(error) && error.response.status === 401) {
        setState({
          ...state,
          logInError: error.response.data.detail,
          isLoading: false,
        });
      } else {
        setState({
          ...state,
          logInError: "General Error: Please try again",
          isLoading: false,
        });
      }
    }

    setLoading(false);
  };

  const onTrySignedIn = async () => {
    if (localStorage.getItem("access_token") !== null) {
      const checkedUserMetaData = await checkUserMetaData();
      if (checkedUserMetaData) {
        setState({ ...state, isLoggedIn: true, isLoading: false });
      } else {
        setState({ ...state, isLoggedIn: false, isLoading: false });
      }
    } else {
      setState({ ...state, isLoggedIn: false, isLoading: false });
    }
  };

  const checkUserMetaData = async () => {
    try {
      const user = await authService.checkUserMetadata();
      if (user) {
        saveUser(user);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  if (!state.isLoading) {
    if (!state.isLoggedIn) {
      return (
        <BrowserRouter>
          <Switch>
            <Route
              path="/admin"
              exact
              render={(props) => (
                <LogIn
                  onLogIn={logIn}
                  logInError={state.logInError}
                  {...props}
                />
              )}
            />
          </Switch>
        </BrowserRouter>
      );
    } else {
      return <AdminApp />;
    }
  } else {
    return <div />;
  }
};

const mapDispatchToProps = (dispatch: Dispatch<PayloadAction<User>>) => {
  return {
    saveUser: (user: User) => dispatch(usersActions.saveUser(user)),
  };
};

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withIsLoading(AdminAuthPage));
