import request from "./request";
import { ISensorsService } from "./services-types";

export class ProductionSensorsService implements ISensorsService {
  getSensorsV2 = async (account_id: string): Promise<Array<Sensor>> => {
    return await request({
      method: "get",
      url: `/sensors?account_id=${account_id}`,
      baseURL: process.env.REACT_APP_NEW_API_URL,
    });
  };

  removeSensorById = async (id: number): Promise<any> => {
    return await request({
      method: "DELETE",
      url: `/sensors/${id}`,
      baseURL: process.env.REACT_APP_NEW_API_URL,
    });
  };

  createSensors = async (sensors: SensorCreate[]): Promise<Array<Sensor>> => {
    return await request({
      method: "post",
      data: sensors,
      url: `/api/sensors/`,
    });
  };
}
