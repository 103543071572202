import React, { FunctionComponent } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import NANDI_URL from "../../lib/url";
import { useAppSelector } from "../../store/hooks/redux-hooks";
import TopNavbar from "./TopNavbar";
import { UserRole } from "../../types/auth";

interface TopNavbarProps {
  onLogout: () => void;
}

const TopNavBarContainer: FunctionComponent<
  WithTranslation & TopNavbarProps
> = ({ t, onLogout }) => {
  const user = useAppSelector((state) => state.users.user);
  const isSuperUser = user?.role === UserRole.SUPERUSER;
  const topNavBarLinks = [
    {
      key: "ALERTS",
      href: NANDI_URL.ADMIN.ALERTS,
      iconName: "alerts",
      label: t("admin.sideBar.alerts"),
    },
    {
      key: "CATTLE",
      href: NANDI_URL.ADMIN.CATTLE,
      iconName: "cow",
      label: t("admin.sideBar.cattle"),
    },
  ];

  const superUserNavbarLinks = topNavBarLinks.concat([
    {
      key: "SENSORS",
      href: NANDI_URL.ADMIN.SENSORS,
      iconName: "sensors",
      label: t("admin.sideBar.sensors"),
    },
    {
      key: "DASHBOARD",
      href: NANDI_URL.ADMIN.DASHBOARD,
      iconName: "dashboard",
      label: t("admin.sideBar.dashboard"),
    },
    {
      key: "ACCOUNTS",
      href: NANDI_URL.ADMIN.ACCOUNTS,
      iconName: "accounts",
      label: t("admin.sideBar.accounts"),
    },
    {
      key: "USERS",
      href: NANDI_URL.ADMIN.USERS,
      iconName: "users",
      label: t("admin.sideBar.users"),
    },
  ]);

  return (
    <TopNavbar
      links={isSuperUser ? superUserNavbarLinks : topNavBarLinks}
      onLogout={onLogout}
      user={user}
    />
  );
};

export default withTranslation()(TopNavBarContainer);
