import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { ServicesContext } from "../../../services/service-providers/service-provider";
import { User } from "../../../types/auth";
import UserPage from "./layout";

type UserPageState = {
  currentUser?: User;
  selectedLanguage: string;
};

const UserPageContainer: FunctionComponent<WithTranslation> = ({ t }) => {
  const { usersService } = useContext(ServicesContext);
  const [state, setState] = useState<UserPageState>({ selectedLanguage: "EN" });

  const loadPage = async () => {
    const currentUser = await usersService.getCurrentUser();
    const selectedLanguage = localStorage.getItem("i18nextLng") as string;
    setState({ ...state, currentUser, selectedLanguage });
  };

  const onChangeLanguage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    localStorage.setItem("i18nextLng", event.target.value);
    setState((prevState) => ({
      ...prevState,
      selectedLanguage: event.target.value,
    }));
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  useEffect(() => {
    loadPage();
  }, []);

  if (!state.currentUser) return null;

  return (
    <UserPage
      currentUser={state.currentUser}
      userDetailsSection={{
        title: t("admin.userPage.detailsSection.title"),
        languageOption: {
          title: t("admin.userPage.languageOption.title"),
          selectedLanguage: state.selectedLanguage,
          onChangeLanguage: onChangeLanguage,
          options: [
            { value: "EN", displayValue: "EN" },
            { value: "ES", displayValue: "ES" },
          ],
        },
      }}
    />
  );
};

export default withTranslation()(UserPageContainer);
