import classNames from "classnames";
import React, { FunctionComponent, InputHTMLAttributes } from "react";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
}

export const Input: FunctionComponent<InputProps> = ({
  className,
  ...props
}) => {
  return (
    <div className={classNames("relative", className)}>
      <input
        className="peer flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus:outline-none focus:ring-0 focus:border-transparent hover:border-input disabled:cursor-not-allowed disabled:opacity-50"
        {...props}
      />
      <div className="absolute inset-0 rounded-md border border-input pointer-events-none peer-focus:border-input"></div>
    </div>
  );
};
