import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router';
import { Icon } from '../Icon';

import styles from './GoBack.module.scss';

interface GoBackProps {
  title: string;
}

export const GoBack: FunctionComponent<GoBackProps> = (props) => {
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  return (
    <div className={styles.goBackContainer}>
      <div className={styles.goBackIconContainer} onClick={() => goBack()}>
        <Icon iconName={'arrow_back'} />
      </div>
      <div className={styles.title}>{props.title}</div>
    </div>
  );
};
