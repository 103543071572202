import {
  CreateHealthCalendarEventDto,
  HealthCalendarEntityKey,
  HealthCalendarEvent,
} from "../types/health-calendar";
import request from "./request";
import { IHealthCalendarService } from "./services-types";

export class HealthCalendarService implements IHealthCalendarService {
  getEvents = async (accountId: string): Promise<HealthCalendarEvent[]> => {
    return await request({
      method: "GET",
      url: `/health-calendar-events/${accountId}`,
      baseURL: process.env.REACT_APP_NEW_API_URL,
    });
  };
  getEntityKeys = async (
    accountId: string
  ): Promise<HealthCalendarEntityKey[]> => {
    return await request({
      method: "GET",
      url: `/health-calendar-events/entity-keys/${accountId}`,
      baseURL: process.env.REACT_APP_NEW_API_URL,
    });
  };
  create = async (
    healthCalendarEventDto: CreateHealthCalendarEventDto
  ): Promise<any> => {
    return await request({
      method: "POST",
      url: "/health-calendar-events",
      baseURL: process.env.REACT_APP_NEW_API_URL,
      data: healthCalendarEventDto,
    });
  };
  deleteOne = async (eventId: number): Promise<any> => {
    return await request({
      method: "DELETE",
      url: `/health-calendar-events/${eventId}`,
      baseURL: process.env.REACT_APP_NEW_API_URL,
    });
  };
}
