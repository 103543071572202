import React, { ButtonHTMLAttributes, FunctionComponent } from "react";
import { ButtonLoader, LoaderSpin } from "../../LoaderSpin/LoaderSpin";
import classNames from "classnames";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  isLoading?: boolean;
  theme?: "light" | "dark" | "red";
}

export const Button: FunctionComponent<Props> = ({
  label,
  isLoading,
  theme = "dark",
  ...props
}) => {
  return (
    <button
      {...props}
      className={classNames(
        "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input h-10 px-4 py-2",
        theme === "dark" ? "bg-[#18181B] text-[#FAFAFA]" : null,
        theme === "light"
          ? "bg-background hover:bg-accent hover:text-accent-foreground"
          : null,
        theme === "red" ? "bg-red-600 text-[#FAFAFA]" : null
      )}
    >
      {isLoading ? (
        <div className="relative top-[-20px]">
          <ButtonLoader />
        </div>
      ) : (
        label
      )}
    </button>
  );
};
