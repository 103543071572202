import classNames from "classnames";
import React, { FunctionComponent } from "react";

const NDVIScales = [
  { value: 0.0, label: "0" },
  { value: 0.1, label: "0.10" },
  { value: 0.2, label: "0.20" },
  { value: 0.3, label: "0.30" },
  { value: 0.4, label: "0.40" },
  { value: 0.5, label: "0.50" },
  { value: 0.6, label: "0.60" },
  { value: 0.7, label: "0.70+" },
  { value: 1.0, label: "1.00" },
];

export const NDVIControl: FunctionComponent<{
  value: boolean;
  disabled: boolean;
  onClick: (e: any) => void;
}> = ({ value, disabled, onClick }) => (
  <div className="absolute bottom-5 z-[10000] left-[10px] text-lg">
    <div className="flex items-center">
      <div className="w-[fit-content] bg-white p-2 rounded">
        <input
          checked={value}
          name="ndvi"
          onChange={onClick}
          disabled={disabled}
          type="checkbox"
          title={disabled ? "No NDVI information for this paddock" : undefined}
        />
        <span className={classNames(disabled ? "text-gray-400" : null)}>
          NDVI
        </span>
      </div>

      {value ? (
        <div className="ml-4 bg-white p-1 rounded text-base flex space-x-1">
          {NDVIScales.map((scale) => (
            <div key={scale.value}>
              <span>{scale.label}</span>
              <span
                className={classNames("min-w-[12px] block min-h-[8px]", {
                  "bg-[#1d492b]": scale.value === 1.0,
                  "bg-[#275e32]": scale.value === 0.7,
                  "bg-[#34763e]": scale.value === 0.6,
                  "bg-[#45974e]": scale.value === 0.5,
                  "bg-[#59b568]": scale.value === 0.4,
                  "bg-[#91d182]": scale.value === 0.3,
                  "bg-[#bde397]": scale.value === 0.2,
                  "bg-[#e5f5ad]": scale.value === 0.1,
                  "bg-[#fbfcd3]": scale.value === 0.0,
                })}
              ></span>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  </div>
);
