import React, { FunctionComponent, useEffect, useState } from "react";
import { Loader } from "../../../components/Loader/Loader";
import {
  CreateUserFormFields,
  UpdateUserFormFields,
  User,
  UserFilter,
} from "../../../types/auth";
import withLoaderHOC, {
  WithLoaderProps,
} from "../../../utils/hocs/WithLoaderHOC";

import { FormModal } from "../../components/FormModal/FormModal";

import { CreateUserForm, UserRoles } from "./UserPageTypes";
import { checkValidity } from "../../../utils/utils";
import { FormEvent } from "react";
import { RootState } from "../../../store/store";
import { connect, ConnectedProps } from "react-redux";
import { useServices } from "../../../services/service-providers/service-provider";
import { FormElement } from "../../../types/form-types";
import { isApiError, isMsgError } from "../../../lib/error";
import UsersPage from "../../pages/users/layout";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/Button/Button";
import { NotificationStatus } from "../../../types/notifications";
import { Notification } from "../../../components/Notification/Notification";

type UsersPageState = {
  users: Array<User>;
  create_user_form: CreateUserForm;
  displayModal: boolean;
  newUserForm: boolean;
  submitFormError: string;
  submitFormLoading: boolean;
};

type Props = WithLoaderProps & PropsFromRedux;

const formInitialState: CreateUserForm = {
  username_field: {
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "Username*",
    },
    value: "",
    validation: {
      required: true,
      message: "Field required",
    },
    valid: false,
    touched: false,
  },
  email_field: {
    elementType: "input",
    elementConfig: {
      type: "email",
      placeholder: "Email*",
    },
    value: "",
    validation: {
      required: true,
      isEmail: true,
      message: "Invalid email",
    },
    valid: false,
    touched: false,
  },
  full_name_field: {
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "Full Name*",
    },
    value: "",
    validation: {
      required: true,
      message: "Field required",
    },
    valid: false,
    touched: false,
  },
  role_field: {
    elementType: "select",
    elementConfig: {
      type: "text",
      placeholder: "Role*",
      options: Object.values(UserRoles).map((userRole) => ({
        value: userRole,
        displayValue: userRole,
      })),
    },
    value: Object.values(UserRoles)[0],
    validation: {
      required: true,
    },
    valid: true,
    touched: true,
  },
  password_field: {
    elementType: "input",
    elementConfig: {
      type: "password",
      placeholder: "Password*",
    },
    value: "",
    validation: {
      required: true,
      isPassword: true,
      message:
        "Password must be 8 characters long. Must contain an uppercase letter or a number",
    },
    valid: false,
    touched: false,
  },
  enabled_field: {
    elementType: "checkbox",
    elementConfig: {
      type: "checkbox",
      placeholder: "Enabled",
      disabled: true,
    },
    value: true,
    validation: {},
    valid: true,
    touched: true,
  },
};

const initialState: UsersPageState = {
  users: [],
  displayModal: false,
  newUserForm: true,
  submitFormError: "",
  create_user_form: {
    ...formInitialState,
  },
  submitFormLoading: false,
};

const UsersPageContainer: FunctionComponent<Props> = ({
  setLoading,
  setError,
  isLoading,
  selectedAccount,
}) => {
  const { usersService, authService } = useServices();
  const { t } = useTranslation();

  const [state, setState] = useState<UsersPageState>(initialState);

  const getUsers = async () => {
    try {
      setLoading(true);
      const data = await usersService.getUsers(
        UserFilter.ALL,
        String(selectedAccount.id)
      );
      setState({
        ...state,
        users: data,
      });
      setLoading(false);
    } catch (error) {
      if (isMsgError(error)) setError(error);
    }
  };

  useEffect(() => {
    getUsers();
  }, [selectedAccount.id]);

  const onTouchEventHandler = (
    event: React.FocusEvent<Element>,
    fieldName: string
  ) => {
    const updatedForm = {
      ...state.create_user_form,
      [fieldName]: {
        ...state.create_user_form[fieldName as keyof CreateUserForm],
        valid: checkValidity(
          state.create_user_form[fieldName as keyof CreateUserForm].value,
          state.create_user_form[fieldName as keyof CreateUserForm].validation
        ),
        touched: true,
      },
    };
    setState({ ...state, create_user_form: updatedForm });
  };

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    const updatedForm = {
      ...state.create_user_form,
      [fieldName]: {
        ...state.create_user_form[fieldName as keyof CreateUserForm],
        value:
          state.create_user_form[fieldName as keyof CreateUserForm]
            .elementType !== "checkbox"
            ? event.target.value
            : event.target.checked,
        valid: checkValidity(
          event.target.value,
          state.create_user_form[fieldName as keyof CreateUserForm].validation
        ),
        touched: true,
      },
    };
    setState({ ...state, create_user_form: updatedForm });
  };

  const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      setState({
        ...state,
        submitFormLoading: true,
      });
      if (state.newUserForm) {
        const new_user: CreateUserFormFields = {
          username: state.create_user_form.username_field.value,
          email: state.create_user_form.email_field.value,
          full_name: state.create_user_form.full_name_field.value,
          role: state.create_user_form.role_field.value,
          password: state.create_user_form.password_field.value,
          account_id: selectedAccount.id,
        };
        const newUser = await authService.createUser(new_user);
        setState((prevState) => {
          return { ...prevState, users: prevState.users.concat([newUser]) };
        });
      } else {
        const update_user: UpdateUserFormFields = {
          username: state.create_user_form.username_field.value,
          email: state.create_user_form.email_field.value,
          full_name: state.create_user_form.full_name_field.value,
          role: state.create_user_form.role_field.value,
          password: state.create_user_form.password_field.value,
          id: 1,
          disabled: !state.create_user_form.enabled_field.value,
          account_id: selectedAccount.id,
        };
        await authService.updateUser(update_user);
      }
      onChangeModalState();
    } catch (error) {
      if (isApiError(error) && error.response.status === 400) {
        setState((prevState) => {
          if (isApiError(error) && error.response.status === 400) {
            return {
              ...prevState,
              submitFormError: `Error: ${error.response.data.detail}`,
            };
          } else {
            return {
              ...prevState,
              submitFormError: "General Error: Please try again",
            };
          }
        });
      }
    } finally {
      setState((prevState) => ({
        ...prevState,
        submitFormLoading: false,
      }));
    }
  };

  const onChangeModalState = () => {
    resetFormState();
    setState((prevState) => {
      return {
        ...prevState,
        displayModal: !prevState.displayModal,
      };
    });
  };

  const resetFormState = () => {
    setState((prevState) => ({
      ...prevState,
      create_user_form: { ...formInitialState },
      newUserForm: true,
      submitFormError: "",
    }));
  };

  const onUserRowClicked = (index: number) => {
    const user = state.users[index];
    const updatedForm = {
      ...state.create_user_form,
      username_field: {
        ...state.create_user_form.username_field,
        elementConfig: {
          ...state.create_user_form.username_field.elementConfig,
          disabled: true,
        },
        value: user.username,
        valid: true,
      },
      email_field: {
        ...state.create_user_form.email_field,
        value: user.email,
        valid: true,
      },
      full_name_field: {
        ...state.create_user_form.full_name_field,
        value: user.full_name,
        valid: true,
      },
      role_field: {
        ...state.create_user_form.role_field,
        value: user.role,
        valid: true,
      },
      enabled_field: {
        ...state.create_user_form.enabled_field,
        value: !user.disabled,
        elementConfig: {
          ...state.create_user_form.enabled_field.elementConfig,
          disabled: false,
        },
        valid: true,
      },
      password_field: {
        ...state.create_user_form.password_field,
        elementConfig: {
          type: "password",
          placeholder: "Password",
        },
        validation: {
          ...state.create_user_form.password_field.validation,
          required: false,
        },
        valid: true,
      },
    };
    setState({
      ...state,
      create_user_form: updatedForm,
      displayModal: true,
      newUserForm: false,
    });
  };

  const formElementsArray: FormElement[] = [];
  Object.keys(state.create_user_form).forEach((key) => {
    formElementsArray.push({
      id: key,
      config: state.create_user_form[key as keyof CreateUserForm],
    });
  });

  const isFormInvalid = formElementsArray.some(
    (formElement) => !formElement.config.valid
  );

  return (
    <div className={"w-full p-2"}>
      <FormModal
        title={state.newUserForm ? "New User" : "Edit User"}
        displayModal={state.displayModal}
        formElementsArray={formElementsArray}
        formSubmitErrorMessage={state.submitFormError}
        isLoading={state.submitFormLoading}
        onSubmit={submitHandler}
        inputChangedHandler={inputChangedHandler}
        onClose={onChangeModalState}
        onTouchEventHandler={onTouchEventHandler}
      >
        <>
          {state.submitFormError ? (
            <Notification status={NotificationStatus.ERROR}>
              {state.submitFormError}
            </Notification>
          ) : null}
          <div className="w-28 m-auto mt-4">
            <Button disabled={isFormInvalid}>Save User</Button>
          </div>
        </>
      </FormModal>
      <Loader isLoading={isLoading}>
        <UsersPage
          title={t("admin.usersPage.title")}
          users={state.users}
          onChangeModalState={onChangeModalState}
          onUserRowClicked={onUserRowClicked}
        />
      </Loader>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    selectedAccount: state.accounts.selectedAccount,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withLoaderHOC(UsersPageContainer));
