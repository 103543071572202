import { User, UserFilter } from "../types/auth";
import request from "./request";
import { IUsersService } from "./services-types";

export class UsersService implements IUsersService {
  getCurrentUser = async (): Promise<User> => {
    return await request({
      method: "get",
      url: "/api/users/current_user",
    });
  };

  getUsers = async (
    filter: UserFilter,
    accountId?: string
  ): Promise<Array<User>> => {
    return await request({
      method: "get",
      url: `/api/users`,
      params: {
        account_id: accountId,
        filter: filter,
      },
    });
  };

  changeUserAccount = async (account_id: number): Promise<User> => {
    console.log(process.env);
    return await request({
      method: "put",
      url: "/users",
      baseURL: process.env.REACT_APP_NEW_API_URL,
      data: {
        account_id: account_id,
      },
    });
  };
}
