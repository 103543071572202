import React, {
  FormEvent,
  FunctionComponent,
  useEffect,
  useState,
} from "react";
import { Button } from "../../../components/Button/Button";
import { Image } from "../../../components/Image/Image";
import { Input } from "../../../components/Input/Input";
import { checkValidity } from "../../../utils/utils";
import logo from "../../../assets/logo.svg";
import styles from "../Auth.module.scss";
import { RouteComponentProps, withRouter } from "react-router";
import withIsLoading from "../../../utils/hocs/withIsLoading";
import { useServices } from "../../../services/service-providers/service-provider";
import { PasswordRecoveryForm } from "../../../types/auth";
import { isApiError } from "../../../lib/error";

interface PasswordRecoveryPageProps {
  setLoading: (isLoading: boolean) => void;
}

interface PasswordRecoveryPageState {
  passwordRecoveryForm: PasswordRecoveryForm;
  formIsValid: boolean;
  successfulMessage: string | null;
}

type Props = PasswordRecoveryPageProps & RouteComponentProps;

const initialState: PasswordRecoveryPageState = {
  formIsValid: false,
  successfulMessage: null,
  passwordRecoveryForm: {
    username_field: {
      elementType: "input",
      elementConfig: {
        type: "text",
        placeholder: "Username",
      },
      value: "",
      validation: {
        required: true,
        minLength: 8,
      },
      valid: false,
      touched: false,
    },
  },
};

const PasswordRecoveryPage: FunctionComponent<Props> = ({
  setLoading,
  history,
}) => {
  const [state, setState] = useState(initialState);
  const { authService } = useServices();

  useEffect(() => {
    setLoading(false);
  }, []);

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    const updatedForm = {
      ...state.passwordRecoveryForm,
      [fieldName]: {
        ...state.passwordRecoveryForm[fieldName as keyof PasswordRecoveryForm],
        value: event.target.value,
        valid: checkValidity(
          event.target.value,
          state.passwordRecoveryForm[fieldName as keyof PasswordRecoveryForm]
            .validation
        ),
        touched: true,
      },
    };
    setState({ ...state, passwordRecoveryForm: updatedForm });
  };

  const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    try {
      const data = await authService.passwordRecovery(
        state.passwordRecoveryForm.username_field.value
      );

      setState({
        ...state,
        successfulMessage: data.message,
      });
      setLoading(false);

      setTimeout(() => {
        history.push("/");
      }, 2000);
    } catch (error) {
      if (isApiError(error) && error.response.status === 401) {
        setState({
          ...state,
          successfulMessage: "Recovery email has been sent",
        });
        setLoading(false);

        setTimeout(() => {
          history.push("/");
        }, 2000);
      } else {
        setState({
          ...state,
          successfulMessage: "General Error: Please try again",
        });
      }
    }
  };

  return (
    <div className={styles.loginPage}>
      <Image className={styles.headerLogin} imageLink={logo} />
      <form onSubmit={submitHandler}>
        <div className={styles.body}>
          <div>
            <Input
              className={styles.formInput}
              elementType={
                state.passwordRecoveryForm.username_field.elementType
              }
              elementConfig={
                state.passwordRecoveryForm.username_field.elementConfig
              }
              value={state.passwordRecoveryForm.username_field.value}
              touched={state.passwordRecoveryForm.username_field.touched}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                inputChangedHandler(event, "username_field")
              }
            />
          </div>
          {state.successfulMessage ? (
            <div className={styles.errorBox}>
              <p className={styles.errorMessage}>
                {state.successfulMessage}. Redirecting...
              </p>
            </div>
          ) : null}
        </div>
        <div className={styles.btnForm}>
          <Button>Send Recovery Email</Button>
        </div>
      </form>
    </div>
  );
};

export default withRouter(withIsLoading(PasswordRecoveryPage));
