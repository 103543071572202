import React, { FunctionComponent } from "react";
import { Button } from "../../../../components/Button/Button";
import { PageTitle } from "../../../../components/PageTitle/PageTitle.component";
import { Table, TableColumn } from "../../../components/Table/Table";
import styles from "./upload.module.scss";

const tableConstants = (): Array<TableColumn> => {
  return [
    {
      title: (
        <>
          EID <span>*</span>
        </>
      ),
      key: "eid",
      render: (uploadMember: UploadMember) => {
        return <span>{uploadMember.tag_id}</span>;
      },
    },
    {
      title: (
        <>
          Cattle Type <span>*</span>
        </>
      ),
      key: "member_type",
      render: (uploadMember: UploadMember) => {
        return <span>{uploadMember.member_type}</span>;
      },
    },
    {
      title: (
        <>
          Breed <span>*</span>
        </>
      ),
      key: "breed",
      render: (uploadMember: UploadMember) => {
        return <span>{uploadMember.breed}</span>;
      },
    },
    {
      title: (
        <>
          Birth Date <span>*</span>
        </>
      ),
      key: "birth",
      render: (uploadMember: UploadMember) => {
        const date: Date = new Date(uploadMember.birth);
        return <span>{date.toLocaleDateString()}</span>;
      },
    },
    {
      title: (
        <>
          Weight <span>*</span>
        </>
      ),
      key: "weight",
      render: (uploadMember: UploadMember) => {
        return <span>{uploadMember.weight}</span>;
      },
    },
  ];
};

type UploadCattleProps = {
  title: string;
  cta: string;
  uploadCattle: Array<UploadMember>;
  onUploadAnimals: () => void;
  onFileInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const UploadCattle: FunctionComponent<UploadCattleProps> = ({
  title,
  cta,
  uploadCattle,
  onUploadAnimals,
  onFileInput,
}) => (
  <>
    <PageTitle>{title}</PageTitle>
    <input
      type="file"
      onChange={onFileInput}
      accept=".csv, .xlsx"
      style={{ padding: "10px" }}
    />
    <Table columns={tableConstants()} items={uploadCattle} />
    <div className={styles.buttonContainer}>
      <div className={styles.buttonSize}>
        <Button onClick={onUploadAnimals}>{cta}</Button>
      </div>
    </div>
  </>
);

export default UploadCattle;
