import classNames from "classnames";
import React, { FunctionComponent } from "react";

type MainLayoutDelimiterProps = {
  id?: string;
  className?: string;
  children: React.ReactNode;
};

const MainLayoutDelimiter: FunctionComponent<MainLayoutDelimiterProps> = ({
  id,
  children,
  className,
}) => (
  <main className="w-full h-full bg-[#F2F2F7]">
    <section
      className={classNames(
        "max-w-[1280px] w-full m-auto px-4 sm:px-10 lg:px-[80px]",
        className
      )}
      id={id}
    >
      {children}
    </section>
  </main>
);

export default MainLayoutDelimiter;
