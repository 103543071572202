import React, { FunctionComponent, useEffect, useState } from "react";

const Modal: FunctionComponent<{ onClose: any }> = ({ children, onClose }) => (
  <div className="absolute w-full h-full bg-gray-800/50 z-[9999999] p-8 top-0 left-0 flex items-center justify-center">
    <div className="absolute top-4 right-4 cursor-pointer" onClick={onClose}>
      <span className="font-bold text-[40px] text-white hover:text-gray-300">
        &times;
      </span>
    </div>
    <div className="max-w-[80%] max-h-[80%] m-auto block">{children}</div>
  </div>
);

const useModal = () => {
  const [isVisible, setIsVisible] = useState(false);

  // hide or show browser scroll
  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isVisible]);

  return { isVisible, setIsVisible };
};

export { Modal, useModal };
