import React, { FunctionComponent } from "react";
import { PageTitle } from "../../../components/PageTitle/PageTitle.component";

type NewAccountProps = {
  title: string;
};

const NewAccountLayout: FunctionComponent<NewAccountProps> = ({ title }) => (
  <>
    <PageTitle>{title}</PageTitle>
  </>
);

export { NewAccountLayout };
