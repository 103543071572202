import React, { FunctionComponent } from "react";

import styles from "./MembersListElement.module.scss";
import { Icon } from "../Icon";

interface MembersListElementProps {
  member: Member;
}

export const MembersListElement: FunctionComponent<MembersListElementProps> = ({
  member,
}) => {
  return (
    <>
      <div className={styles.listElement}>
        <div className={styles.sensorStatusContainer}>
          <div className={styles.sensorStatus}>
            <div
              className={[
                styles.dot,
                member.status === "ALERT"
                  ? styles.alertColor
                  : styles.nonAlertColor,
              ].join(" ")}
            ></div>
          </div>
          <div className={styles.iconContainer}>
            <Icon iconName={member.member_type.toLowerCase()} />
          </div>
          <div className={styles.sensorId}>{member.tag_id}</div>
        </div>
        <div className={styles.goForwardIconContainer}>
          <Icon iconName={"arrow_forward"} />
        </div>
      </div>
      <div className={styles.listDivider}></div>
    </>
  );
};
