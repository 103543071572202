// main imports
import React, { FunctionComponent } from "react";
import { connect, ConnectedProps } from "react-redux";
import { NavLink, Route, Switch } from "react-router-dom";

// lib
import { Features, isUserAllowed } from "../../../lib/feature-flag";
import NANDI_URL from "../../../lib/url";
import { RootState } from "../../../store/store";

// Pages
import MainSensorsPage from "./MainSensorsPage/MainSensorsPage";
import UploadSensorsPage from "./UploadSensorsPage/UploadSensorsPage";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/Button/Button";

const SensorsPage: FunctionComponent<PropsFromRedux> = ({ user }) => {
  const { t } = useTranslation();
  return (
    <div className="p-2">
      {isUserAllowed(Features.SENSORS_UPLOAD, user) ? (
        <div className={"flex w-full justify-around"}>
          <div className={"flex w-full"}>
            <NavLink
              exact
              to={NANDI_URL.ADMIN.SENSORS}
              className="p-2"
              activeClassName="border-b-2 border-gray-500"
            >
              {t("admin.sensors.sensorsTable")}
            </NavLink>
            <NavLink
              exact
              to={NANDI_URL.ADMIN.SENSORS_UPLOAD}
              className="p-2"
              activeClassName="border-b-2 border-gray-500"
            >
              {t("admin.sensors.uploadSensor")}
            </NavLink>
          </div>
        </div>
      ) : null}
      <div>
        <Switch>
          <Route
            path={NANDI_URL.ADMIN.SENSORS}
            exact
            component={MainSensorsPage}
          />
          <Route
            path={NANDI_URL.ADMIN.SENSORS_UPLOAD}
            component={UploadSensorsPage}
          />
        </Switch>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    user: state.users.user,
  };
};

const mapDispatchToProps = () => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SensorsPage);
