import React, { FunctionComponent, useState } from "react";
import { useHistory } from "react-router";

import {
  FieldSummaryWithExtraDetails,
  Property,
} from "../../../../types/fields";
import NANDI_URL from "../../../../lib/url";

import { EnhancedMap } from "../../../../components/Map/Map";
import PaddockSummaryCard from "../../../../components/PaddockSummaryCard";
import { PageTitle } from "../../../../components/PageTitle/PageTitle.component";
import { NDVICard } from "../../paddock/layout/components";

type AdminHomePageProps = {
  title: string;
  fields: Array<FieldSummaryWithExtraDetails>;
  geoJsonData: GeoJSON.FeatureCollection<GeoJSON.Geometry, Property>;
};

const AdminHomePage: FunctionComponent<AdminHomePageProps> = ({
  title,
  geoJsonData,
  fields,
}) => {
  const history = useHistory();

  const [ndviFilter, setNdviFilter] = useState(false);

  const handleNDVIFilter = () => {
    setNdviFilter((prevNdviFilter) => !prevNdviFilter);
  };

  return (
    <div className="w-full h-full p-2">
      <PageTitle className="w-[fit-content]">{title}</PageTitle>

      <main className="grid grid-cols-4 gap-4 mt-4">
        <section className="col-span-3 min-h-[550px] max-h-[500px]">
          <EnhancedMap
            geoJsonData={{
              ...geoJsonData,
            }}
            tiles={
              ndviFilter
                ? fields.map((field) =>
                    field.agro ? field.agro[0].tile.ndvi : ""
                  )
                : undefined
            }
            ndviOptions={{
              onClick: handleNDVIFilter,
              value: ndviFilter,
              disabled: !fields.some(
                (field) =>
                  field.agro && field.agro[0] && field.agro[0].tile.ndvi
              ),
            }}
          />
        </section>
        <section className="col-span-1 grid gap-4 h-[fit-content]">
          {fields.map((field) => (
            <PaddockSummaryCard
              className="h-[fit-content]"
              key={field.id}
              field={field}
              onClick={() => {
                history.push(
                  NANDI_URL.ADMIN.GENERATE_BY_PADDOCK_ID(String(field.id))
                );
              }}
            >
              {ndviFilter && field.agroStats ? (
                <NDVICard className="mt-2" agroStatsInfo={field.agroStats[0]} />
              ) : null}
            </PaddockSummaryCard>
          ))}
        </section>
      </main>
    </div>
  );
};

export { AdminHomePage };
