import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { HeaderExpanded } from "../../components/HeaderExpanded/HeaderExpanded";
import { EnhancedMap } from "../../components/Map/Map";
import { useServices } from "../../services/service-providers/service-provider";
import {
  createGeoJsonData,
  FieldSummaryWithExtraDetails,
  Property,
} from "../../types/fields";
import { OldMemberCount } from "../../types/members";
import { useAppSelector } from "../../store/hooks/redux-hooks";

type MapPageState = {
  fields: Array<FieldSummaryWithExtraDetails>;
  geoJsonData: GeoJSON.FeatureCollection<GeoJSON.Geometry, Property>;
  totalMembersCount: Array<OldMemberCount>;
  searchFieldValue: string;
};

const initialState: MapPageState = {
  fields: [],
  searchFieldValue: "",
  totalMembersCount: [],
  geoJsonData: {
    type: "FeatureCollection",
    features: [],
  },
};

const MapPage: FunctionComponent = ({}) => {
  const [state, setState] = useState(initialState);
  const { selectedAccount } = useAppSelector((state) => state.accounts);
  const { fieldsService, membersService } = useServices();
  const [ndviFilter, setNdviFilter] = useState(false);

  const handleNDVIFilter = useCallback(
    (e: any) => {
      e.preventDefault();
      setNdviFilter((prevNdviFilter) => !prevNdviFilter);
      e.stopPropagation();
    },
    [ndviFilter]
  );

  const getFields = async () => {
    try {
      const [fields, members] = await Promise.all([
        fieldsService.getFields(String(selectedAccount.id)),
        membersService.getMembersSummary(),
      ]);
      setState({
        ...state,
        fields: fields,
        totalMembersCount: members,
        geoJsonData: createGeoJsonData({ fields, ref: "app" }),
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getFields();
  }, []);

  const onInputSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      searchFieldValue: event.target.value,
    });
  };

  return (
    <>
      <HeaderExpanded
        searchFieldValue={state.searchFieldValue}
        onInputSearchChange={onInputSearchChange}
      />
      <EnhancedMap
        geoJsonData={{
          ...state.geoJsonData,
          features: state.geoJsonData.features.filter((feature) =>
            feature.properties.name.includes(state.searchFieldValue)
          ),
        }}
        tiles={
          ndviFilter
            ? state.fields.map((field) =>
                field.agro ? field.agro[0].tile.ndvi : ""
              )
            : undefined
        }
        ndviOptions={{
          onClick: handleNDVIFilter,
          value: ndviFilter,
          disabled: !state.fields.some(
            (field) => field.agro && field.agro[0].tile.ndvi
          ),
        }}
      />
    </>
  );
};

export default MapPage;
