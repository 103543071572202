import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import NANDI_URL from "../../../lib/url";
import { Icon } from "../../Icon";

import styles from "./HeaderMembersInfo.module.scss";

interface HeaderMembersInfoProps {
  membersCount: number;
  memberType: string;
  memberName: string;
  className: string;
  membersInAlertStatus: number;
}

export const HeaderMembersInfo: FunctionComponent<HeaderMembersInfoProps> = ({
  className,
  membersCount,
  memberType,
  memberName,
  membersInAlertStatus,
}) => {
  const { t } = useTranslation();

  return (
    <Link
      className={[styles.memberInfoContainer, className].join(" ")}
      to={`${NANDI_URL.APP.MEMBERS}?entity_type=${memberType}`}
    >
      <div className={styles.memberInfoIcon}>
        <Icon iconName={memberType} />
      </div>
      <div className={styles.memberInfoCount}>{membersCount}</div>
      <div className={styles.memberInfoName}>
        {t(`entityTypes.${memberName}`)}
      </div>
      {membersInAlertStatus > 0 ? (
        <div className={styles.alertsContainer}>
          <b>{membersInAlertStatus}</b> {t("homepage.header.withProblem")}
        </div>
      ) : null}
    </Link>
  );
};
