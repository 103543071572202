import React, { FunctionComponent } from "react";

import styles from "./FieldAlertsListElement.module.scss";
import { Icon } from "../Icon";

import { Alert } from "../../types/alerts";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface FieldAlertsListElementProps {
  className?: string;
  alert: Alert;
  link: string;
}

export const FieldAlertsListElement: FunctionComponent<
  FieldAlertsListElementProps
> = ({ alert, className, link }) => {
  const { t } = useTranslation();

  const fieldName = alert?.field_name ?? alert?.field?.name;

  const alertDescription = (field: string | undefined) => {
    if (field) {
      return t(
        `alertsTable.description.${alert.alert_type} - ${alert.entity_type}`,
        { fieldName }
      );
    } else {
      return t(
        `alertsTable.descriptionWithoutField.${alert.alert_type} - ${alert.entity_type}`
      );
    }
  };

  return (
    <Link to={link} className={styles.listElementContainer}>
      <div
        className={[
          styles.listElement,
          className,
          Boolean(alert.is_read) ? "" : styles.alertNotRead,
        ].join(" ")}
      >
        <div className={styles.alertInfoContainer}>
          <Icon
            iconName={alert.entity_type.toLowerCase()}
            className={styles.iconContainer}
          />
          <div className={styles.alertInfo}>
            <div className={styles.alertTitle}>{alert.entity_description}</div>
            <div className={styles.alertSubtitle}>
              {alertDescription(fieldName)}
            </div>
          </div>
        </div>
        <div className={styles.dateContainer}>
          <div className={styles.date}>
            {new Date(alert.date).toLocaleTimeString(navigator.language, {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </div>
          <div className={styles.date}>
            {new Date(alert.date).toLocaleDateString(navigator.language, {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </div>
        </div>
      </div>
    </Link>
  );
};
