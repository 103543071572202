import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import styles from "./SelectItemModal.module.scss";

interface SelectItemModalProps {
  displayModal: boolean;
  items: Array<any>;
  selectedItem: number;
  onAction: (itemId: number) => void;
  onClose: (selected: number) => void;
}

const SelectItemModal: FunctionComponent<SelectItemModalProps> = ({
  displayModal,
  items,
  selectedItem,
  onAction,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        onClick={() => onClose(selectedItem)}
        className={[
          styles.modalBackground,
          displayModal ? styles.modalDisplay : "",
        ].join(" ")}
      />
      <div
        className={[
          styles.modalContent,
          displayModal ? styles.modalDisplay : "",
        ].join(" ")}
      >
        {items.map((item, index) => (
          <button
            key={index}
            className={styles.filterRow}
            onClick={() => onAction(item.id)}
          >
            <div className={styles.pointContainer}>
              <div
                className={
                  selectedItem === item.id
                    ? styles.selectedRow
                    : styles.notSelectedRow
                }
              ></div>
            </div>
            <div key={item.id} className={styles.filterText}>
              {t(`modalFilter.${item.name}`)}
            </div>
          </button>
        ))}
      </div>
    </>
  );
};

export default SelectItemModal;
