import NANDI_URL from "../lib/url";
import {
  User,
  LogInApiResponse,
  PasswordRecoveryApiResponse,
  CreateUserFormFields,
} from "../types/auth";
import request from "./request";
import { IAuthService } from "./services-types";

export class ProductionAuthService implements IAuthService {
  logIn = async (
    username: string,
    password: string
  ): Promise<LogInApiResponse> => {
    const methodParams = new URLSearchParams();
    methodParams.append("username", username);
    methodParams.append("password", password);

    return await request({
      method: "post",
      data: methodParams,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      url: "/auth/token",
    }).then((logInApiResponse) => {
      localStorage.setItem("access_token", logInApiResponse.access_token);
      localStorage.setItem("token_type", logInApiResponse.token_type);
      return logInApiResponse;
    });
  };

  adminLogIn = async (
    username: string,
    password: string
  ): Promise<LogInApiResponse> => {
    const methodParams = new URLSearchParams();
    methodParams.append("username", username);
    methodParams.append("password", password);

    return await request({
      method: "post",
      data: methodParams,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      url: "/auth/adminLogin",
    });
  };

  checkUserMetadata = async (): Promise<User> => {
    return await request({
      method: "get",
      url: "/api/users/current_user",
    });
  };

  passwordRecovery = async (
    username: string
  ): Promise<PasswordRecoveryApiResponse> => {
    return await request({
      method: "post",
      data: { username },
      url: "/auth/passwordRecovery",
    });
  };

  createUser = async (new_user: CreateUserFormFields): Promise<any> => {
    return await request({
      method: "post",
      data: { ...new_user },
      url: "/api/users/",
    });
  };

  updateUser = async (update_user: CreateUserFormFields): Promise<any> => {
    return await request({
      method: "post",
      data: { ...update_user },
      url: "/api/users/update",
    });
  };

  passwordReset = async (
    recovery_token: string,
    new_password: string
  ): Promise<PasswordRecoveryApiResponse> => {
    return await request({
      method: "post",
      data: { recovery_token: recovery_token, new_password: new_password },
      url: "/auth/passwordReset",
    });
  };

  checkPasswordRecoveryToken = async (
    recovery_token: string
  ): Promise<PasswordRecoveryApiResponse> => {
    return await request({
      method: "get",
      url: "/auth/checkToken",
      params: {
        token: recovery_token,
      },
    });
  };

  logout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("token_type");
    window.location.replace(NANDI_URL.ADMIN.MAIN);
  };
}
