import { AxiosError, AxiosResponse } from "axios";
import axios, { AxiosRequestConfig } from "axios";

const onSuccess = (response: AxiosResponse) => {
  console.debug("Request Successful!", response);
  return response.data;
};

const onError = (error: AxiosError) => {
  console.error("Request Failed:", error.config);

  if (error.response) {
    // Request was made but server responded with something
    // other than 2xx
    console.error("Status:", error.response.status);
    console.error("Data:", error.response.data);
    console.error("Headers:", error.response.headers);

    if (error.response.status === 400) {
      return Promise.reject(error);
    }

    if (error.response.status === 401) {
      if (error.response.data.detail === "Incorrect username or password") {
        return Promise.reject(error);
      }
      if (error.response.data.detail === "Could not validate credentials") {
        return Promise.reject(error);
      }
      if (error.response.data.detail === "User not registered") {
        return Promise.reject(error);
      }
      if (error.response.data.detail === "Validation Error") {
        return Promise.reject(error);
      }
      if (
        error.response.data.detail === "Not authenticated" &&
        error.config.url === "/api/users/current_user"
      ) {
        return Promise.reject(error);
      } else {
        document.location.href = "/";
      }
    }
  } else {
    // Something else happened while setting up the request
    // triggered the error
    console.error("Error Message:", error.message);
  }

  return Promise.reject(error.response || error.message);
};

const client = (config: AxiosRequestConfig) =>
  axios.create({
    baseURL: config.baseURL ? config.baseURL : process.env.REACT_APP_API_URL,
  })(config);

const request = async (options: AxiosRequestConfig) => {
  const access_token = localStorage.getItem("access_token");
  const token_type = localStorage.getItem("token_type");

  const config: AxiosRequestConfig = {
    ...options,

    headers: {
      Authorization: `${token_type} ${access_token}`,
      "Access-Control-Allow-Credentials": true,
    },
    timeout: 35000,
  };

  return client(config).then(onSuccess).catch(onError);
};

export default request;
