import moment from "moment";
import { KmlPolygon, LatLng } from "../admin-site/types/Account";
import NANDI_URL from "../lib/url";
import { Alert } from "./alerts";

// Outlines of the paddock/field types declaration
export type OutlineBase = {
  order: number;
  latitude: number;
  longitude: number;
};

export type Outline = OutlineBase & {
  id: number;
  outline_field_id: number;
};

// Field types declaration

type FieldBase = {
  account_id: number;
  name: string;
};

export type Field = FieldBase & {
  id: number;
};

export type FieldSummary = FieldBase & {
  id: number;
  outlines: Array<Outline>;
  members: Array<MemberWithDetails>;
  memberCount?: Array<MemberCount>;
  ndvi_tile?: string; // id of polygon on agromonitoring
};

export type FieldAttachmentType = "WATER" | "ANTENNA";
export type FieldAttachment = {
  id: number;
  name: string;
  type: FieldAttachmentType;
  ndvi_tile?: string;
  dimensions?: number;
  account_id: number;
  field_attachment_outlines: {
    id: number;
    latitude: number;
    longitude: number;
    order: number;
    outline_field_id: number;
  }[];
};

export type FieldDetailedSummary = FieldBase & {
  id: number;
  outlines: Array<Outline>;
  members: Array<MemberWithDetails>;
  memberCount?: Array<MemberCount>;
  alerts?: Array<Alert>;
  ndvi_tile?: string; // id of polygon on agromonitoring
};

export type FieldCreate = FieldBase & {
  outlines: Array<OutlineBase>;
};

export type FieldSummaryWithDetails = FieldSummary & {
  last_field_alert: Alert | null;
};
export type FieldSummaryWithExtraDetails = FieldDetailedSummary & {
  last_field_alert: Alert | null;
  agro?: AgroSatelliteInfo[];
  agroStats?: AgroStatsInfo[];
  membersByGroup: MemberCount[];
  dimensions: number;
};

export type NdviData = {
  tile: string;
  agro: AgroSatelliteInfo[];
  agroStats: AgroStatsInfo[];
};

export type Property = {
  id: number;
  name: string;
  display: string;
  status: string;
  type: MemberType | FieldAttachmentType | "FIELD";
  ref?: string;
  onClick?: (history: any) => void;
};

export const sortFieldsAlphabeticallyUp = (
  a: FieldSummary,
  b: FieldSummary
) => {
  if (a.name < b.name) {
    return -1;
  }
  if (b.name < a.name) {
    return 1;
  }
  return 0;
};

export const sortFieldsAlphabeticallyDown = (
  a: FieldSummary,
  b: FieldSummary
) => {
  if (a.name < b.name) {
    return 1;
  }
  if (b.name < a.name) {
    return -1;
  }
  return 0;
};

export const sortFieldsByAlert = (x: FieldSummary) => {
  // true values first
  return x.members.some((member) => member.status === "ALERT") ? -1 : 1;
  // false values first
  // return (x === y)? 0 : x? 1 : -1;
};

export const createGeoJsonData = ({
  fields,
  fieldAttachments,
  ref,
  members,
}: {
  fields?: Array<FieldSummaryWithExtraDetails>;
  fieldAttachments?: Array<FieldAttachment>;
  ref: string;
  members?: Array<MemberWithDetails>;
}): GeoJSON.FeatureCollection<GeoJSON.Geometry, Property> => {
  const geoJsonData: GeoJSON.FeatureCollection<GeoJSON.Geometry, Property> = {
    type: "FeatureCollection",
    features: [],
  };
  fields?.forEach((field) => {
    const fieldCoordinates: GeoJSON.Position[] = [];
    field.outlines.forEach((outline) => {
      const coordinates: GeoJSON.Position = [
        outline.latitude,
        outline.longitude,
      ];
      fieldCoordinates.push(coordinates);
    });
    const featureField: GeoJSON.Feature<GeoJSON.Polygon, Property> = {
      type: "Feature",
      properties: {
        id: field.id,
        name: field.name,
        display: field.name,
        type: "FIELD",
        status: field.members.some((member) => member.status === "ALERT")
          ? "ALERT"
          : "NORMAL",
        ref: ref,
        onClick: (history) => {
          if (ref === "admin") {
            history.push(`/admin/monitor/${field.id}`);
          } else {
            history.push(
              `${NANDI_URL.APP.GENERATE_MONITOR_BY_ID_MAP(String(field.id))}`
            );
          }
        },
      },
      geometry: {
        type: "Polygon",
        coordinates: [fieldCoordinates],
      },
    };
    geoJsonData.features.push(featureField);
  });

  console.log("trying to render for members ", members);
  members?.forEach((member) => {
    if (member.last_position) {
      const [longitude, latitude] = member.last_position.split(":");
      const featureMember: GeoJSON.Feature<GeoJSON.Point, Property> = {
        type: "Feature",
        properties: {
          id: member.id,
          name: member.tag_id,
          type: member.member_type as MemberType,
          display: `${member.tag_id} <br /> Last seen ${moment
            .unix(member.last_position_ts)
            .fromNow()}`,
          status: member.status,
          onClick: (history) => {
            if (ref === "admin") {
              history.push(`/admin/cattle/${member.id}`);
            } else if (ref === "app") {
              history.push(
                NANDI_URL.APP.GENERATE_MEMBER_BY_ID(String(member.id))
              );
            }
          },
        },
        geometry: {
          type: "Point",
          coordinates: [Number(latitude), Number(longitude)],
        },
      };
      geoJsonData.features.push(featureMember);
    }
  });

  fieldAttachments?.forEach((fieldAttachment) => {
    const outlines = fieldAttachment.field_attachment_outlines;
    if (outlines.length === 1) {
      const { longitude, latitude } =
        fieldAttachment.field_attachment_outlines[0];
      const featureFieldAttachment: GeoJSON.Feature<GeoJSON.Point, Property> = {
        type: "Feature",
        properties: {
          id: fieldAttachment.id,
          name: fieldAttachment.name,
          type: fieldAttachment.type,
          display: fieldAttachment.name,
          status: "NORMAL",
          onClick: (history) => {},
        },
        geometry: {
          type: "Point",
          coordinates: [latitude, longitude],
        },
      };
      geoJsonData.features.push(featureFieldAttachment);
    } else if (outlines.length > 1) {
      const fieldCoordinates: GeoJSON.Position[] = [];
      outlines.forEach((outline) => {
        const coordinates: GeoJSON.Position = [
          outline.latitude,
          outline.longitude,
        ];
        fieldCoordinates.push(coordinates);
      });
      const featureFieldAttachment: GeoJSON.Feature<GeoJSON.Polygon, Property> =
        {
          type: "Feature",
          properties: {
            id: fieldAttachment.id,
            name: fieldAttachment.name,
            type: fieldAttachment.type,
            display: fieldAttachment.name,
            status: "NORMAL",
            onClick: (history) => {},
          },
          geometry: {
            type: "Polygon",
            coordinates: [fieldCoordinates],
          },
        };
      geoJsonData.features.push(featureFieldAttachment);
    }
  });

  return geoJsonData;
};

export const createGeoJsonDataFromCoordinates = (
  fields: Array<KmlPolygon>
): GeoJSON.FeatureCollection<GeoJSON.Polygon, Property> => {
  const geoJsonData: GeoJSON.FeatureCollection<GeoJSON.Polygon, Property> = {
    type: "FeatureCollection",
    features: [],
  };

  fields.forEach((field, index) => {
    const fieldCoordinates: GeoJSON.Position[] = [];
    field.points.forEach((point) => {
      const coordinates: GeoJSON.Position = [point.latitude, point.longitude];
      fieldCoordinates.push(coordinates);
    });
    const featureField: GeoJSON.Feature<GeoJSON.Polygon, Property> = {
      type: "Feature",
      properties: {
        id: index,
        name: field.name,
        display: field.name,
        status: "NORMAL",
        type: "FIELD",
      },
      geometry: {
        type: "Polygon",
        coordinates: [fieldCoordinates],
      },
    };
    geoJsonData.features.push(featureField);
  });

  return geoJsonData;
};

export const extractGoogleCoords = async (plainText: string) => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(plainText, "text/xml");
  const googlePolygons: Array<KmlPolygon> = [];

  if (xmlDoc.documentElement.nodeName === "kml") {
    for (const item of xmlDoc.getElementsByTagName("Placemark") as any) {
      let polygons = item.getElementsByTagName("Polygon");

      /** POLYGONS PARSE **/
      for (const polygon of polygons) {
        let polygonName = item
          .getElementsByTagName("name")[0]
          .childNodes[0].nodeValue.trim();

        let coords = polygon
          .getElementsByTagName("coordinates")[0]
          .childNodes[0].nodeValue.trim();
        let points = coords.split(" ");

        let googlePolygonsPaths: Array<LatLng> = [];
        for (const point of points) {
          let coord = point.split(",");
          googlePolygonsPaths.push({
            latitude: coord[0],
            longitude: coord[1],
          });
        }
        googlePolygons.push({
          points: googlePolygonsPaths,
          name: polygonName,
        });
      }
    }
  } else {
    throw Error("error while parsing");
  }
  return googlePolygons;
};
