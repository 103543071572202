import React, { FunctionComponent } from "react";
import { Icon } from "../../Icon";

import styles from "./MemberInfo.module.scss";

type MemberInfoProps = {
  memberCount: MemberCount;
  className?: string;
};

export const MemberInfo: FunctionComponent<MemberInfoProps> = ({
  className,
  memberCount,
}) => (
  <div
    className={[
      styles.memberInfoContainer,
      className,
      memberCount.membersInAlertStatus === 0
        ? ""
        : styles.memberInfoContainerAlert,
    ].join(" ")}
  >
    <div className={styles.memberInfoCount}>{memberCount.count}</div>
    <div className={styles.memberInfoIcon}>
      <Icon iconName={memberCount.memberType.toLowerCase()} />
    </div>
  </div>
);
