import {
  Account,
  AccountAttribute,
  CreateAccount,
} from "../admin-site/types/Account";
import request from "./request";
import { IAccountsService } from "./services-types";

export class ProductionAccountsService implements IAccountsService {
  getAccountById = async (account_id: number | string): Promise<Account> => {
    return await request({
      method: "get",
      url: `/api/accounts/${account_id}`,
    });
  };

  getAccountAttributesById = async (
    account_id: number | string
  ): Promise<Array<AccountAttribute>> => {
    return await request({
      method: "get",
      url: `/api/account_attributes/${account_id}`,
    });
  };

  updateAccountAttributesById = async (
    account_id: number | string,
    attributes: Array<AccountAttribute>
  ): Promise<Array<AccountAttribute>> => {
    return await request({
      method: "post",
      url: `/api/account_attributes/${account_id}`,
      data: attributes,
    });
  };

  getAccounts = async (): Promise<Array<Account>> => {
    return await request({
      method: "get",
      url: `/api/accounts`,
    });
  };

  createAccount = async (account: CreateAccount): Promise<Account> => {
    return await request({
      method: "post",
      data: account,
      url: `/api/accounts`,
    });
  };

  getAccountWeather = async (accountId: number): Promise<AccountAttribute> => {
    return await request({
      method: "get",
      url: `/accounts/weather/${accountId}`,
      baseURL: process.env.REACT_APP_NEW_API_URL,
    }).then((res) => {
      return res;
    });
  };
}
