import React, { FunctionComponent } from "react";
import { Link, useHistory } from "react-router-dom";
import { Notification } from "../../../../components/Notification/Notification";
import NANDI_URL from "../../../../lib/url";
import { Alert } from "../../../../types/alerts";
import { NotificationStatus } from "../../../../types/notifications";
import { PageTitle } from "../../../../components/PageTitle/PageTitle.component";
import { Table, TableColumn, TableRow } from "../../../components/Table/Table";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Select } from "../../../../components/shadcn/Select";
import { TableRowV2, TableV2 } from "../../../../components/shadcn/Table/Table";
import { Button } from "../../../../components/shadcn/Button";

type FilterCurrentValue = { value: string | undefined; displayValue: string };

type AlertsPageProps = {
  title: string;
  alerts: Array<Alert>;
  totalAlerts?: number;
  noAlertsMessage: string;
  alertsTableColumns: Array<TableColumn>;
  currentType?: FilterCurrentValue;
  setCurrentType?: (currentType: FilterCurrentValue) => void;
  typeOptions?: FilterCurrentValue[];
  currentStatus?: FilterCurrentValue;
  setCurrentStatus?: (currentType: FilterCurrentValue) => void;
  statusOptions?: FilterCurrentValue[];
  pageNumber?: number;
  setPageNumber?: (value: number) => void;
  totalPages?: number;
  className?: string;
};

const AlertsPage: FunctionComponent<AlertsPageProps> = ({
  title,
  alerts,
  totalAlerts,
  noAlertsMessage,
  alertsTableColumns,
  currentType,
  setCurrentType,
  typeOptions,
  currentStatus,
  setCurrentStatus,
  statusOptions,
  pageNumber,
  setPageNumber,
  totalPages,
  className,
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  const searchParams = new URLSearchParams(window.location.search);

  const addQuery = (paramKey: string, paramValue: string) => {
    searchParams.set(paramKey, paramValue);
    history.push({ search: searchParams.toString() });
  };

  return (
    <div className={classNames("bg-[#F2F2F7] px-8 pt-10 py-5", className)}>
      <PageTitle>{title}</PageTitle>
      <div className="flex justify-between pr-4 items-center w-full mt-4 flex-col pt-4 p-6 rounded-lg border bg-card text-card-foreground">
        <div className="flex w-full justify-end">
          <div className={"flex w-[60%] gap-4 justify-end"}>
            {currentType && setCurrentType && typeOptions ? (
              <div className={"flex flex-col"}>
                <Select
                  defaultLabel={t("alertsTable.typeFilter.title")}
                  selectedOption={currentType}
                  onChange={(e) => {
                    addQuery("alert_type", e.currentTarget.value);

                    setCurrentType(
                      typeOptions.filter(
                        (x) => x.value === e.currentTarget.value
                      )[0] ?? typeOptions[0]
                    );
                  }}
                  options={typeOptions}
                />
              </div>
            ) : null}
            {currentStatus && setCurrentStatus && statusOptions ? (
              <div className="flex flex-col">
                <Select
                  defaultLabel={t("alertsTable.statusFilter.title")}
                  selectedOption={currentStatus}
                  onChange={(e) => {
                    addQuery("current_status", e.currentTarget.value);

                    setCurrentStatus(
                      statusOptions.filter(
                        (x) => x.value === e.currentTarget.value
                      )[0] ?? statusOptions[0]
                    );
                  }}
                  options={statusOptions}
                />
              </div>
            ) : null}
          </div>
        </div>
        <main className="mt-4 w-full">
          {alerts.length > 0 ? (
            <TableV2
              columns={alertsTableColumns}
              renderTableRow={false}
              items={alerts}
            >
              {alerts.map((alert) => (
                <TableRowV2 key={alert.id}>
                  {alertsTableColumns.map((col, key) => (
                    <td key={key}>
                      <div className="p-2">{col.render(alert)}</div>
                    </td>
                  ))}
                </TableRowV2>
              ))}
            </TableV2>
          ) : (
            <Notification
              className="w-max"
              status={NotificationStatus.INFORMATION}
            >
              {noAlertsMessage}
            </Notification>
          )}
          {totalAlerts && totalAlerts > 0 ? (
            <div className="mt-4 w-full flex justify-between">
              <p className="text-sm text-[#64748B]">
                {t("alertsTable.totalAlerts", { alerts: totalAlerts })}
              </p>
              {pageNumber && setPageNumber ? (
                <div className="flex gap-x-4">
                  <Button
                    label={t("alertsTable.cta.previous")}
                    disabled={pageNumber === 1}
                    onClick={() => {
                      if (pageNumber && setPageNumber && pageNumber > 1) {
                        setPageNumber(pageNumber - 1);
                      }
                    }}
                  />
                  <Button
                    disabled={pageNumber === totalPages}
                    label={t("alertsTable.cta.next")}
                    onClick={() => {
                      if (
                        totalPages &&
                        pageNumber &&
                        setPageNumber &&
                        pageNumber < totalPages
                      ) {
                        setPageNumber(pageNumber + 1);
                      }
                    }}
                  />
                </div>
              ) : null}
            </div>
          ) : null}
        </main>
      </div>
    </div>
  );
};

export { AlertsPage };
