import React, { FunctionComponent } from "react";
import NANDI_URL from "../../lib/url";

import styles from "./Footer.module.scss";
import { FooterLink } from "./FooterLink/FooterLink";

interface FooterProps {}

const menuLinks = [
  {
    linkName: "Home",
    linkIcon: "home",
    linkTo: NANDI_URL.APP.HOME,
  },
  {
    linkName: "Alerts",
    linkIcon: "alerts_footer",
    linkTo: NANDI_URL.APP.ALERTS,
  },
  {
    linkName: "Monitor",
    linkIcon: "monitor",
    linkTo: NANDI_URL.APP.MONITOR,
  },
  {
    linkName: "Map",
    linkIcon: "map",
    linkTo: NANDI_URL.APP.MAP,
  },
  {
    linkName: "Settings",
    linkIcon: "settings",
    linkTo: NANDI_URL.APP.SETTINGS,
  },
];

export const Footer: FunctionComponent<FooterProps> = () => {
  return (
    <div className={styles.navbar}>
      {menuLinks.map((menuLink) => (
        <FooterLink
          key={menuLink.linkName}
          linkIcon={menuLink.linkIcon}
          linkName={menuLink.linkName}
          linkTo={menuLink.linkTo}
        />
      ))}
    </div>
  );
};
