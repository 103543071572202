import classNames from "classnames";
import moment from "moment";
import React, { FunctionComponent, useState } from "react";
import { Alert } from "../../../../types/alerts";

type Props = {
  className?: string;
  timeline: Timeline;
};

type EventsDisplayProps = {
  events?: MemberEvent[];
  alerts?: Alert[];
  focusMode?: boolean;
};

type EventDisplayProps = {
  memberEvent: MemberEvent;
  className?: string;
};

type AlertDisplayProps = {
  alert: Alert;
  className?: string;
};

type TooltipProps = {};

const MonthSelector: FunctionComponent<
  MonthlyTimeline & { focusMode?: boolean; faux?: boolean }
> = ({ label, events, alerts, focusMode = false, faux = false }) => {
  const styles = {
    disabledStyles:
      (!events || events.length === 0) && (!alerts || alerts.length === 0),
    enabledStyles:
      (events && events.length > 0) || (alerts && alerts.length > 0),
    hasAlertEvents: alerts && alerts.length > 0,
  };
  return (
    <div
      className={classNames("p-2 rounded", {
        "bg-gray-200": styles.disabledStyles,
        "bg-gray-800": styles.enabledStyles,
        "flex-auto": focusMode,
        invisible: faux,
      })}
    >
      <p
        className={classNames("text-center", {
          "text-gray-400": styles.disabledStyles,
          "text-white": styles.enabledStyles,
        })}
      >
        {label}
      </p>
    </div>
  );
};

const AlertDisplay: FunctionComponent<AlertDisplayProps> = ({
  alert,
  className,
}) => {
  const styles = {};
  const [displayTooltip, setDisplayTooltip] = useState(false);

  return (
    <main className={classNames(className, "relative")}>
      <div
        onMouseOver={() => setDisplayTooltip(true)}
        onMouseOut={() => setDisplayTooltip(false)}
        className={classNames(
          "p-2 rounded bg-yellow-100 border-yellow-400 border",
          {}
        )}
      >
        <p className={classNames("text-center font-medium text-yellow-800")}>
          {alert.alert_type}
        </p>
      </div>

      {displayTooltip ? (
        <Tooltip>
          <section>
            <p className="font-semibold text-sm">{alert.alert_type}</p>
            <div className="border-l-2 border-gray-200 pl-1">
              <p className="mt-0.5 text-sm">
                {moment(alert.date).format("MMMM DD, YYYY")}
              </p>
              <p className="mt-0.5 text-sm">Potrero {alert.field_name}</p>
            </div>
          </section>
        </Tooltip>
      ) : null}
    </main>
  );
};

const Tooltip: FunctionComponent<TooltipProps> = ({ children }) => (
  <div className="absolute z-10 w-max bg-white border shadow-lg border-gray-500 p-4 rounded mt-2">
    <section>{children}</section>
  </div>
);

const EventDisplay: FunctionComponent<EventDisplayProps> = ({
  memberEvent,
  className,
}) => {
  const styles = {
    heatStyles: memberEvent.type === "HEAT",
    healthCheckStyles:
      memberEvent.type === "VACCINATION" || memberEvent.type === "WEIGHT",
    informationStyles:
      memberEvent.type === "SENSOR_PAIRING" || memberEvent.type === "BIRTH",
  };

  const valueModifier = {
    label:
      memberEvent.type === "WEIGHT"
        ? "kgs"
        : memberEvent.type === "HEAT"
        ? "steps"
        : null,
  };

  const [displayTooltip, setDisplayTooltip] = useState(false);

  return (
    <main className={classNames(className, "relative")}>
      <div
        onMouseOver={() => setDisplayTooltip(true)}
        onMouseOut={() => setDisplayTooltip(false)}
        className={classNames("p-2 rounded", {
          "border-red-400 border bg-red-100": styles.heatStyles,
          "border-green-400 border bg-green-100": styles.healthCheckStyles,
          "border-black-400 border bg-white-100": styles.informationStyles,
        })}
      >
        <p
          className={classNames("text-center text-sm overflow-hidden", {
            "text-red-800 font-medium": styles.heatStyles,
            "text-green-800 font-medium": styles.healthCheckStyles,
            "text-black-800 font-medium": styles.informationStyles,
          })}
        >
          {memberEvent.type}
        </p>
      </div>
      {displayTooltip ? (
        <Tooltip>
          <section>
            <p className="font-semibold text-sm">{memberEvent.type}</p>
            <div className="border-l-2 border-gray-200 pl-1">
              <p className="mt-0.5 text-sm">
                {moment.unix(memberEvent.ts).format("MMMM DD, YYYY")}
              </p>
              <p className="mt-0.5 text-sm">
                {memberEvent.value} {valueModifier.label}
              </p>
            </div>
          </section>
        </Tooltip>
      ) : null}
    </main>
  );
};

const EventsDisplay: FunctionComponent<EventsDisplayProps> = ({
  events,
  alerts,
  focusMode,
}) => (
  <main className={classNames({ "flex-auto": focusMode })}>
    <section>
      <>
        {alerts
          ? alerts.map((a) => (
              <AlertDisplay className="mt-1" key={a.id} alert={a} />
            ))
          : null}
      </>
      <>
        {events
          ? events.map((e) => (
              <EventDisplay className="mt-1" key={e.id} memberEvent={e} />
            ))
          : null}
      </>
    </section>
  </main>
);

const YearSelector: FunctionComponent<{
  onClick: () => void;
  selected: boolean;
}> = ({ children, selected, onClick }) => (
  <h1
    className={classNames("font-medium text-xl p-2 rounded cursor-pointer", {
      "bg-gray-300": !selected,
      "bg-gray-800": selected,
      "text-white": selected,
      "text-gray-800": !selected,
    })}
    onClick={onClick}
  >
    {children}
  </h1>
);

export const Timeline: FunctionComponent<Props> = ({ className, timeline }) => {
  const [focusMode, setFocusMode] = useState<boolean>(false);
  const currentYear = moment().year();
  const [selectedYear, setSelectedYear] = useState<number>(currentYear);

  const lastFourYears = Array.from({ length: 4 }, (x, i) => currentYear - i);

  return (
    <main className={className}>
      <header>
        <section>
          <div className="flex space-x-2">
            {lastFourYears.map((x, i) => (
              <YearSelector
                key={i}
                selected={selectedYear === x}
                onClick={() => setSelectedYear(x)}
              >
                {x}
              </YearSelector>
            ))}
          </div>
          <div>
            <input
              type="checkbox"
              value="FocusMode"
              checked={focusMode}
              onChange={() => setFocusMode(!focusMode)}
            />
            Focus Mode
          </div>
          <div
            className={classNames("mt-2 w-full  gap-[2px]", {
              "grid grid-cols-12": !focusMode,
              flex: focusMode,
            })}
          >
            {timeline[selectedYear].map(({ events, alerts, label }) => (
              <MonthSelector
                events={events}
                alerts={alerts}
                key={label}
                focusMode={
                  focusMode &&
                  ((events && events?.length > 0) ||
                    (alerts && alerts?.length > 0))
                }
                label={label}
              />
            ))}
          </div>
        </section>
      </header>
      <main
        className={classNames("mt-2  gap-[2px]", {
          "grid grid-cols-12": !focusMode,
          flex: focusMode,
        })}
      >
        {timeline[selectedYear].map(({ events, alerts, label }) => {
          return (events && events?.length > 0) ||
            (alerts && alerts?.length > 0) ? (
            <EventsDisplay
              key={label}
              events={events}
              alerts={alerts}
              focusMode={
                focusMode &&
                ((events && events?.length > 0) ||
                  (alerts && alerts?.length > 0))
              }
            />
          ) : (
            <MonthSelector faux key={label} label={label} />
          );
        })}
      </main>
    </main>
  );
};
