import React, { FunctionComponent, useEffect, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { Loader } from "../../../components/Loader/Loader";
import { Notification } from "../../../components/Notification/Notification";
import { isMsgError } from "../../../lib/error";
import { useServices } from "../../../services/service-providers/service-provider";
import { RootState } from "../../../store/store";
import {
  createGeoJsonData,
  FieldSummaryWithExtraDetails,
  Property,
} from "../../../types/fields";
import { NotificationStatus } from "../../../types/notifications";
import withLoaderHOC, {
  WithLoaderProps,
} from "../../../utils/hocs/WithLoaderHOC";
import { AdminHomePageV2 } from "./layout/V2";
import { AccountAttribute } from "../../types/Account";

type AdminHomeState = {
  fields: Array<FieldSummaryWithExtraDetails>;
  members: Array<MemberWithDetails>;
  weather: AccountAttribute | null;
  geoJsonData: GeoJSON.FeatureCollection<GeoJSON.Geometry, Property>;
};

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = WithLoaderProps & PropsFromRedux & WithTranslation;

const initialState: AdminHomeState = {
  fields: [],
  members: [],
  weather: null,
  geoJsonData: {
    type: "FeatureCollection",
    features: [],
  },
};

const AdminHomePageV2Container: FunctionComponent<Props> = ({
  selectedAccount,
  isLoading,
  t,
  setLoading,
  setError,
}) => {
  const [state, setState] = useState(initialState);
  const { fieldsService, membersService, accountsService } = useServices();
  const getData = async () => {
    if (selectedAccount && selectedAccount.id) {
      try {
        setLoading(true);
        let params = [{ key: "account_id", value: String(selectedAccount.id) }];
        // might get members from spreading fields.(f => f.members)
        const members = await membersService.getMembers(params);

        const fields = await fieldsService.getFieldsWithMembersInventory(
          selectedAccount.id
        );
        const fieldAttachments = await fieldsService.getFieldAttachments(
          selectedAccount.id
        );

        const weather = await accountsService.getAccountWeather(
          Number(selectedAccount.id)
        );

        const geoJsonInfo = createGeoJsonData({
          fields,
          ref: "admin",
          members,
          fieldAttachments,
        });
        setState({
          ...state,
          fields,
          geoJsonData: geoJsonInfo,
          members,
          weather,
        });
        setLoading(false);
      } catch (error) {
        if (isMsgError(error)) setError(error);
        setLoading(false);
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [selectedAccount?.id]);

  return (
    <Loader isLoading={isLoading}>
      {selectedAccount ? (
        <AdminHomePageV2
          title={t("admin.homePage.title", {
            accountName: selectedAccount?.name,
          })}
          fields={state.fields}
          geoJsonData={state.geoJsonData}
          members={state.members}
          selectedAccount={selectedAccount}
          weatherData={state.weather}
        />
      ) : (
        <Notification
          status={NotificationStatus.INFORMATION}
          className="m-2 w-max"
        >
          No accounts created for this user
        </Notification>
      )}
    </Loader>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    selectedAccount: state.accounts.selectedAccount
      ? {
          ...state.accounts.selectedAccount,
          id: String(state.accounts.selectedAccount.id),
        }
      : null,
    user: state.users.user,
  };
};

const connector = connect(mapStateToProps);

export default connector(
  withTranslation()(withLoaderHOC(AdminHomePageV2Container))
);
