import React from "react";
import Catch from "../../lib/error-boundary";

import styles from "./ErrorBoundary.module.scss";

type Props = {
  children: React.ReactNode;
};

const ErrorBoundary = Catch((props: Props, error?: Error) => {
  if (error) {
    return (
      <div className={styles.main}>
        <h2 className="flex items-center">
          <span className="text-8xl text-purple-800 p-8 border-r">404</span>
          <span className="p-8 text-xl">There was an error</span>
        </h2>
      </div>
    );
  } else {
    return <React.Fragment>{props.children}</React.Fragment>;
  }
});

export { ErrorBoundary };
