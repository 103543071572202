import React, { FunctionComponent, useContext, ChangeEvent } from "react";
import { useHistory } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import { ServicesContext } from "../../../services/service-providers/service-provider";
import { RootState } from "../../../store/store";
import SelectAccountComponent from "./SelectAccountComponent";

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

const SelectAccountContainer: FunctionComponent<Props> = ({
  accounts,
  user,
}) => {
  const { usersService } = useContext(ServicesContext);
  const history = useHistory();

  const handleChangeAccount = async (e: ChangeEvent<HTMLSelectElement>) => {
    try {
      await usersService.changeUserAccount(Number(e.target.value));
      history.go(0);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    user && (
      <SelectAccountComponent
        accounts={accounts}
        onChange={handleChangeAccount}
        user={user}
      />
    )
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    accounts: state.accounts.accounts,
    user: state.users.user,
  };
};

const connector = connect(mapStateToProps);

export default connector(SelectAccountContainer);
