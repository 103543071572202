import { Account } from "../../admin-site/types/Account";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

interface AccountsState {
  accounts: Array<Account>;
  selectedAccount: Account;
}

const initialState: AccountsState = {
  accounts: [],
  selectedAccount: {
    id: 0,
    name: "",
    disabled: false,
    demo: false,
  },
};

export const accountsSlice = createSlice({
  name: "accounts",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    saveAccounts: (state, action: PayloadAction<Array<Account>>) => {
      state.accounts = action.payload;
    },
    setSelectedAccount: (state, action: PayloadAction<Account>) => {
      state.selectedAccount = action.payload;
    },
  },
});

const { saveAccounts, setSelectedAccount } = accountsSlice.actions;
export const accountsActions = {
  saveAccounts,
  setSelectedAccount,
};
// Other code such as selectors can use the imported `RootState` type
export const selectAccounts = (state: RootState) => state.accounts.accounts;

export default accountsSlice.reducer;
