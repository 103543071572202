import React, { FunctionComponent } from "react";
import { Route, Switch } from "react-router";
import AlertsMainPage from "./AlertsMainPage/AlertsMainPage";
import AlertDetailPage from "./AlertDetailPage/AlertDetailPage";
import { WithTranslation, withTranslation } from "react-i18next";
import NANDI_URL from "../../lib/url";
import Header from "../../components/Header";

const AlertsPage: FunctionComponent<WithTranslation> = ({ t }) => (
  <>
    <Header>{t("alertsPage.title")}</Header>
    <div>
      <Switch>
        <Route path={NANDI_URL.APP.ALERTS} exact component={AlertsMainPage} />
        <Route
          path={`${NANDI_URL.APP.ALERTS}/:alertId`}
          exact
          render={({ match }) => (
            <AlertDetailPage alertId={match.params.alertId} />
          )}
        />
      </Switch>
    </div>
  </>
);

export default withTranslation()(AlertsPage);
