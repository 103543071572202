import React, {
  FormEvent,
  FunctionComponent,
  useEffect,
  useState,
} from "react";
//Components
import { HealthCalendarMain } from "./Main";
//Utils
import classNames from "classnames";
import moment from "moment";
import { useServices } from "../../../services/service-providers/service-provider";
import {
  CreateHealthCalendarEventDto,
  HealthCalendarEntityKey,
  HealthCalendarEvent,
  HealthCalendarNewEventData,
} from "../../../types/health-calendar";
import { LoadEventModal } from "./LoadEventModal";
import { ViewDetailModal } from "../ViewDetailModal";
import { Button, TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { buttonStyles } from "../../MUI-styles/button";

type Props = { className?: string; selectedAccount: any };

export type YearlyEvents = {
  [year: number]: {
    [month: string]: HealthCalendarEvent[];
  };
};

export const HealthCalendar: FunctionComponent<Props> = ({
  className,
  selectedAccount,
}) => {
  const [tabs, setTabs] = useState<HealthCalendarEntityKey[]>([]);
  const [openNewEventModal, setOpenNewEventModal] = useState(false);
  const [viewDetail, setViewDetail] = useState<HealthCalendarEvent | null>(
    null
  );
  const { healthCalendarEventsService } = useServices();
  const [events, setEvents] = useState<HealthCalendarEvent[]>([]);
  const [filteredEvents, setFilteredEvents] = useState<YearlyEvents | null>(
    null
  );
  const [calendarDateSelected, setCalendarDateSelected] = useState<{
    month: undefined | number;
    year: undefined | number;
  }>({ month: undefined, year: undefined });

  const { month, year } = calendarDateSelected;

  const { t } = useTranslation();

  const handleFilterEvents = () => {
    setFilteredEvents(
      events
        .filter((e) => {
          if ((!month && !year) || month === -1) {
            // init and cleaned state, upcoming events
            return (
              e.year >= moment().year() &&
              moment(e.month, "MMMM").month() >=
                Number(moment().format("YYYY-MM").split("-")[1]) - 1
            );
          }
          // month and year selected
          return moment(e.month, "MMMM").month() === month && e.year === year;
        })
        .sort((a, b) => Number(a.creation_date) - Number(b.creation_date))
        .reduce((acc: YearlyEvents, x: HealthCalendarEvent, i) => {
          if (!acc[x.year]) {
            acc[x.year] = {};
          }
          if (acc[x.year]?.[x.month]) {
            acc[x.year][x.month] = [...acc[x.year][x.month], x];
          } else {
            acc[x.year][x.month] = [x];
          }
          return acc;
        }, {})
    );
  };
  const getHealthCalendarEvents = async () => {
    if (selectedAccount && selectedAccount.id) {
      await healthCalendarEventsService
        .getEvents(selectedAccount.id)
        .then((events) => {
          setEvents(events);
        })
        .catch((err) => {
          throw new Error(err);
        });
    }
  };

  const handleRemoveHealthCalendarEvent = async (eventId: number) => {
    await healthCalendarEventsService
      .deleteOne(eventId)
      .then((events) => {
        getHealthCalendarEvents();
        setViewDetail(null);
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

  const getHealthCalendarEntityKeys = async () => {
    if (selectedAccount && selectedAccount.id) {
      await healthCalendarEventsService
        .getEntityKeys(selectedAccount.id)
        .then((tabs) => {
          setTabs(tabs);
        })
        .catch((err) => {
          throw new Error(err);
        });
    }
  };
  // TO DO REFACTOR THIS TO COMMON STYLES

  const MUIStyles = buttonStyles();

  // New event Modal ////////////////
  const generateNewEventInitData: () => HealthCalendarNewEventData = () => {
    return {
      entityType: "ALL",
      date: moment().format("YYYY-MM"),
      accountable: "",
      details: "",
      title: "",
    };
  };

  const [newEventData, setNewEventData] = useState<HealthCalendarNewEventData>(
    generateNewEventInitData()
  );

  const handleCreateHealthCalendarEvent = async (
    event: FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    if (selectedAccount && selectedAccount.id) {
      const monthNumber = Number(newEventData.date.split("-")[1]) - 1;
      const monthName = moment()
        .month(monthNumber)
        .format("MMMM")
        .toUpperCase();

      const newHealthCalendarEvent: CreateHealthCalendarEventDto = {
        accountId: Number(selectedAccount.id),
        entityKey: newEventData.entityType,
        name: newEventData.title,
        accountable: newEventData.accountable,
        detail: newEventData.details,
        month: monthName,
        year: newEventData.date.split("-")[0],
      };

      await healthCalendarEventsService
        .create(newHealthCalendarEvent)
        .then((res) => {
          setOpenNewEventModal(false);
          getHealthCalendarEvents();
        })
        .catch((err) => {
          setOpenNewEventModal(false);
          throw new Error(err);
        });
    }
  };

  const handleChangeNewEvent = (
    event: React.ChangeEvent<{ name?: unknown; value: unknown }>
  ) => {
    setNewEventData({
      ...newEventData,
      [`${event.target.name}`]: event.target.value,
    });
  };

  const handleCancelNewEvent = () => {
    setOpenNewEventModal(false);
    setNewEventData(generateNewEventInitData());
  };

  //////////////////

  useEffect(() => {
    getHealthCalendarEvents();
    getHealthCalendarEntityKeys();
  }, []);

  useEffect(() => {
    handleFilterEvents();
  }, [month, year, events]);

  return (
    <section
      className={classNames(
        "flex flex-col w-full pt-4 p-6 rounded-lg border bg-card text-card-foreground ",
        className
      )}
    >
      <div className="flex justify-between w-full mb-4">
        <h1 className="font-medium text-[24px] leading-[30px] text-[#18181B]">
          {t("admin.mainPage.healthCalendar.title")}
        </h1>
        <div className="flex items-center gap-x-8">
          <TextField
            id="date"
            label="Date"
            type="month"
            value={
              (month || month === 0) && year
                ? `${year}-${month > 8 ? "" : "0"}${month + 1}`
                : ""
            }
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              setCalendarDateSelected({
                year: Number(e.currentTarget.value.slice(0, 4)),
                month: Number(e.currentTarget.value.slice(5, 7)) - 1,
              });
            }}
          />
          <button
            className={
              "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2"
            }
            onClick={() => setOpenNewEventModal(true)}
          >
            {t("admin.mainPage.healthCalendar.event.create.new")}
          </button>
        </div>
      </div>

      <div
        className={
          "flex w-full flex-col max-h-full bg-white rounded-lg items-end"
        }
      >
        <LoadEventModal
          tabs={tabs}
          openModal={openNewEventModal}
          setOpenModal={(value: boolean) => setOpenNewEventModal(value)}
          newEventData={newEventData}
          handleSubmit={handleCreateHealthCalendarEvent}
          handleChange={handleChangeNewEvent}
          handleCancel={handleCancelNewEvent}
        />
        <ViewDetailModal
          open={viewDetail !== null}
          detail={viewDetail?.detail ?? ""}
          id={viewDetail?.id ?? 0}
          title={viewDetail?.name ?? ""}
          accountable={viewDetail?.accountable_to ?? ""}
          closeModal={() => setViewDetail(null)}
          handleRemoveEvent={handleRemoveHealthCalendarEvent}
        />
        <HealthCalendarMain
          events={filteredEvents}
          handleViewDetail={(event: HealthCalendarEvent) =>
            setViewDetail(event)
          }
        />
      </div>
    </section>
  );
};
