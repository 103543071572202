import React, { FunctionComponent, useEffect, useState } from "react";

import { FieldDetailsSensorsInfo } from "../../../components/FieldDetailsSensorInfo/FieldDetailsSensorsInfo";
import { RouteComponentProps, withRouter } from "react-router";
import { FieldDetailsAlertsBox } from "../../../components/FieldDetailsAlertsBox/FieldDetailsAlertsBox";

import containerStyle from "../FieldPage.module.scss";
import withLoaderHOC, {
  WithLoaderProps,
} from "../../../utils/hocs/WithLoaderHOC";
import { useServices } from "../../../services/service-providers/service-provider";
import {
  createGeoJsonData,
  FieldSummaryWithDetails,
  FieldSummaryWithExtraDetails,
  Property,
} from "../../../types/fields";
import { WithTranslation, withTranslation } from "react-i18next";
import { isMsgError } from "../../../lib/error";
import { Loader } from "../../../components/Loader/Loader";
import { Link } from "react-router-dom";
import NANDI_URL from "../../../lib/url";
import { Button } from "../../../components/Button/Button";

type FieldDetailsPageState = {
  field: FieldSummaryWithExtraDetails | null;
  geoJsonData?: GeoJSON.FeatureCollection<GeoJSON.Geometry, Property>;
  status: boolean;
};

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> &
  WithTranslation &
  WithLoaderProps;

const FieldDetailsPage: FunctionComponent<Props> = ({
  match,
  history,
  isLoading,
  t,
  setError,
  setLoading,
}) => {
  const fieldId = match.params.id;
  const [state, setState] = useState<FieldDetailsPageState>({
    field: null,
    status: false,
  });
  const { fieldsService } = useServices();

  const getField = async () => {
    try {
      const field = await fieldsService.getFieldById(fieldId);
      const members = await fieldsService.getMembersByFieldIdAndMemberType(
        fieldId,
        "ALL"
      );

      const geoJsonData = createGeoJsonData({
        fields: [field],
        ref: "app",
        members: members
          .filter((x) => x.status !== "ARCHIV")
          .map((x: any) => {
            return { ...x, last_position: x?.last_position?.value };
          }),
      });
      const isFieldGeneralStatusAlert = field?.members.some(
        (member) => member.status === "ALERT"
      );

      setState({
        field,
        geoJsonData,
        status: isFieldGeneralStatusAlert || false,
      });
      setLoading(false);
    } catch (error) {
      if (isMsgError(error)) setError(error);
      console.log(error);
    }
  };

  useEffect(() => {
    getField();
  }, []);

  const goToMemberInfoDetailPage = (sensor_type: string): void => {
    history.push(`${match.url}/${sensor_type.toLowerCase()}`);
  };

  const goToAllAlertsPage = (): void => {
    history.push(`${match.url}/alerts`);
  };

  return (
    <Loader isLoading={isLoading}>
      <div className={containerStyle.fieldDetails}>
        <div className="mt-4">
          <h2 className="font-semibold">{t("fieldDetailsPage.alertsTitle")}</h2>
          <FieldDetailsAlertsBox
            className="mt-2"
            onClick={goToAllAlertsPage}
            alert={state.field?.last_field_alert}
          />
        </div>
        <Link
          className="block mt-4"
          to={NANDI_URL.APP.GENERATE_MONITOR_BY_ID_MAP(fieldId)}
        >
          <Button>Ver Mapa</Button>
        </Link>
      </div>
    </Loader>
  );
};

export default withTranslation()(withRouter(withLoaderHOC(FieldDetailsPage)));
