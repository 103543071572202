import React, { FunctionComponent } from "react";
// Components
import DailyBullJumpsComponent from "./DailyBullJumpsComponent";
import { useTranslation } from "react-i18next";

type Props = {
  dailyBullJumps: DailyActivityV2 | null;
};

const DailyBullJumpsContainer: FunctionComponent<Props> = ({
  dailyBullJumps,
}) => {
  const { t } = useTranslation();
  return dailyBullJumps !== null && Object.keys(dailyBullJumps).length > 0 ? (
    <DailyBullJumpsComponent
      labels={Object.keys(dailyBullJumps)}
      datasets={[
        {
          label: t("admin.cattlePage.cattleID.bullJumps"),
          data: Object.values(dailyBullJumps).map((x) => x.count),
          backgroundColor: "rgba(75, 192, 192, 0.5)",
        },
      ]}
    />
  ) : null;
};

export default DailyBullJumpsContainer;
