import classNames from "classnames";
import React, { FunctionComponent } from "react";
import { Icon } from "../../../components/Icon";

type PaddockInventoryDetailComponentProps = {
  label: string;
  count: string;
  className?: string;
  iconName: string;
};

export const InventoryDetailComponent: FunctionComponent<
  PaddockInventoryDetailComponentProps
> = ({ label, count, className = "", iconName }) => {
  return (
    <div
      className={classNames(
        "flex items-center justify-between gap-x-4 border-r-[1px] border-[#CFD4E8] border-dotted pr-[1.5rem] last-of-type:border-none",
        className
      )}
    >
      <Icon iconName={iconName} className="w-12" />
      <div className="flex flex-col justify-center">
        <p
          className={
            "text-[#0F172A] text-[1.5rem] font-bold tracking-[0.015rem]"
          }
        >
          {count}
        </p>
        <p
          className={
            "text-[#0F172A] text-[0.75rem] font-normal tracking-[0.015rem]"
          }
        >
          {label}
        </p>
      </div>
    </div>
  );
};
