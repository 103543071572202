import React, { FunctionComponent, useEffect, useState } from "react";

import { RouteComponentProps, withRouter } from "react-router";
import { FieldAlertsListElement } from "../../../components/FieldAlertsListElement/FieldAlertsListElement";

import styles from "./FieldAlertsPage.module.scss";
import containerStyle from "../FieldPage.module.scss";
import { Alert } from "../../../types/alerts";
import withLoaderHOC, {
  WithLoaderProps,
} from "../../../utils/hocs/WithLoaderHOC";
import { Icon } from "../../../components/Icon";
import { Loader } from "../../../components/Loader/Loader";
import { useServices } from "../../../services/service-providers/service-provider";
import { WithTranslation, withTranslation } from "react-i18next";
import { isMsgError } from "../../../lib/error";
import NANDI_URL from "../../../lib/url";

type FieldAlertsPageState = {
  alerts: Array<Alert>;
};

interface MatchParams {
  id: string;
  member_id?: string;
}

type Props = RouteComponentProps<MatchParams> &
  WithTranslation &
  WithLoaderProps;

const FieldAlertsPage: FunctionComponent<Props> = ({
  match,
  t,
  isLoading,
  setLoading,
  setError,
}) => {
  const [state, setState] = useState<FieldAlertsPageState>({
    alerts: [],
  });

  const { membersService, fieldsService } = useServices();

  const getAlerts = async () => {
    const memberId = match.params.member_id;
    const fieldId = match.params.id;

    try {
      const alerts = memberId
        ? await membersService.getMemberAlerts(Number(memberId))
        : await fieldsService.getFieldAlerts(fieldId);

      setState({
        ...state,
        alerts: alerts,
      });
      setLoading(false);
    } catch (error) {
      if (isMsgError(error)) setError(error);
    }
  };

  useEffect(() => {
    getAlerts();
  }, []);

  return (
    <div className={containerStyle.fieldAlertsPage}>
      <Loader isLoading={isLoading}>
        <div className={styles.filterContainer}>
          <div className={styles.sortTitle}>{t("general.sort")}</div>
          <div className={styles.arrowIconContainer}>
            <Icon iconName={"arrow_down"} />
          </div>
        </div>
        <div className={styles.alertsList}>
          {state.alerts.map((alert) => (
            <FieldAlertsListElement
              key={alert.id}
              className={styles.alertListElementBackground}
              alert={alert}
              link={`${NANDI_URL.APP.ALERTS}/${alert.id}`}
            />
          ))}
        </div>
      </Loader>
    </div>
  );
};

export default withTranslation()(withRouter(withLoaderHOC(FieldAlertsPage)));
