import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './AlertsPageModal.module.scss';

interface AlertsPageModalProps {
  displayModal: boolean;
  onDelete: { (): void } | null;
  onClose: () => void;
}

export const AlertsPageModal: FunctionComponent<AlertsPageModalProps> = (
  props
) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        onClick={() => props.onClose()}
        className={[
          styles.modalBackground,
          props.displayModal ? styles.modalDisplay : '',
        ].join(' ')}
      />
      <div
        className={[
          styles.modalContent,
          props.displayModal ? styles.modalDisplay : '',
        ].join(' ')}
      >
        <div className={styles.modalTitle}>
          {t('alertsPage.alertPageModal.confirmTitle')}
        </div>
        <div className={styles.modalButtonsContainer}>
          <button
            className={styles.modalButton}
            onClick={() => props.onClose()}
          >
            {t('alertsPage.alertPageModal.cancelButton')}
          </button>
          <div className={styles.buttonDivider}></div>
          <button
            className={styles.modalButton}
            onClick={() => (props.onDelete !== null ? props.onDelete() : {})}
          >
            {t('alertsPage.alertPageModal.confirmButton')}
          </button>
        </div>
      </div>
    </>
  );
};
