enum NotificationStatus {
  OK = "OK",
  ERROR = "ERROR",
  INFORMATION = "INFORMATION",
}

export type Notification = {
  status: NotificationStatus;
  message: string;
};

export { NotificationStatus };
