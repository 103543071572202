import React, { FunctionComponent, useEffect, useState } from "react";

import { RouteComponentProps, withRouter } from "react-router";
import { Alert } from "../../../types/alerts";

import styles from "./AlertDetailPage.module.scss";
import { Icon } from "../../../components/Icon";
import { AlertCard } from "../../../components/AlertCard/AlertCard";
import { AlertPageButton } from "../../../components/AlertPageButton/AlertPageButton";
import withModalHOC, { WithModalProps } from "../../../utils/hocs/withModalHOC";
import withLoaderHOC, {
  WithLoaderProps,
} from "../../../utils/hocs/WithLoaderHOC";
import { Loader } from "../../../components/Loader/Loader";
import { useServices } from "../../../services/service-providers/service-provider";
import { WithTranslation, withTranslation } from "react-i18next";
import { isMsgError } from "../../../lib/error";
import { Link } from "react-router-dom";
import NANDI_URL from "../../../lib/url";
import classNames from "classnames";

type AlertDetailPageState = {
  alert: Alert | null;
};

type Props = { alertId: string } & RouteComponentProps &
  WithModalProps &
  WithTranslation &
  WithLoaderProps;

const AlertDetailPage: FunctionComponent<Props> = ({
  alertId,
  history,
  isLoading,
  t,
  setLoading,
  setError,
  setConfirmModalAction,
  setModalDisplayState,
}) => {
  const [state, setState] = useState<AlertDetailPageState>({ alert: null });
  const { alertsService } = useServices();

  const getAlert = async () => {
    setLoading(true);

    try {
      const alert = await alertsService.updateAlert(alertId, true);

      setState({
        ...state,
        alert: alert,
      });
      setLoading(false);
    } catch (error) {
      if (isMsgError(error)) setError(error);
    }
  };

  useEffect(() => {
    setConfirmModalAction(onDeleteAlert);
    getAlert();
  }, []);

  const goBack = () => {
    history.goBack();
  };

  const onDeleteAlert = async () => {
    if (state.alert) {
      await alertsService.deleteAlert(state.alert.id);
      setModalDisplayState(false);
      history.goBack();
    }
  };

  return (
    <div className={"p-4"}>
      <div className={styles.goBackHeader}>
        <div className={styles.goBackIconContainer}>
          <button className={styles.buttonGoBack} onClick={goBack}>
            <Icon iconName={"arrow_forward"} />
          </button>
        </div>
        <div
          onClick={() => setModalDisplayState(true)}
          className={styles.deleteContainer}
        >
          <div className={styles.deleteIconContainer}>
            <Icon iconName={"delete"} />
          </div>
          <div className={styles.deleteTitle}>{t("general.delete")}</div>
        </div>
      </div>
      <Loader isLoading={isLoading}>
        {state.alert !== null ? (
          <>
            <AlertCard alert={state.alert} />
            <div className={styles.buttonsListContainer}>
              <Link
                to={`${NANDI_URL.APP.MEMBERS}/${state.alert.entity_type}/${state.alert.entity_id}`}
              >
                <AlertPageButton
                  className={styles.button}
                  title={t("alertsPage.goToMemberButton")}
                  iconName={state.alert.entity_type.toLowerCase()!!}
                />
              </Link>
              <Link to={`${NANDI_URL.APP.MONITOR}/${state.alert.field_id}`}>
                <AlertPageButton
                  className={classNames(styles.button, "mt-4")}
                  title={t("alertsPage.goToFieldButton")}
                  iconName={"field"}
                />
              </Link>
            </div>
          </>
        ) : null}
      </Loader>
    </div>
  );
};

export default withTranslation()(
  withRouter(withModalHOC(withLoaderHOC(AlertDetailPage)))
);
