import React, { FunctionComponent, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
// Context
import { RootState } from "../../../store/store";
// Components
import { Loader } from "../../../components/Loader/Loader";
import { PageTitle } from "../../../components/PageTitle/PageTitle.component";
import { Table, TableRow } from "../../components/Table/Table";
import CheckboxList from "./checkboxlist";
// Services
import { useServices } from "../../../services/service-providers/service-provider";
// Utils
import withLoaderHOC, {
  WithLoaderProps,
} from "../../../utils/hocs/WithLoaderHOC";
import {
  downloadTableAsCSV,
  getInitialFieldChecks,
  getInitialFields,
} from "./utils";
import { InsightRow } from "./types";
import { Field } from "../../../types/fields";

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = {} & PropsFromRedux;

const InsightsContainer: FunctionComponent<WithLoaderProps & Props> = ({
  isLoading,
  selectedAccount,
  setLoading,
}) => {
  const { insightsService, fieldsService } = useServices();
  const [accountFields, setAccountFields] = useState<Array<Field> | null>(null);
  const [insightsRow, setInsightsRow] = useState<Array<InsightRow> | null>(
    null
  );
  const [fields, setFields] = useState(getInitialFields());
  const [checkedFields, setCheckedFields] = useState<string[]>(
    getInitialFieldChecks()
  );
  const [filters, setFilters] = useState<{
    member_type: "ALL" | "COW" | "BULL";
    member_field_id: string;
  }>({ member_type: "ALL", member_field_id: "ALL" });

  const getInsights = () => {
    return insightsService.getInsights(
      String(selectedAccount.id),
      checkedFields,
      filters
    );
  };

  const getFields = () => {
    return fieldsService.getFields(String(selectedAccount.id));
  };

  const loadInfo = async () => {
    setLoading(true);
    document.title = `Nandi | Insights`;
    const insightRows = await getInsights();
    const availableFields = await getFields();
    setInsightsRow(insightRows);
    setAccountFields(availableFields);
    setLoading(false);
  };

  useEffect(() => {
    loadInfo();
  }, [checkedFields, filters, selectedAccount.id]);

  const handleFieldChange = (newFields: string[]) => {
    setCheckedFields(newFields);
  };

  const handleMemberTypeFilterChange = (e: any) => {
    setFilters((prevState) => ({ ...prevState, member_type: e.target.value }));
  };

  const handleMemberFieldIdChange = (e: any) => {
    setFilters((prevState) => ({
      ...prevState,
      member_field_id: e.target.value,
    }));
  };

  return (
    <Loader isLoading={isLoading}>
      <main className="p-4">
        <header>
          <PageTitle>Insights {selectedAccount.name}</PageTitle>
          <div className="flex items-center mt-2">
            <div className="bg-gray-100 p-4 rounded">
              <h3 className="mb-4 font-semibold text-gray-900 dark:text-white">
                Select your fields
              </h3>

              <CheckboxList
                checkedFields={checkedFields}
                onCheck={handleFieldChange}
                fields={fields.map((f) => ({ id: f.key, name: f.title }))}
              />
            </div>
            <div className="ml-4 bg-gray-100 p-4 rounded">
              <h3 className="mb-4 font-semibold text-gray-900 dark:text-white">
                Filters
              </h3>
              <label htmlFor="member_type">Cattle Type</label>
              <select
                onChange={handleMemberTypeFilterChange}
                name="member_type"
                id="member_type"
                value={filters.member_type}
              >
                <option value="ALL">All</option>
                <option value="BULL">Bulls</option>
                <option value="COW">Cows</option>
              </select>
              {accountFields && accountFields.length > 0 ? (
                <div className="block mt-2">
                  <label htmlFor="member_field_id">Field</label>
                  <select
                    onChange={handleMemberFieldIdChange}
                    name="member_field_id"
                    id="member_field_id"
                    value={filters.member_field_id}
                  >
                    <option value={"ALL"}>All</option>
                    {accountFields.map((a) => (
                      <option key={a.id} value={a.id}>
                        {a.name}
                      </option>
                    ))}
                  </select>
                </div>
              ) : null}
            </div>
            <div className="ml-4 bg-gray-100 p-4 rounded">
              <h3 className="mb-4 font-semibold text-gray-900 dark:text-white">
                Export
              </h3>
              <a href="#" onClick={() => downloadTableAsCSV("insights")}>
                Download as CSV
              </a>
            </div>
          </div>
        </header>
        <section className="mt-4">
          {insightsRow && insightsRow.length > 0 ? (
            <Table
              tableId="insights"
              isPaginationEnabled={false}
              renderTableRow={false}
              items={insightsRow}
              columns={fields.filter((f) => checkedFields.includes(f.key))}
            >
              {insightsRow.map((row) => (
                <TableRow key={row.id}>
                  {fields.map((field) => {
                    return checkedFields.includes(field.key) ? (
                      <td key={field.key}>{field.render(row)}</td>
                    ) : null;
                  })}
                </TableRow>
              ))}
            </Table>
          ) : null}
        </section>
      </main>
    </Loader>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    selectedAccount: state.accounts.selectedAccount,
    user: state.users.user,
  };
};

const connector = connect(mapStateToProps);

const InsightsPage = connector(withLoaderHOC(InsightsContainer));

export { InsightsPage };
