import classNames from "classnames";
import React, { FunctionComponent } from "react";

type LinkProps = {
  className?: string;
  href: string;
  target: string;
};

const Link: FunctionComponent<LinkProps> = ({
  children,
  className,
  href,
  target,
}) => (
  <a
    href={href}
    target={target}
    className={classNames(
      "font-medium text-indigo-600 hover:text-indigo-500 text-center block",
      className
    )}
  >
    {children}
  </a>
);

export { Link };
