import React, { FunctionComponent } from "react";

import { Icon } from "../Icon";
import { Alert } from "../../types/alerts";
import { useTranslation } from "react-i18next";

interface FieldDetailsAlertsBoxProps {
  className?: string;
  alert?: Alert | null;
  onClick: () => void;
}

export const FieldDetailsAlertsBox: FunctionComponent<
  FieldDetailsAlertsBoxProps
> = ({ alert, className, onClick }) => {
  const { t } = useTranslation();

  const showLastAlert = (
    <div
      className={[className, "p-2 flex items-center bg-[#f8f8f8]"].join(" ")}
    >
      <div className="font-semibold">
        {t(`alertTypes.${alert?.alert_type}`)}
      </div>
      <div className="ml-2">
        {`${alert?.date.toLocaleTimeString(navigator.language, {
          hour: "2-digit",
          minute: "2-digit",
        })} - ${alert?.date.toLocaleDateString()}`}
      </div>
    </div>
  );

  const noAlerts = (
    <div className="mt-2 bg-[#f8f8f8] p-2 rounded">
      {t("alertsBox.noAlertsTitle")}
    </div>
  );

  return (
    <>
      {alert ? showLastAlert : noAlerts}
      {alert ? (
        <div
          className="flex justify-between mt-2 items-center"
          onClick={onClick}
        >
          <div>{t("alertsBox.showAllAlerts")}</div>
          <Icon
            className="fill-current text-gray-500"
            iconName="arrow_forward"
          />
        </div>
      ) : null}
    </>
  );
};
