import React, { FunctionComponent, useEffect, useState } from "react";
import nandi_logo from "../../assets/nandi_logo.svg";
import PaddockSummaryCard from "../../components/PaddockSummaryCard";
import { Loader } from "../../components/Loader/Loader";
import SelectItemModal from "../../components/SelectItemModal";
import { useServices } from "../../services/service-providers/service-provider";
import { Icon } from "../../components/Icon";
import withLoaderHOC, { WithLoaderProps } from "../../utils/hocs/WithLoaderHOC";
import {
  FieldSummaryWithExtraDetails,
  sortFieldsAlphabeticallyDown,
  sortFieldsAlphabeticallyUp,
  sortFieldsByAlert,
} from "../../types/fields";

import styles from "./MonitorPage.module.scss";
import { WithTranslation, withTranslation } from "react-i18next";
import { isMsgError } from "../../lib/error";
import Tab, { Tabs } from "../../components/Tab";
import { Link } from "react-router-dom";
import NANDI_URL from "../../lib/url";
import { useAppSelector } from "../../store/hooks/redux-hooks";

type MonitorPageState = {
  fields: Array<FieldSummaryWithExtraDetails>;
  searchFieldValue: string;
  selectedFilter: string;
  displaySortMethodModal: boolean;
  selectedSortMethod: number;
  sortMethods: Array<Item>;
};

type Item = {
  id: number;
  name: string;
  action: (
    members: Array<FieldSummaryWithExtraDetails>
  ) => Array<FieldSummaryWithExtraDetails>;
};

type Props = WithTranslation & WithLoaderProps;

const initialState: MonitorPageState = {
  fields: [],
  searchFieldValue: "",
  selectedFilter: "All",
  displaySortMethodModal: false,
  selectedSortMethod: 1,
  sortMethods: [
    {
      id: 1,
      name: "A-Z",
      action: (members: Array<FieldSummaryWithExtraDetails>) => {
        return members.sort(sortFieldsAlphabeticallyUp);
      },
    },
    {
      id: 2,
      name: "Z-A",
      action: (members: Array<FieldSummaryWithExtraDetails>) => {
        return members.sort(sortFieldsAlphabeticallyDown);
      },
    },
    {
      id: 3,
      name: "Alerts",
      action: (members: Array<FieldSummaryWithExtraDetails>) => {
        return members.sort(sortFieldsByAlert);
      },
    },
  ],
};

const MonitorPage: FunctionComponent<Props> = ({
  isLoading,
  t,
  setLoading,
  setError,
}) => {
  const [state, setState] = useState(initialState);
  const { selectedAccount } = useAppSelector((s) => s.accounts);
  const { fieldsService } = useServices();

  const getFields = async (selectedFilter: string) => {
    try {
      const fields = await fieldsService.getFields(String(selectedAccount.id));
      setState({
        ...state,
        fields: fields,
        selectedFilter: selectedFilter,
      });
      setLoading(false);
    } catch (error) {
      if (isMsgError(error)) setError(error);
      console.log(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    getFields(state.selectedFilter);
  }, []);

  const onChangeSelectedFilter = (filterName: string): void => {
    setState({ ...state, selectedFilter: filterName });
    setLoading(true);
    getFields(filterName).finally(() => {
      setLoading(false);
    });
  };

  const changeSelectedSortMethod = (sortMethodId: number): void => {
    setState({
      ...state,
      selectedSortMethod: sortMethodId,
      displaySortMethodModal: !state.displaySortMethodModal,
    });
  };

  const changeModalState = (): void => {
    setState({
      ...state,
      displaySortMethodModal: !state.displaySortMethodModal,
    });
  };

  const getSortedAlerts = (): Array<FieldSummaryWithExtraDetails> => {
    const sortedAlerts = state.sortMethods
      .find((sortMethod) => sortMethod.id === state.selectedSortMethod)
      ?.action(state.fields!!);

    return sortedAlerts!!;
  };

  const filters = ["All", "Only Alerts"];

  return (
    <>
      <SelectItemModal
        displayModal={state.displaySortMethodModal}
        items={state.sortMethods}
        selectedItem={state.selectedSortMethod}
        onAction={changeSelectedSortMethod}
        onClose={changeModalState}
      />
      <div className={styles.header}>
        <div className={styles.logoContainer}>
          <img src={nandi_logo} className={styles.imgHeader} alt="logo_icon" />
        </div>
        <div className={styles.areaName}>{t(`monitorPage.title`)}</div>
      </div>
      <div className={styles.body}>
        <Tabs>
          {filters.map((filter, index) => (
            <Tab
              key={index}
              selected={filter === state.selectedFilter}
              label={t(`monitorPage.filter.${filter}`)}
              onClick={() => onChangeSelectedFilter(filter)}
            />
          ))}
        </Tabs>
        <div className={styles.sortContainer}>
          <div className={styles.sortTitle} onClick={changeModalState}>
            {t("general.sort")}
          </div>
          <div className={styles.arrowIconContainer}>
            <Icon iconName={"arrow_down"} />
          </div>
        </div>
        <div className={"grid gap-4 pl-2 pr-2"}>
          <Loader isLoading={isLoading}>
            {getSortedAlerts()
              .filter((field) =>
                field.name
                  .toUpperCase()
                  .includes(state.searchFieldValue.toUpperCase())
              )
              .map((field) => (
                <Link
                  key={field.id}
                  to={`${NANDI_URL.APP.MONITOR}/${field.id}`}
                >
                  <PaddockSummaryCard field={field} />
                </Link>
              ))}
          </Loader>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(withLoaderHOC(MonitorPage));
