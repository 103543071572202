import React, { useState } from "react";
import { ErrorModal } from "../../components/ErrorModal/ErrorModal";

export type WithLoaderProps = {
  isLoading: boolean;
  setError: (errorMessage: string) => void;
  setLoading: (isComponentLoading: boolean) => void;
};

const withLoaderHOC = (WrappedComponent: any) => {
  const HOC = (props: any) => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const setLoadingState = (isComponentLoading: boolean) => {
      setIsLoading(isComponentLoading);
    };

    const setErrorPopUp = (errorMessage: string) => {
      setError(errorMessage);
      setIsLoading(false);
      setTimeout(() => {
        setError(null);
      }, 3000);
    };

    return (
      <>
        {error !== null && <ErrorModal errorMessage={error} />}
        <WrappedComponent
          {...props}
          setError={setErrorPopUp}
          isLoading={isLoading}
          setLoading={setLoadingState}
        />
      </>
    );
  };

  HOC.displayName = `withLoader(${WrappedComponent.name})`;

  return HOC;
};
export default withLoaderHOC;
