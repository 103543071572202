import React, {
  Reducer,
  createContext,
  FunctionComponent,
  useReducer,
} from "react";

type State = {
  member: Member | null;
  isLoading: boolean;
};
type Action =
  | { type: "request" }
  | { type: "success"; member: Member }
  | { type: "failure"; error: string };
type Dispatch = (action: Action) => void;

const MemberContext = createContext<
  | {
      state: State;
      dispatch: Dispatch;
    }
  | undefined
>(undefined);

const memberReducer: Reducer<State, Action> = (
  state: State,
  action: Action
): State => {
  switch (action.type) {
    case "request": {
      return { member: null, isLoading: true };
    }
    case "success": {
      return { member: action.member, isLoading: false };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const memberInitialState = {
  member: null,
  isLoading: false,
};

export const MemberProvider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(memberReducer, memberInitialState);
  const value = { state, dispatch };

  return (
    <MemberContext.Provider value={value}>{children}</MemberContext.Provider>
  );
};

export const useMember = () => {
  const context = React.useContext(MemberContext);
  if (context === undefined) {
    throw new Error("useMember must be used within a MemberProvider");
  }
  return context;
};
