import { Account } from "../admin-site/types/Account";
import { FormField } from "./form-types";

export type LogInApiResponse = {
  access_token: string;
  token_type: string;
};

export type PasswordRecoveryApiResponse = {
  message: string;
};

export enum UserFilter {
  CAN_RECEIVE_ALERTS = "can-receive-alerts",
  ALL = "all",
}

export type User = {
  id: number;
  username: string;
  email: string;
  role: UserRole;
  full_name: string;
  disabled: boolean;
  account_id: number;
  password?: string;
  tel_number?: string;
  tel_country_code?: string;
  account: Account;
  CustomerRep: boolean;
};

export enum UserRole {
  ANON = "Anon",
  USER = "User",
  ADMIN = "Admin",
  SUPERUSER = "Superuser",
}

export type CreateUserFormFields = {
  username: string;
  email: string;
  full_name: string;
  role: string;
  password: string;
  account_id: number;
};

export type UpdateUserFormFields = {
  id: number;
  username: string;
  email: string;
  full_name: string;
  role: string;
  password: string;
  account_id: number;
  disabled: boolean;
};

export type LoginForm = {
  username_field: FormField;
  password_field: FormField;
};

export type PasswordRecoveryForm = {
  username_field: FormField;
};

export type ResetPasswordForm = {
  new_password_field: FormField;
  confirm_new_password_field: FormField;
};
