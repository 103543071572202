import { connect, ConnectedProps } from "react-redux";
import React, {
  Dispatch,
  FunctionComponent,
  useContext,
  useEffect,
} from "react";
import { PayloadAction } from "@reduxjs/toolkit";

import withLoaderHOC, { WithLoaderProps } from "./utils/hocs/WithLoaderHOC";
import App from "./App";

// Types
import { UserRole } from "./types/auth";
import { ServicesContext } from "./services/service-providers/service-provider";
import { accountsActions } from "./store/slices/accounts-slice";
import { genericError, isMsgError } from "./lib/error";
import { RootState } from "./store/store";

type Account = {
  id: number;
  name: string;
  disabled: boolean;
  demo: boolean;
};

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = WithLoaderProps & PropsFromRedux;

const AppContainer: FunctionComponent<Props> = ({
  user,
  setError,
  saveAccounts,
  setSelectedAccount,
  setLoading,
}) => {
  const { accountsService } = useContext(ServicesContext);

  const getAccounts = async () => {
    try {
      if (user?.role === UserRole.SUPERUSER) {
        let data: Account[] | null = await accountsService.getAccounts();
        console.log("accounts for ", data, user);
        if (data) {
          saveAccounts(data);
          setSelectedAccount(user.account);
        }
      } else {
        const data = await accountsService.getAccountById(user?.account_id!!);
        saveAccounts([data]);
      }
    } catch (error) {
      if (isMsgError(error)) {
        setError(error);
      } else {
        setError(genericError());
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAccounts();
  }, []);

  return <App />;
};

const mapStateToProps = (state: RootState) => {
  return {
    user: state.users.user,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<PayloadAction<Array<Account> | Account>>
) => {
  return {
    saveAccounts: (accounts: Array<Account>) =>
      dispatch(accountsActions.saveAccounts(accounts)),
    setSelectedAccount: (account: Account) =>
      dispatch(accountsActions.setSelectedAccount(account)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(withLoaderHOC(AppContainer));
