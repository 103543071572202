import React, { FunctionComponent, useEffect, useState } from "react";

import { RouteComponentProps, withRouter } from "react-router";

import styles from "./FieldAlertDetailPage.module.scss";
import containerStyle from "../FieldPage.module.scss";
import withModalHOC, { WithModalProps } from "../../../utils/hocs/withModalHOC";
import withLoaderHOC, {
  WithLoaderProps,
} from "../../../utils/hocs/WithLoaderHOC";
import { Alert } from "../../../types/alerts";
import { Icon } from "../../../components/Icon";
import { AlertCard } from "../../../components/AlertCard/AlertCard";
import { Loader } from "../../../components/Loader/Loader";
import { useServices } from "../../../services/service-providers/service-provider";
import { WithTranslation, withTranslation } from "react-i18next";
import { isMsgError } from "../../../lib/error";

type FieldAlertDetailPageState = {
  alert: Alert | null;
};

interface MatchParams {
  alert_id: string;
}

type Props = RouteComponentProps<MatchParams> &
  WithModalProps &
  WithTranslation &
  WithLoaderProps;

const FieldAlertDetailPage: FunctionComponent<Props> = (props) => {
  const [state, setState] = useState<FieldAlertDetailPageState>({
    alert: null,
  });
  const { alertsService } = useServices();

  const getAlert = async () => {
    props.setLoading(true);

    try {
      const alertId = props.match.params.alert_id;
      const alert = await alertsService.updateAlert(alertId, true);

      setState({
        ...state,
        alert: alert,
      });
      props.setLoading(false);
    } catch (error) {
      if (isMsgError(error)) props.setError(error);
    }
  };

  useEffect(() => {
    props.setConfirmModalAction(onDeleteAlert);
    getAlert();
  }, []);

  const onDeleteAlert = async () => {
    await alertsService.deleteAlert(state.alert?.id!!);
    props.setModalDisplayState(false);
    props.history.goBack();
  };

  return (
    <div className={containerStyle.fieldAlertDetailPage}>
      <div className={styles.goBackHeader}>
        <div className={styles.goBackIconContainer}></div>
        <div
          onClick={() => props.setModalDisplayState(true)}
          className={styles.deleteContainer}
        >
          <div className={styles.deleteIconContainer}>
            <Icon iconName={"delete"} />
          </div>
          <div className={styles.deleteTitle}>{props.t("general.delete")}</div>
        </div>
      </div>
      <Loader isLoading={props.isLoading}>
        <AlertCard
          className={styles.alertCardBackground}
          alert={state.alert!!}
        />
      </Loader>
    </div>
  );
};

export default withTranslation()(
  withRouter(withModalHOC(withLoaderHOC(FieldAlertDetailPage)))
);
