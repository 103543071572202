import moment from "moment";
import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
} from "chart.js";

// Components
import { DatePicker, YearPicker } from "../../../../components/DatePicker";
import { LoaderWithRender } from "../../../../components/Loader/Loader";
// Services
import { useServices } from "../../../../services/service-providers/service-provider";
// Utils
import withLoaderHOC, {
  WithLoaderProps,
} from "../../../../utils/hocs/WithLoaderHOC";
import { Color } from "../../../../lib/utils/color";

type NDVIChartProps = {
  paddockName: string;
  polygonId: string;
};

const getToday = (): number => Number(moment.utc().format("X"));

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const NDVIChartBase: FunctionComponent<NDVIChartProps & WithLoaderProps> = ({
  paddockName,
  polygonId,
  isLoading,
  setLoading,
}) => {
  const { agroService } = useServices();
  const [startDate, setStartDate] = useState(1577847600);
  const [endDate, setEndDate] = useState(getToday());
  const [agroStats, setAgroStats] = useState<AgroStatsInfo[] | null>(null);
  const [labels, setLabels] = useState<any>([]);
  const [datasets, setDatasets] = useState<any>([]);

  const getDataSets = (years: any) => {
    return Object.keys(years).map((year) => ({
      label: `${year}`,
      backgroundColor: Color.generateRandom(),
      data: Object.keys(years[year])
        .sort((a, b) => (Number(a) > Number(b) ? 1 : -1))
        .map((month) => ({
          x: years[year][month].label,
          y: years[year][month].data.mean,
        })),
    }));
  };

  const getLabels = () => {
    return [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ];
  };

  const getAgroStatsByYear = (agroStats: AgroStatsInfo[]) => {
    const years: any = {};

    agroStats.forEach((a) => {
      const year = moment.unix(a.dt).format("YYYY");
      const month = moment.unix(a.dt).format("MM");
      if (!(year in years)) {
        years[year] = {};
        years[year] = {
          "01": { label: "01", data: { mean: -0.1 } },
          "02": { label: "02", data: { mean: -0.1 } },
          "03": { label: "03", data: { mean: -0.1 } },
          "04": { label: "04", data: { mean: -0.1 } },
          "05": { label: "05", data: { mean: -0.1 } },
          "06": { label: "06", data: { mean: -0.1 } },
          "07": { label: "07", data: { mean: -0.1 } },
          "08": { label: "08", data: { mean: -0.1 } },
          "09": { label: "09", data: { mean: -0.1 } },
          "10": { label: "10", data: { mean: -0.1 } },
          "11": { label: "11", data: { mean: -0.1 } },
          "12": { label: "12", data: { mean: -0.1 } },
        };
      }
      years[year][month].data = a.data;
    });

    return years;
  };

  const loadInfo = async () => {
    try {
      setLoading(true);
      const agroStats = await agroService.getByPolygonId(
        polygonId,
        startDate,
        endDate
      );
      const agroStatsByYear = getAgroStatsByYear(agroStats);

      const newDataSets = getDataSets(agroStatsByYear);
      const newLabels = getLabels();
      console.log("new chart ", newDataSets, newLabels, agroStatsByYear);

      setAgroStats(agroStats);
      setDatasets(newDataSets);
      setLabels(newLabels);
    } catch (e) {
      console.log(e);
      setAgroStats(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadInfo();
    return () => {};
  }, [polygonId, startDate, endDate]);

  const handleStartYearChange: React.ChangeEventHandler<HTMLSelectElement> = (
    e
  ) => {
    setStartDate(Number(moment(e.target.value).startOf("year").unix()));
  };

  const handleEndYearChange: React.ChangeEventHandler<HTMLSelectElement> = (
    e
  ) => {
    setEndDate(Number(moment(e.target.value).endOf("year").unix()));
  };

  return (
    <>
      <header className="flex p-4">
        <div>
          <p>Start Year</p>
          <YearPicker
            value={moment.unix(startDate).format("YYYY")}
            onChange={handleStartYearChange}
          />
        </div>
        <div className="ml-2">
          <p>End Year</p>
          <YearPicker
            value={moment.unix(endDate).format("YYYY")}
            onChange={handleEndYearChange}
          />
        </div>
      </header>
      <LoaderWithRender isLoading={isLoading}>
        {!agroStats ? (
          <p>
            No NDVI information for this period or try recharging the page for
            long periods of time.
          </p>
        ) : null}
      </LoaderWithRender>
      <Line
        className="mt-4 max-h-[350px]"
        options={{
          responsive: true,
          maintainAspectRatio: false,
          elements: {
            point: {
              radius: 7,
            },
          },
        }}
        data={{
          labels,
          datasets,
        }}
      />
    </>
  );
};

export const NDVIYearlyChart = withLoaderHOC(NDVIChartBase);
