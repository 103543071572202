import React, { FunctionComponent, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { DashboardPage } from ".";
import { Loader } from "../../../components/Loader/Loader";
import { useServices } from "../../../services/service-providers/service-provider";
import { isMsgError } from "../../../lib/error";
// Context
import { RootState } from "../../../store/store";
import withLoaderHOC, {
  WithLoaderProps,
} from "../../../utils/hocs/WithLoaderHOC";

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = {} & PropsFromRedux;

const PageContainer: FunctionComponent<WithLoaderProps & Props> = ({
  isLoading,
  setLoading,
  setError,
}) => {
  const [memberEventsCount, setMemberEventsCount] = useState<
    Array<{ date: string; member_events: number; member_messages: number }>
  >([]);
  const [memberEventsLastDay, setMemberEventsLastDay] = useState<
    Array<MemberEventsLastDay>
  >([]);

  const { membersService } = useServices();
  const getMemberEventsByLastWeek = async () => {
    try {
      const data = await membersService.getMemberEventsByDates();
      setMemberEventsCount(data);
      setLoading(false);
    } catch (error) {
      if (isMsgError(error)) setError(error);
      setLoading(false);
    }
  };

  const getEventsLastDay = async () => {
    try {
      const data = await membersService.getMemberEventsLastDay();
      setMemberEventsLastDay(data);
      setLoading(false);
    } catch (error) {
      if (isMsgError(error)) setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getMemberEventsByLastWeek();
    getEventsLastDay();
  }, []);

  return (
    <Loader isLoading={isLoading}>
      <DashboardPage
        memberEventsCount={memberEventsCount}
        memberEventsLastDay={memberEventsLastDay}
      />
    </Loader>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    selectedAccount: state.accounts.selectedAccount,
    user: state.users.user,
  };
};

const connector = connect(mapStateToProps);

const DashboardPageContainer = connector(withLoaderHOC(PageContainer));

export { DashboardPageContainer };
