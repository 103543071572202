import React, { FunctionComponent, useEffect, useState } from "react";

import { Route, RouteComponentProps, Switch, withRouter } from "react-router";
import { GoBack } from "../../components/GoBack/GoBack";
import MembersByTypePage from "../AllMembersPage/MembersByTypePage/MembersByTypePage";
import FieldAlertsPage from "./FieldAlertsPage/FieldAlertsPage";
import MemberDetailPage from "../AllMembersPage/MemberDetailPage/MemberDetailPage";
import FieldDetailsPage from "./FieldDetailsPage/FieldDetailsPage";

import FieldAlertDetailPage from "./FieldAlertDetailPage/FieldAlertDetailPage";
import { useServices } from "../../services/service-providers/service-provider";
import Header from "../../components/Header";
import NANDI_URL from "../../lib/url";
import { AppMonitorByIdMapPage } from "../App/MonitorById/Map/AppMonitorByIdMap.page";

type FieldPageState = {
  areaName: string;
};

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams>;

const initialState: FieldPageState = {
  areaName: "",
};

const FieldPage: FunctionComponent<Props> = (props) => {
  const [state, setState] = useState(initialState);
  const { fieldsService } = useServices();

  const getField = async () => {
    const fieldId = props.match.params.id;

    try {
      const field = await fieldsService.getFieldById(fieldId);

      setState({
        ...state,
        areaName: field.name,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getField();
  }, []);

  const { path } = props.match;

  return (
    <>
      <Header>
        <GoBack title={state.areaName} />
      </Header>
      <Switch>
        <Route
          path={NANDI_URL.APP.MONITOR_BY_ID}
          exact
          component={FieldDetailsPage}
        />
        <Route
          path={NANDI_URL.APP.MONITOR_BY_ID_MAP}
          exact
          render={({ match }) => (
            <AppMonitorByIdMapPage paddockId={match.params.id as string} />
          )}
        />
        <Route path={`${path}/alerts`} exact component={FieldAlertsPage} />
        <Route
          path={`${path}/alerts/:alert_id`}
          exact
          component={FieldAlertDetailPage}
        />
        <Route
          path={`${NANDI_URL.APP.MONITOR}/:id/:sensor_type`}
          exact
          render={({ match }) => (
            <MembersByTypePage
              fieldId={match.params.id}
              memberEntityType={match.params.sensor_type}
            />
          )}
        />
        <Route path={`${path}/:member_id`} exact component={MemberDetailPage} />
        <Route
          path={`${path}/:sensor_type/:member_id/alerts`}
          exact
          component={FieldAlertsPage}
        />
        <Route
          path={`${path}/:sensor_type/:member_id/alerts/:alert_id`}
          exact
          component={FieldAlertDetailPage}
        />
      </Switch>
    </>
  );
};

export default withRouter(FieldPage);
