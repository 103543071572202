import request from "./request";
import { IRodeosService } from "./services-types";

export class ProductionRodeosService implements IRodeosService {
  getRodeo = async (
    accountid?: string | number,
    rodeoid?: string | number
  ): Promise<Rodeo[]> => {
    return await request({
      method: "GET",
      url: "/rodeos",
      baseURL: process.env.REACT_APP_NEW_API_URL,
      params: {
        accountid,
        rodeoid,
      },
    });
  };
}
