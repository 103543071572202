import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  title?: string;
  detail: string;
  id: number;
  open: boolean;
  accountable: string;
  closeModal: () => void;
  handleRemoveEvent: (eventId: number) => void;
};
export const ViewDetailModal: FunctionComponent<Props> = ({
  title,
  detail,
  id,
  open,
  accountable,
  closeModal,
  handleRemoveEvent,
}) => {
  const { t } = useTranslation();

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      title: {
        color: "#4C4E64",
        fontSize: "20px",
      },
      form: {
        width: 400,
        backgroundColor: "white",
        gap: "24px",
        display: "flex",
        flexDirection: "column",
        padding: "32px",
      },
      label: {
        textAlign: "left",
        width: "100%",
        fontSize: "13px",
      },
      input: {
        width: "100%",
      },
      textarea: {
        resize: "none",
        border: "1px solid grey",
      },
    })
  );

  const MUIStyles = useStyles();
  return (
    <Dialog
      open={open}
      onClose={closeModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className="rounded-lg border bg-card text-card-foreground shadow-sm flex flex-col p-4">
        {title ? (
          <h1 className="whitespace-nowrap text-2xl font-semibold leading-none tracking-tight">
            {title}
          </h1>
        ) : null}

        <DialogContent className={MUIStyles.form}>
          <p className="text-sm text-muted-foreground">{detail}</p>
          <div className="flex items-center gap-x-2">
            <p className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
              {t(
                "admin.mainPage.healthCalendar.event.viewDetailModal.accountable"
              )}
            </p>
            <p className="text-sm text-muted-foreground">{accountable}</p>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2"
            onClick={() => {
              handleRemoveEvent(id);
            }}
          >
            {t("admin.mainPage.healthCalendar.event.viewDetailModal.delete")}
          </button>
          <button
            className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2"
            onClick={closeModal}
          >
            {t("admin.mainPage.healthCalendar.event.viewDetailModal.accept")}
          </button>
        </DialogActions>
      </div>
    </Dialog>
  );
};
