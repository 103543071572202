import classNames from "classnames";
import React, { FunctionComponent, ReactNode, useState } from "react";
import { Icon } from "../../../components/Icon";
import { Select } from "../../../components/Select/Select";

import styles from "./Table.module.scss";

type TableProps = {
  tableId?: string;
  className?: string;
  items: Array<any>;
  columns: Array<TableColumn>;
  isPaginationEnabled?: boolean;
  renderTableRow?: boolean;
  onRowClicked?: (index: number) => void;
  onChangeFilter?: () => void;
};

export type TableColumn = {
  title: ReactNode;
  key: string;
  render: (data: any) => ReactNode;
};

export const Table: FunctionComponent<TableProps> = ({
  tableId,
  children,
  className,
  onChangeFilter = () => {},
  onRowClicked,
  isPaginationEnabled = true,
  renderTableRow = true,
  items,
  columns,
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);

  // PAGES HANDLERS [HOLD]

  /*   const onChangeRows = (event: React.ChangeEvent<HTMLSelectElement>) => { 
    const value = Number.parseInt(event.target.value);
    setRowsPerPage(value);
    setPageNumber(1);
    onChangeFilter(1, value);
  };

  const onPageBack = () => {
    if (pageNumber !== 1) {
      setPageNumber(pageNumber - 1);
      onChangeFilter(pageNumber - 1, rowsPerPage);
    }
  };

  const onPageForward = () => {
    if (items.length === rowsPerPage) {
      setPageNumber(pageNumber + 1);
      onChangeFilter(pageNumber + 1, rowsPerPage);
    }
  }; */

  return (
    <div className={classNames(styles.responsiveTable, className)}>
      <table id={tableId} className="table table-bordered table-hover">
        <thead>
          <tr>
            {columns.map((tableColumn) => (
              <th key={tableColumn.key}>{tableColumn.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {renderTableRow
            ? items.map((item, index) => (
                <tr
                  className={classNames({
                    "cursor-pointer hover:bg-white":
                      typeof onRowClicked === "function",
                  })}
                  key={index}
                  onClick={onRowClicked ? () => onRowClicked(index) : () => {}}
                >
                  {columns.map((col, key) => (
                    <td key={key}>{col.render(item)}</td>
                  ))}
                </tr>
              ))
            : children}
        </tbody>
      </table>
      {/*       {isPaginationEnabled && items.length > 10 ? (
        <div className={styles.paginationContainer}>
          <div className={styles.rowsPerPageContainer}>
            <div className={styles.selectTitle}>Rows per page</div>
            <div className={styles.selectContainer}>
              <Select
                selectedValue={rowsPerPage}
                onChange={onChangeRows}
                options={[
                  { value: 10, displayValue: "10" },
                  { value: 25, displayValue: "25" },
                  { value: 50, displayValue: "50" },
                ]}
              />
            </div>
          </div>
          <div className={styles.pagesContainer}>
            <div className={styles.title}>Page {pageNumber}</div>
            <div
              className={[
                styles.iconContainer,
                pageNumber === 1 ? styles.disabledIcon : "",
              ].join(" ")}
              onClick={() => onPageBack()}
            >
              <Icon iconName={"arrow_back"} />
            </div>
            <div
              className={[
                styles.iconContainer,
                items.length <= rowsPerPage ? styles.disabledIcon : "",
              ].join(" ")}
              onClick={() => onPageForward()}
            >
              <Icon iconName={"arrow_forward"} />
            </div>
          </div>
        </div>
      ) : null} */}
    </div>
  );
};

const TableRow: FunctionComponent<{ onClick?: any }> = ({
  children,
  onClick,
}) => (
  <tr
    onClick={onClick}
    className={classNames("cursor-pointer hover:bg-white relative")}
  >
    {children}
  </tr>
);

export { TableRow };
