import React, { FunctionComponent, useState } from "react";
import { Button } from "../../../../components/Button/Button";

type Props = {
  member: Member;
};

export const MemberDetailPhoto: FunctionComponent<Props> = ({ member }) => {
  const [viewPhotos, setViewPhotos] = useState(false);

  return (
    <section className="mt-4">
      <Button
        disabled={!member.profile_picture && !member.profile_picture_front}
        onClick={() => setViewPhotos((p) => !p)}
      >
        {viewPhotos ? "Hide Images" : "View Images"}
      </Button>
      {viewPhotos ? (
        <div className="mt-4 overflow-auto whitespace-nowrap gap-2 flex rounded-lg rounded-tr-none rounded-br-none">
          {member.profile_picture ? <img src={member.profile_picture} /> : null}
          {member.profile_picture_front ? (
            <img src={member.profile_picture_front} />
          ) : null}
        </div>
      ) : null}
    </section>
  );
};
