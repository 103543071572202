import moment from "moment";
import React from "react";
import { InsightRow } from "./types";

export const getInitialFields = () => [
  {
    title: "Tag ID",
    key: "tag_id",
    initialSelected: true,
    render: (d: InsightRow) => <>{d.tag_id}</>,
  },
  {
    title: "Current Weight",
    key: "weight",
    initialSelected: false,
    render: (d: InsightRow) => <>{d.weight}</>,
  },
  {
    title: "Paddock",
    key: "member_field_name",
    initialSelected: false,
    render: (d: InsightRow) => <>{d.member_field_name}</>,
  },
  {
    title: "Cattle Type",
    key: "member_type",
    initialSelected: true,
    render: (d: InsightRow) => <>{d.member_type}</>,
  },
  {
    title: "Breed",
    key: "breed",
    initialSelected: false,
    render: (d: InsightRow) => <>{d.breed}</>,
  },
  {
    title: "Birth Date",
    key: "birth_date",
    initialSelected: false,
    render: (d: InsightRow) => <>{moment(d.birth_date).format("L")}</>,
  },
];

export const getInitialFieldChecks = () =>
  getInitialFields()
    .filter((f) => f.initialSelected === true)
    .map((f) => f.key);

export const downloadTableAsCSV = (table_id: string, separator = ",") => {
  // Select rows from table_id
  var rows = document.querySelectorAll("table#" + table_id + " tr");
  // Construct csv
  var csv = [];
  for (var i = 0; i < rows.length; i++) {
    var row = [],
      cols = rows[i].querySelectorAll("td, th");
    for (var j = 0; j < cols.length; j++) {
      // Clean innertext to remove multiple spaces and jumpline (break csv)
      var data = (cols[j] as any).innerText
        .replace(/(\r\n|\n|\r)/gm, "")
        .replace(/(\s\s)/gm, " ");
      // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
      data = data.replace(/"/g, '""');
      // Push escaped string
      row.push('"' + data + '"');
    }
    csv.push(row.join(separator));
  }
  var csv_string = csv.join("\n");
  // Download it
  var filename =
    "export_" + table_id + "_" + new Date().toLocaleDateString() + ".csv";
  var link = document.createElement("a");
  link.style.display = "none";
  link.setAttribute("target", "_blank");
  link.setAttribute(
    "href",
    "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string)
  );
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
