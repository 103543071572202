import classNames from "classnames";
import React, { FunctionComponent, ReactNode } from "react";
import { NotificationStatus } from "../../types/notifications";

type Props = {
  children: ReactNode;
  status?: NotificationStatus;
  className?: string;
};

const Notification: FunctionComponent<Props> = ({
  children,
  className,
  status = NotificationStatus.ERROR,
}) => (
  <p
    className={classNames(className, "p-4 rounded", {
      "bg-red-50 border border-red-300": status === NotificationStatus.ERROR,
      "bg-green-50 border border-green-300": status === NotificationStatus.OK,
      "bg-blue-50 border border-blue-300":
        status === NotificationStatus.INFORMATION,
    })}
  >
    {children}
  </p>
);

export { Notification };
