import React, { FunctionComponent } from "react";

import { Bar, Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

type Props = {
  labels: string[];
  datasets: any;
};

const DailyBullJumpsComponent: FunctionComponent<Props> = ({
  labels,
  datasets,
}) => {
  const { t } = useTranslation();

  const options = {
    plugins: {
      title: {
        display: true,
        text: t("admin.cattlePage.cattleID.bullJumps"),
      },
    },
    responsive: true,
  };

  return (
    <Bar
      options={options}
      className="mt-4 max-h-[350px]"
      data={{
        labels: labels,
        datasets,
      }}
    />
  );
};

export default DailyBullJumpsComponent;
    