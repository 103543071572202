//
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { connect, ConnectedProps } from "react-redux";

// Components
import { Loader } from "../../../../components/Loader/Loader";

// Lib
import { isMsgError } from "../../../../lib/error";
import { ServicesContext } from "../../../../services/service-providers/service-provider";
import { RootState } from "../../../../store/store";
import withLoaderHOC, {
  WithLoaderProps,
} from "../../../../utils/hocs/WithLoaderHOC";

// Pages
import MainSensorsPage from "../../../pages/sensors/layout";
import { useTranslation } from "react-i18next";

type MainSensorsPageState = {
  sensors: Array<Sensor>;
  rowsPerPage: number;
  page: number;
  extendBatteryWidth: boolean;
};

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & WithLoaderProps;

const initialState: MainSensorsPageState = {
  sensors: [],
  rowsPerPage: 100,
  page: 1,
  extendBatteryWidth: false,
};

const MainSensorsPageContainer: FunctionComponent<Props> = ({
  selectedAccount,
  setError,
  setLoading,
  isLoading,
}) => {
  const { sensorsService } = useContext(ServicesContext);
  const [state, setState] = useState(initialState);
  const { t } = useTranslation();

  const getSensors = async () => {
    try {
      setLoading(true);
      const data = await sensorsService.getSensorsV2(selectedAccount.id);
      setState({
        ...state,
        sensors: data,
      });
      setLoading(false);
    } catch (error) {
      if (isMsgError(error)) {
        setError(error);
      }
    }
  };

  const handleRemoveSensor = async (id: number, name: string) => {
    if (window.confirm(t("admin.sensors.confirmRemove", { sensor: name }))) {
      setLoading(true);
      await sensorsService
        .removeSensorById(id)
        .then(() => {
          getSensors();
        })
        .catch((err) => {
          throw new Error(err);
        });
    }
  };

  useEffect(() => {
    getSensors();
  }, [selectedAccount.id]);

  return (
    <Loader isLoading={isLoading}>
      <MainSensorsPage
        onChangeFilter={getSensors}
        onDelete={handleRemoveSensor}
        isLoading={isLoading}
        sensors={state.sensors}
      />
    </Loader>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    selectedAccount: {
      ...state.accounts.selectedAccount,
      id: String(state.accounts.selectedAccount.id),
    },
  };
};

const connector = connect(mapStateToProps);

export default connector(withLoaderHOC(MainSensorsPageContainer));
