import { AccountAttributeExtended } from "../../admin-site/types/Account";

const AccountAttribute = {
  sort: (a: AccountAttributeExtended, b: AccountAttributeExtended) => {
    return (
      AccountAttribute.getPositions()[a.attribute_name] -
      AccountAttribute.getPositions()[b.attribute_name]
    );
  },
  getPositions: (): { [key: string]: number } => {
    return {
      applicant_name: 1,
      applicant_role: 2,
      solicitant_email: 3,
      applicant_phone: 4,
      company_address: 5,
      company_phone: 6,
      contract_period: 7,
      start_date: 8,
      cattle_to_monitor: 9,
      bulls_to_monitor: 10,
      cows_to_monitor: 11,
      drinkers_to_monitor: 12,
      ranch_conectivity: 13,
      ranch_dimensions: 14,
      ranch_location: 15,
      ranch_location_link: 16,
    };
  },
};

export { AccountAttribute };
