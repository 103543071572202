import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import NANDI_URL from "../../../../lib/url";
import { Table, TableColumn, TableRow } from "../../../components/Table/Table";
import { useTranslation } from "react-i18next";
import { Icon } from "../../../../components/Icon";
import { User, UserRole } from "../../../../types/auth";

type AccountCattleProps = {
  cattle: Array<MemberWithDetails>;
  onDelete: (id: number, name: string) => void;
  user: User | null;
};

const AccountCattle: FunctionComponent<AccountCattleProps> = ({
  cattle,
  onDelete,
  user,
}) => {
  const { SUPERUSER } = UserRole;
  const { t } = useTranslation();

  const tableConstants = (): Array<TableColumn> => {
    return [
      {
        title: "EID",
        key: "eid",
        render: (member: MemberWithDetails) => {
          return <span>{member.tag_id}</span>;
        },
      },
      {
        title: t("admin.cattlePage.table.cattleType"),
        key: "cattle_type",
        render: (member: MemberWithDetails) => {
          return <>{member.member_type}</>;
        },
      },
      {
        title: t("admin.cattlePage.table.breed"),
        key: "breed",
        render: (member: MemberWithDetails) => {
          return <>{member.breed}</>;
        },
      },
      {
        title: t("admin.cattlePage.table.birth"),
        key: "birth_date",
        render: (member: MemberWithDetails) => {
          return <>{member.birth_date}</>;
        },
      },
      {
        title: t("admin.cattlePage.table.weight"),
        key: "weight",
        render: (member: MemberWithDetails) => {
          return member.weight ? <>{member.weight} kgs</> : null;
        },
      },
      {
        title: t("admin.cattlePage.table.paddock"),
        key: "paddock",
        render: (member: MemberWithDetails) => {
          return <>{member.member_field_name}</>;
        },
      },
    ];
  };

  return (
    <main>
      <Table
        className="mt-4"
        columns={tableConstants()}
        renderTableRow={false}
        items={cattle}
      >
        {cattle.map((cattleMember) => (
          <TableRow key={cattleMember.id}>
            {tableConstants().map((col, key) => (
              <td key={key}>
                {key === 1 && user?.role === SUPERUSER ? (
                  <div
                    className="bg-red-500 p-2 rounded-full cursor-pointer flex absolute bottom-1 left-1"
                    onClick={() =>
                      onDelete(cattleMember.id, cattleMember.tag_id)
                    }
                  >
                    <Icon
                      iconName="delete"
                      className="fill-current self-end block text-black group-hover:text-[#586A84] w-5 h-5"
                    />
                  </div>
                ) : null}
                <Link
                  className="block"
                  to={NANDI_URL.ADMIN.GENERATE_CATTLE_BY_ID(
                    String(cattleMember.id)
                  )}
                >
                  {col.render(cattleMember)}
                </Link>
              </td>
            ))}
          </TableRow>
        ))}
      </Table>
    </main>
  );
};

const CattleSection: FunctionComponent = ({ children }) => (
  <section className="pl-4 pr-4">{children}</section>
);

export default AccountCattle;
export { CattleSection };
