import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { EnhancedMap } from "../../../../components/Map/Map";
import { useServices } from "../../../../services/service-providers/service-provider";
import {
  createGeoJsonData,
  FieldSummaryWithDetails,
  FieldSummaryWithExtraDetails,
  Property,
} from "../../../../types/fields";

const AppMonitorByIdMap: FunctionComponent<{ paddockId: string }> = ({
  paddockId,
}) => {
  const [state, setState] = useState<{
    field?: FieldSummaryWithExtraDetails;
    geoJsonData?: GeoJSON.FeatureCollection<GeoJSON.Geometry, Property>;
    members?: Array<Member>;
  }>({
    geoJsonData: {
      type: "FeatureCollection",
      features: [],
    },
  });

  const [ndviFilter, setNdviFilter] = useState(false);

  const handleNDVIFilter = useCallback(
    (e: any) => {
      e.preventDefault();
      setNdviFilter((prevNdviFilter) => !prevNdviFilter);
      e.stopPropagation();
    },
    [ndviFilter]
  );

  const { fieldsService } = useServices();

  const loadInfo = async () => {
    const fieldInfo = await fieldsService.getFieldById(paddockId);
    const members = await fieldsService.getMembersByFieldIdAndMemberType(
      paddockId,
      "ALL"
    );

    const geoJsonInfo = createGeoJsonData({
      fields: [fieldInfo],
      ref: "admin",
      members,
    });

    console.log(fieldInfo, members, geoJsonInfo);

    setState({
      field: fieldInfo,
      geoJsonData: geoJsonInfo,
      members: members.filter((x) => x.status !== "ARCHIV"),
    });
  };

  useEffect(() => {
    loadInfo();
  }, []);

  if (!state.geoJsonData) {
    return null;
  }

  return (
    <EnhancedMap
      geoJsonData={{
        ...state.geoJsonData,
      }}
      tiles={
        ndviFilter && state.field?.agro
          ? [state.field?.agro[0].tile.ndvi]
          : undefined
      }
      ndviOptions={{
        onClick: handleNDVIFilter,
        value: ndviFilter,
        disabled: !state.field?.agro,
      }}
    />
  );
};

const AppMonitorByIdMapPage = AppMonitorByIdMap;

export { AppMonitorByIdMapPage };
