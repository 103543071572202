import React, { Dispatch, FunctionComponent, useEffect, useState } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import LogIn from "./LogIn";
import withIsLoading from "../../utils/hocs/withIsLoading";
import PasswordRecoveryPage from "./PasswordRecoveryPage/PasswordRecoveryPage";
import ResetPasswordPage from "./ResetPasswordPage/ResetPasswordPage";
import { connect, ConnectedProps } from "react-redux";
import { User } from "../../types/auth";
import AdminAuthPage from "../../admin-site/containers/AdminAuthPage/AdminAuthPage";
import { PayloadAction } from "@reduxjs/toolkit";
import { usersActions } from "../../store/slices/users-slice";
import { useServices } from "../../services/service-providers/service-provider";
import { isApiError } from "../../lib/error";
import NANDI_URL from "../../lib/url";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { accountsActions } from "../../store/slices/accounts-slice";
import AppContainer from "../../AppContainer";

type OwnProps = {
  setLoading: (isLoading: boolean) => void;
};

type IndexState = {
  isLoggedIn: boolean;
  logInError?: string;
  isLoading: boolean;
};

type Props = OwnProps & PropsFromRedux;

const AuthPage: FunctionComponent<Props> = ({ setLoading, saveUser }) => {
  const [state, setState] = useState<IndexState>({
    isLoggedIn: false,
    isLoading: true,
  });

  const { authService } = useServices();

  const dispatch = useAppDispatch();

  const logIn = async (username: string, password: string) => {
    setLoading(true);
    try {
      await authService.logIn(username, password);
      onTrySignedIn();
    } catch (error) {
      if (isApiError(error) && error.response.status === 401) {
        setState({
          ...state,
          logInError: error.response.data.detail,
          isLoading: false,
        });
      } else {
        setState({
          ...state,
          logInError: "General Error: Please try again",
          isLoading: false,
        });
      }
    }
    setLoading(false);
  };

  const onTrySignedIn = async () => {
    if (localStorage.getItem("access_token") !== null) {
      const user = await checkUserMetaData();
      if (user) {
        setState({ ...state, isLoggedIn: true, isLoading: false });
        dispatch(accountsActions.setSelectedAccount(user.account));
      } else {
        setState({
          ...state,
          isLoggedIn: false,
          isLoading: false,
          logInError: "sessionExpired",
        });
      }
    } else {
      setState({ ...state, isLoggedIn: false, isLoading: false });
    }
  };

  const checkUserMetaData = async () => {
    try {
      const user = await authService.checkUserMetadata();
      if (user) {
        saveUser(user);
        return user;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    setLoading(false);
    onTrySignedIn();
  }, []);

  if (state.isLoading) {
    return <div />;
  }

  if (window.location.pathname.includes("/admin")) {
    return <AdminAuthPage />;
  }

  if (state.isLoggedIn) return <AppContainer />;

  return (
    <HashRouter>
      <Switch>
        <Route
          path={NANDI_URL.MAIN}
          render={(props) => (
            <LogIn {...props} onLogIn={logIn} logInError={state.logInError} />
          )}
        />
        <Route
          path={NANDI_URL.PASSWORD_RECOVERY}
          exact
          component={PasswordRecoveryPage}
        />
        <Route
          path={NANDI_URL.PASSWORD_RECOVERY_WITH_TOKEN}
          exact
          component={ResetPasswordPage}
        />
      </Switch>
    </HashRouter>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<PayloadAction<User>>) => {
  return {
    saveUser: (user: User) => dispatch(usersActions.saveUser(user)),
  };
};

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withIsLoading(AuthPage));
