import React, { FunctionComponent } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Route, RouteComponentProps, Switch, withRouter } from "react-router";

import NANDI_URL, { getParams } from "../../lib/url";
import { GoBack } from "../../components/GoBack/GoBack";
import FieldAlertDetailPage from "../FieldPage/FieldAlertDetailPage/FieldAlertDetailPage";
import FieldAlertsPage from "../FieldPage/FieldAlertsPage/FieldAlertsPage";
import MemberDetailPage from "./MemberDetailPage/MemberDetailPage";
import MembersByTypePage from "./MembersByTypePage/MembersByTypePage";
import { MemberGraphPage } from "./MemberGraphPage/MemberGraph.page";

const AllMembersPage: FunctionComponent<
  RouteComponentProps & WithTranslation
> = ({ match, t }) => {
  const { path } = match;

  return (
    <>
      <div
        className={"bg-gradient-to-r from-[#2f307e] to-[#586c9d] w-full p-4"}
      >
        <GoBack title={t(`allMembersPage.Members`)} />
      </div>
      <div className="pb-10">
        <Switch>
          <Route
            path={NANDI_URL.APP.MEMBERS}
            exact
            render={({ location }) => (
              <MembersByTypePage
                memberEntityType={getParams(
                  location.search,
                  "entity_type",
                  "all"
                )}
              />
            )}
          />
          <Route
            path={NANDI_URL.APP.MEMBER_BY_ID}
            exact
            component={MemberDetailPage}
          />
          <Route
            path={`${path}/:member_id/alerts`}
            exact
            component={FieldAlertsPage}
          />
          <Route
            path={`${path}/:member_id/graph/:graph`}
            exact
            component={MemberGraphPage}
          />
          <Route
            path={`${path}/:sensor_type/:member_id/alerts/:alert_id`}
            exact
            component={FieldAlertDetailPage}
          />
        </Switch>
      </div>
    </>
  );
};

export default withTranslation()(withRouter(AllMembersPage));
