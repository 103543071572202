import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import "./index.css";
import "./App.scss";
import "./styles/_all.scss";

// misc
import "./lib/language-support/i18n";

// Providers
import { ServicesProvider } from "./services/service-providers/service-provider";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { DevToolsProvider } from "./store/devToolsStore";

// pages
import AuthPage from "./pages/AuthPage/AuthPage";
import DevToolsPage from "./pages/DevToolsPage";

// components
import { ErrorBoundary } from "./components/ErrorBoundary/ErrorBoundary";
import { FieldsProvider } from "./context/fields";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const App = () => {
  return (
    <ErrorBoundary>
      <React.StrictMode>
        <DevToolsProvider>
          <ServicesProvider>
            <Provider store={store}>
              <FieldsProvider>
                <AuthPage />
                <DevToolsPage />
              </FieldsProvider>
            </Provider>
          </ServicesProvider>
        </DevToolsProvider>
      </React.StrictMode>
    </ErrorBoundary>
  );
};

const startApp = async () => {
  ReactDOM.render(<App />, document.getElementById("root"));
};

startApp();
serviceWorkerRegistration.register();
reportWebVitals();
