import { FormField } from '../../../types/form-types';

export interface CreateUserForm {
  username_field: FormField;
  email_field: FormField;
  full_name_field: FormField;
  role_field: FormField;
  password_field: FormField;
  enabled_field: FormField;
}

export enum UserRoles {
  USER = 'User',
  ADMIN = 'Admin',
}
