import classNames from "classnames";
import React, { FunctionComponent } from "react";

const PageTitle: FunctionComponent<{ className?: string }> = ({
  children,
  className,
}) => (
  <h2 className={classNames("text-2xl font-medium", className)}>{children}</h2>
);

const PageSubTitle: FunctionComponent<{ className?: string }> = ({
  children,
  className,
}) => (
  <h3 className={classNames("text-lg font-normal", className)}>{children}</h3>
);

export { PageSubTitle, PageTitle };
