import React, { FunctionComponent } from "react";
import { Icon } from "../../../components/Icon";
import { AccountAttribute } from "../../../lib/utils/account-attributes.utils";
import { FAB } from "../../../molecules/button";
import { AccountAttributeExtended } from "../../types/Account";

type AccountLayoutProps = {
  title: string;
  subtitle: string;
  accountAttributes: Array<AccountAttributeExtended>;
  isAllowedToEdit: boolean;
  isEdit: boolean;
  decorate: (key: string, val: string) => React.ReactNode | null;
  onChange: any;
  onClickToggleEdit: any;
  handleSaveAttributes: any;
};

const AccountLayout: FunctionComponent<AccountLayoutProps> = ({
  title,
  subtitle,
  accountAttributes,
  isAllowedToEdit,
  isEdit,
  decorate,
  onChange,
  onClickToggleEdit,
  handleSaveAttributes,
}) => (
  <main>
    <section className="bg-white shadow overflow-hidden rounded-lg border-t border-gray-200">
      <div className="px-4 py-5 sm:px-6 flex justify-between">
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {title}
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">{subtitle}</p>
        </div>
        {isAllowedToEdit ? (
          <section className="grid grid-cols-2 gap-2">
            {!isEdit ? (
              <FAB
                className="col-span-2"
                modifier="mini"
                type="button"
                onClick={onClickToggleEdit}
              >
                <Icon className="fill-current" iconName="edit" /> Editar
              </FAB>
            ) : null}
            {isEdit ? (
              <>
                <FAB modifier="mini" type="button" onClick={onClickToggleEdit}>
                  <Icon className="fill-current" iconName="cancel" /> Cancelar
                </FAB>
                <FAB
                  modifier="mini"
                  type="button"
                  onClick={handleSaveAttributes}
                >
                  <Icon className="fill-current" iconName="save" /> Guardar
                </FAB>
              </>
            ) : null}
          </section>
        ) : null}
      </div>
      <div>
        {accountAttributes
          ?.sort(AccountAttribute.sort)
          .map((accountAttribute) => (
            <div
              className="odd:bg-gray-50 grid grid-cols-4 px-2 py-2 sm:px-6 items-center"
              key={`${accountAttribute.account_id}-${accountAttribute.event}-${accountAttribute.attribute_name}`}
            >
              <p className="col-span-1 text-sm text-gray-500">
                {accountAttribute.attribute_name_translation}
              </p>
              <div className="col-span-2">
                {isEdit ? (
                  <input
                    name={accountAttribute.attribute_name}
                    value={accountAttribute.attribute_value}
                    onChange={onChange}
                    className="text-sm w-full text-gray-900 border-[1px] rounded-sm border-gray-300"
                  />
                ) : (
                  <p className="text-sm w-full text-gray-900">
                    {accountAttribute.attribute_value}
                  </p>
                )}
              </div>
              <div className="col-span-1">
                {decorate(
                  accountAttribute.attribute_name,
                  accountAttribute.attribute_value
                )}
              </div>
            </div>
          ))}
      </div>
    </section>
  </main>
);

export { AccountLayout };
