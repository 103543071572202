import React, { FunctionComponent } from "react";
import { HealthCalendarEvent } from "./Event";
import { HealthCalendarEvent as HealthCalendarEventType } from "../../../../types/health-calendar";
import { useTranslation } from "react-i18next";
import { YearlyEvents } from "..";
import moment from "moment";

type Props = {
  events: YearlyEvents | null;
  handleViewDetail: (event: HealthCalendarEventType) => void;
};

export const HealthCalendarMain: FunctionComponent<Props> = ({
  events,
  handleViewDetail,
}) => {
  const currentYear = moment().year();
  const { t } = useTranslation();
  return (
    <div className="flex flex-col w-full mt-4 h-[400px] overflow-y-auto  justify-start pt-4">
      {events !== null && Object.keys(events).length !== 0 ? (
        Object.entries(events).map(([year, months]) => (
          <div key={year} className={"w-full"}>
            {currentYear !== Number(year) ? (
              <h1 className="font-semibold leading-[30px] text-[#09090B] mb-4">
                {year}
              </h1>
            ) : null}
            {Object.entries(months).map(([month, events], i) => {
              return (
                <div key={month} className="w-full">
                  <h2
                    className={
                      "font-semibold leading-[30px] text-[#09090B] border-b-[1px} border-b-solid border-b-[#E4E4E7]"
                    }
                  >
                    {t("general.months." + month.toLowerCase())}
                  </h2>
                  <div>
                    {events.map((event) => {
                      return (
                        <HealthCalendarEvent
                          event={event}
                          handleViewDetail={handleViewDetail}
                          key={i}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        ))
      ) : (
        <p className="text-[#4C5862] text-lg m-auto">
          {t("admin.mainPage.healthCalendar.event.emptyState")}
        </p>
      )}
    </div>
  );
};
