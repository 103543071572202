import React, { FunctionComponent } from "react";
import { Table, TableColumn, TableRow } from "../../../components/Table/Table";
import { Link } from "react-router-dom";
import NANDI_URL from "../../../../lib/url";
import { useTranslation } from "react-i18next";

type Props = {
  cattles: Array<MemberEventsLastDay>;
};

const CattlesTable: FunctionComponent<Props> = ({ cattles }) => {
  const { t } = useTranslation();
  const tableConstants = (): Array<TableColumn> => {
    return [
      {
        title: t("admin.dashboard.memberEvents"),
        key: "member_events",
        render: (member: MemberEventsLastDay) => {
          return <>{member._count.member_events}</>;
        },
      },
      {
        title: t("admin.dashboard.cattleType"),
        key: "cattle_type",
        render: (member: MemberEventsLastDay) => {
          return <>{member.member_type}</>;
        },
      },
      {
        title: "Tag ID",
        key: "tag_id",
        render: (member: MemberEventsLastDay) => {
          return <>{member.tag_id}</>;
        },
      },
      {
        title: t("admin.dashboard.paddock"),
        key: "paddock",
        render: (member: MemberEventsLastDay) => {
          return <>{member.member_field_name}</>;
        },
      },
      {
        title: t("admin.dashboard.accountName"),
        key: "account_name",
        render: (member: MemberEventsLastDay) => {
          return <>{member.accounts?.name}</>;
        },
      },
    ];
  };

  return (
    <main>
      <Table
        className="mt-32"
        columns={tableConstants()}
        renderTableRow={false}
        items={cattles}
        isPaginationEnabled={false}
      >
        {cattles.map((cattleMember) => (
          <TableRow key={cattleMember.id}>
            {tableConstants().map((col, key) => (
              <td key={key}>
                <Link
                  className="block"
                  to={NANDI_URL.ADMIN.GENERATE_CATTLE_BY_ID(
                    String(cattleMember.id)
                  )}
                >
                  {col.render(cattleMember)}
                </Link>
              </td>
            ))}
          </TableRow>
        ))}
      </Table>
    </main>
  );
};

export default CattlesTable;
