import React, { FunctionComponent, useEffect, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { Loader } from "../../../components/Loader/Loader";
import { Notification } from "../../../components/Notification/Notification";
import { isMsgError } from "../../../lib/error";
import { useServices } from "../../../services/service-providers/service-provider";
import { RootState } from "../../../store/store";
import {
  createGeoJsonData,
  FieldSummaryWithExtraDetails,
  Property,
} from "../../../types/fields";
import { NotificationStatus } from "../../../types/notifications";
import withLoaderHOC, {
  WithLoaderProps,
} from "../../../utils/hocs/WithLoaderHOC";
import { AdminHomePage } from "./layout";

type AdminHomeState = {
  fields: Array<FieldSummaryWithExtraDetails>;
  geoJsonData: GeoJSON.FeatureCollection<GeoJSON.Geometry, Property>;
};

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = WithLoaderProps & PropsFromRedux & WithTranslation;

const initialState: AdminHomeState = {
  fields: [],
  geoJsonData: {
    type: "FeatureCollection",
    features: [],
  },
};

const AdminHomePageContainer: FunctionComponent<Props> = ({
  selectedAccount,
  isLoading,
  t,
  setLoading,
  setError,
}) => {
  const [state, setState] = useState(initialState);
  const { fieldsService } = useServices();

  const getFields = async () => {
    if (selectedAccount && selectedAccount.id) {
      try {
        setLoading(true);
        const fields = await fieldsService.getFields(selectedAccount?.id);

        const geoJsonInfo = createGeoJsonData({ fields, ref: "admin" });

        setState({
          ...state,
          fields,
          geoJsonData: geoJsonInfo,
        });
        setLoading(false);
      } catch (error) {
        if (isMsgError(error)) setError(error);
        console.log(error);
        setLoading(false);
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    getFields();
  }, [selectedAccount?.id]);

  return (
    <Loader isLoading={isLoading}>
      {selectedAccount ? (
        <AdminHomePage
          title={t("admin.homePage.title", {
            accountName: selectedAccount?.name,
          })}
          fields={state.fields}
          geoJsonData={state.geoJsonData}
        />
      ) : (
        <Notification
          status={NotificationStatus.INFORMATION}
          className="m-2 w-max"
        >
          No accounts created for this user
        </Notification>
      )}
    </Loader>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    selectedAccount: state.accounts.selectedAccount
      ? {
          ...state.accounts.selectedAccount,
          id: String(state.accounts.selectedAccount.id),
        }
      : null,
    user: state.users.user,
  };
};

const connector = connect(mapStateToProps);

export default connector(
  withTranslation()(withLoaderHOC(AdminHomePageContainer))
);
