const Color = {
  generateRandom: () => {
    const randomNum = () => Math.floor(Math.random() * (235 - 52 + 1) + 52);
    const randomRGB = () =>
      `rgb(${randomNum()}, ${randomNum()}, ${randomNum()})`;
    return randomRGB();
  },
  generateUniqueColor: () => {
    return `rgba(191, 219, 254)`;
  },
};

export { Color };
