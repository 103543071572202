import { FeatureCollection, Geometry } from "geojson";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// Components
import { Loader } from "../../../components/Loader/Loader";
import { EnhancedMap } from "../../../components/Map/Map";
import { PageTitle } from "../../../components/PageTitle/PageTitle.component";
// Services
import { useServices } from "../../../services/service-providers/service-provider";
import { Alert } from "../../../types/alerts";
import {
  createGeoJsonData,
  Property,
  FieldSummaryWithExtraDetails,
} from "../../../types/fields";
import withLoaderHOC, {
  WithLoaderProps,
} from "../../../utils/hocs/WithLoaderHOC";
import { getAlertsTableColumnsSummary } from "../alerts/AlertsPageContainer";
import { AlertsPage } from "../alerts/layout/AlertsPage";
import { MapReference, NDVICard, ReportSelection } from "./layout/components";
import { NDVIYearlyChart } from "./layout/ndvi-yearly-chart";
import { NDVIChart } from "./layout/ndvi-history-chart";
import { alertTypeTooltipStyles } from "../../MUI-styles/alertTooltip";

const groupArrayOfObjects = (list: Array<MemberWithDetails>) => {
  const groups: {
    ALERT: Array<MemberWithDetails>;
    HEAT: Array<MemberWithDetails>;
    NORMAL: Array<MemberWithDetails>;
    COWS: Array<MemberWithDetails>;
    BULLS: Array<MemberWithDetails>;
  } = {
    ALERT: [],
    HEAT: [],
    NORMAL: [],
    COWS: [],
    BULLS: [],
  };

  list.forEach((member) => {
    if (member.status === "ALERT") groups.ALERT.push(member);
    if (member.status === "HEAT") groups.HEAT.push(member);
    if (member.status === "NORMAL") {
      groups.NORMAL.push(member);
      // only add to cow/ bulls group to those in regular state
      if (member.member_type === "COW") groups.COWS.push(member);
      if (member.member_type === "BULL") groups.BULLS.push(member);
    }
  });

  return groups;
};

type State = {
  field?: FieldSummaryWithExtraDetails;
  geoJsonData?: GeoJSON.FeatureCollection<GeoJSON.Geometry, Property>;
  alerts: Array<Alert>;
  members?: Array<MemberWithDetails>;
  membersByStatus?: {
    ALERT: Array<MemberWithDetails>;
    HEAT: Array<MemberWithDetails>;
    NORMAL: Array<MemberWithDetails>;
    COWS: Array<MemberWithDetails>;
    BULLS: Array<MemberWithDetails>;
  };
};

const PaddockByIdContainer: FunctionComponent<
  WithLoaderProps & { paddockId: string }
> = ({ paddockId, isLoading, setLoading }) => {
  const { t } = useTranslation();
  const { fieldsService } = useServices();
  const [state, setState] = useState<State>({ alerts: [] });
  const [selectedReport, setSelectedReport] = useState("ndvi");

  const alertTypeStyles = alertTypeTooltipStyles();
  // Filters of map
  const [cattleFilter, setCattleFilter] = useState(true);
  const [ndviFilter, setNdviFilter] = useState(false);

  const loadInfo = async () => {
    setLoading(true);
    const fieldInfo = await fieldsService.getFieldById(paddockId);
    const alertsInfo = await fieldsService.getFieldAlerts(paddockId);

    const geoJsonInfo = createGeoJsonData({
      fields: fieldInfo ? [fieldInfo] : undefined,
      ref: "admin",
      members: fieldInfo.members,
    });
    const membersGroupedByStatus = groupArrayOfObjects(fieldInfo.members);

    setState({
      field: fieldInfo,
      geoJsonData: geoJsonInfo,
      alerts: alertsInfo,
      members: fieldInfo.members,
      membersByStatus: membersGroupedByStatus,
    });
    setLoading(false);
  };

  useEffect(() => {
    loadInfo();
    return () => {};
  }, []);

  useEffect(() => {
    let geoJsonInfo: FeatureCollection<Geometry, Property>;
    if (state.field && state.members) {
      geoJsonInfo = createGeoJsonData({
        fields: [state.field],
        ref: "admin",
        members: cattleFilter ? state.members : undefined,
      });

      setState((prevState) => ({
        ...prevState,
        geoJsonData: geoJsonInfo,
      }));
    }
  }, [cattleFilter]);

  const handleNDVIFilter = () => {
    setNdviFilter((prevNdviFilter) => !prevNdviFilter);
  };

  return (
    <Loader isLoading={isLoading}>
      <main className="p-4">
        <PageTitle>{state.field?.name}</PageTitle>
        <section className="grid grid-cols-4 gap-4 mt-4">
          <section className="col-span-4">
            <input
              checked={cattleFilter}
              onChange={(e) => setCattleFilter(!cattleFilter)}
              name="cattle"
              type="checkbox"
            />
            <label htmlFor="cattle">Cattle</label>
            {state.geoJsonData ? (
              <section className="w-full h-full min-h-[400px] max-h-[500px]">
                <EnhancedMap
                  geoJsonData={{
                    ...state.geoJsonData,
                  }}
                  tiles={
                    ndviFilter && state.field?.agro
                      ? [state.field?.agro[0].tile.ndvi]
                      : undefined
                  }
                  ndviOptions={{
                    onClick: handleNDVIFilter,
                    value: ndviFilter,
                    disabled: !state.field?.agro,
                  }}
                  sidePanel={
                    state.membersByStatus ? (
                      <MapReference membersByStatus={state.membersByStatus} />
                    ) : null
                  }
                />
              </section>
            ) : null}
          </section>
          {ndviFilter && state.field?.agroStats ? (
            <NDVICard
              className="mt-4"
              agroStatsInfo={
                state.field.agroStats[state.field.agroStats.length - 1]
              }
            />
          ) : null}
          <section className="col-span-4">
            <AlertsPage
              title={t("alertsTable.title")}
              alertsTableColumns={getAlertsTableColumnsSummary(t)}
              alerts={state.alerts}
              noAlertsMessage={t("alertsTable.noAlerts")}
            />
          </section>

          <section className="col-span-4">
            <header>
              <PageTitle className="ml-4 mt-4">
                Reportes
                <ReportSelection
                  onChange={(e: any) => {
                    setSelectedReport(e.target.value);
                  }}
                  value={selectedReport}
                />
              </PageTitle>
            </header>
            {state.field?.ndvi_tile && selectedReport === "ndvi" ? (
              <NDVIChart
                paddockName={state.field.name}
                polygonId={state.field.ndvi_tile}
              />
            ) : null}
            {state.field?.ndvi_tile && selectedReport === "yearly-ndvi" ? (
              <NDVIYearlyChart
                paddockName={`${state.field.name} Yearly`}
                polygonId={state.field.ndvi_tile}
              />
            ) : null}
          </section>
        </section>
      </main>
    </Loader>
  );
};

const PaddockByIdPage = withLoaderHOC(PaddockByIdContainer);

export { PaddockByIdPage };
