import React, {
  FocusEvent,
  FormEvent,
  FunctionComponent,
  useEffect,
  useState,
} from "react";
import { Button } from "../../../components/Button/Button";
import { Image } from "../../../components/Image/Image";
import { Input } from "../../../components/Input/Input";
import { checkValidity } from "../../../utils/utils";
import logo from "../../../assets/logo.svg";
import styles from "../Auth.module.scss";
import { RouteComponentProps, withRouter } from "react-router";
import withIsLoading from "../../../utils/hocs/withIsLoading";
import { Link } from "react-router-dom";
import { useServices } from "../../../services/service-providers/service-provider";
import { ResetPasswordForm } from "../../../types/auth";
import { FormElement } from "../../../types/form-types";
import { isApiError } from "../../../lib/error";

type ResetPasswordPageProps = {
  setLoading: (isLoading: boolean) => void;
};

type ResetPasswordPageState = {
  passwordResetForm: ResetPasswordForm;
  successfulMessage: string | null;
  apiResponseError: string | null;
  isTokenValid: boolean;
  isLoading: boolean;
};

type MatchParams = {
  recovery_token: string;
};

type Props = ResetPasswordPageProps & RouteComponentProps<MatchParams>;

const initialState: ResetPasswordPageState = {
  successfulMessage: null,
  apiResponseError: null,
  isTokenValid: false,
  isLoading: true,
  passwordResetForm: {
    new_password_field: {
      elementType: "input",
      elementConfig: {
        type: "password",
        placeholder: "New Password",
      },
      value: "",
      validation: {
        required: true,
        minLength: 8,
      },
      valid: false,
      touched: false,
    },
    confirm_new_password_field: {
      elementType: "input",
      elementConfig: {
        type: "password",
        placeholder: "Confirm New Password",
      },
      value: "",
      validation: {
        required: true,
        minLength: 8,
      },
      valid: false,
      touched: false,
    },
  },
};

const ResetPasswordPage: FunctionComponent<Props> = ({
  setLoading,
  match,
  history,
}) => {
  const [state, setState] = useState(initialState);
  const { authService } = useServices();

  useEffect(() => {
    checkTokenValidation();
  }, []);

  const checkTokenValidation = async () => {
    setLoading(true);
    try {
      const recovery_token = match.params.recovery_token;

      const data = await authService.checkPasswordRecoveryToken(recovery_token);
      if (data.message === "Valid") {
        setState({
          ...state,
          isTokenValid: true,
          isLoading: false,
        });
      }
    } catch (error) {
      setState({
        ...state,
        isTokenValid: false,
        isLoading: false,
      });
    }
    setLoading(false);
  };

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    const updatedForm = {
      ...state.passwordResetForm,
      [fieldName]: {
        ...state.passwordResetForm[fieldName as keyof ResetPasswordForm],
        value: event.target.value,
        valid:
          checkValidity(
            event.target.value,
            state.passwordResetForm[fieldName as keyof ResetPasswordForm]
              .validation
          ) &&
          state.passwordResetForm[fieldName as keyof ResetPasswordForm].touched,
        touched: true,
      },
    };
    setState({ ...state, passwordResetForm: updatedForm });
  };

  const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    try {
      const recovery_token = match.params.recovery_token;
      const new_password = state.passwordResetForm.new_password_field.value;
      const confirmed_password =
        state.passwordResetForm.confirm_new_password_field.value;

      if (new_password === confirmed_password) {
        const data = await authService.passwordReset(
          recovery_token,
          state.passwordResetForm.new_password_field.value
        );
        setState({
          ...state,
          successfulMessage: data.message,
        });
        setTimeout(() => {
          history.push("/");
        }, 2000);
      }
    } catch (error) {
      if (isApiError(error) && error.response.status === 401) {
        setState({
          ...state,
          apiResponseError: error.response.data.detail,
        });
      } else {
        setState({
          ...state,
          apiResponseError: "General Error: Please try again",
        });
      }
    }
    setLoading(false);
  };

  const onTouchEventHandler = (event: FocusEvent, fieldName: string) => {
    const updatedForm = {
      ...state.passwordResetForm,
      [fieldName]: {
        ...state.passwordResetForm[fieldName as keyof ResetPasswordForm],
        valid: checkValidity(
          state.passwordResetForm[fieldName as keyof ResetPasswordForm].value,
          state.passwordResetForm[fieldName as keyof ResetPasswordForm]
            .validation
        ),
        touched: true,
      },
    };
    setState({ ...state, passwordResetForm: updatedForm });
  };

  const formElementsArray: FormElement[] = [];
  Object.keys(state.passwordResetForm).forEach((key) => {
    formElementsArray.push({
      id: key,
      config: state.passwordResetForm[key as keyof ResetPasswordForm],
    });
  });

  let form = formElementsArray.map((formElement) => (
    <Input
      className={styles.formInput}
      elementType={formElement.config.elementType}
      key={formElement.id}
      elementConfig={formElement.config.elementConfig}
      value={formElement.config.value}
      touched={formElement.config.touched}
      invalid={!formElement.config.valid && formElement.config.touched}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        inputChangedHandler(event, formElement.id)
      }
      onBlur={(event: FocusEvent) => {
        onTouchEventHandler(event, formElement.id);
      }}
    />
  ));

  const errorMessages = [];
  if (
    formElementsArray.some(
      (formElement) => !formElement.config.valid && formElement.config.touched
    )
  ) {
    errorMessages.push("Password must be 8 characters long");
  }

  const new_password = state.passwordResetForm.new_password_field.value;
  const confirmed_password =
    state.passwordResetForm.confirm_new_password_field.value;

  if (new_password !== confirmed_password) {
    errorMessages.push("Passwords must be the same");
  }

  const isFormValid =
    errorMessages.length === 0 &&
    formElementsArray.some((formElement) => formElement.config.touched);

  if (!state.isLoading) {
    if (state.isTokenValid) {
      return (
        <div className={styles.loginPage}>
          <Image className={styles.headerLogin} imageLink={logo} />

          <form onSubmit={submitHandler}>
            <div className={styles.body}>
              <div className={styles.formInputs}>{form}</div>
              <div className={styles.messagesBox}>
                {errorMessages.map((errorMessage, index) => (
                  <div key={index} className={styles.errorMessage}>
                    {errorMessage}
                  </div>
                ))}
                {state.successfulMessage ? (
                  <div className={styles.successfulMessage}>
                    {state.successfulMessage}
                  </div>
                ) : null}
                {state.apiResponseError ? (
                  <div className={styles.errorMessage}>
                    {state.apiResponseError}
                  </div>
                ) : null}
              </div>
            </div>
            <div className={styles.btnForm}>
              <Button disabled={!isFormValid}>Create New Password</Button>
            </div>
          </form>
        </div>
      );
    } else {
      return (
        <div className={styles.loginPage}>
          <Image className={styles.headerLogin} imageLink={logo} />
          <div className={styles.body}>
            <div className={styles.invalidTokenTitle}>
              Reset Password Invalid
            </div>
            <div className={styles.invalidTokenMessage}>
              The reset password link you followed is either invalid or has
              expired. Please click <Link to={"/passwordRecovery"}>here</Link>{" "}
              to request a new link.
            </div>
          </div>
        </div>
      );
    }
  } else {
    return <div />;
  }
};

export default withRouter(withIsLoading(ResetPasswordPage));
