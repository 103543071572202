import classNames from "classnames";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Icon } from "../../../Icon";
import styles from "./FooterGauchoAppLink.module.scss";

interface FooterGauchoAppLinkProps {
  linkName: string;
  linkIcon: string;
  linkTo: string;
  className?: string;
}

export const FooterGauchoAppLink: FunctionComponent<
  FooterGauchoAppLinkProps
> = ({ className, linkIcon, linkTo, linkName }) => {
  const { t } = useTranslation();
  return (
    <NavLink
      className={classNames(styles.footerLinkContainer, className)}
      activeClassName={styles.active}
      to={linkTo}
    >
      <div className={styles.footerLinkIcon}>
        <Icon iconName={linkIcon} />
      </div>
      <div className={styles.footerLinkName}>{linkName}</div>
    </NavLink>
  );
};
