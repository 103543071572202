import { User } from '../../types/auth';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UsersState {
  user: User | null;
}

const initialState: UsersState = {
  user: null,
};

export const usersSlice = createSlice({
  name: 'users',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    saveUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    deleteUser: (state) => {
      state.user = null;
    },
  },
});

const { saveUser, deleteUser } = usersSlice.actions;
export const usersActions = {
  saveUser,
  deleteUser,
};

export default usersSlice.reducer;
