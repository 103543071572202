import React, { FunctionComponent } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Header from "../../components/Header";
import { RootState } from "../../store/store";
import { Dispatch } from "react";
import { PayloadAction } from "@reduxjs/toolkit";
import { Account } from "../../admin-site/types/Account";
import { accountsActions } from "../../store/slices/accounts-slice";
import { connect, ConnectedProps } from "react-redux";
import { WithLoaderProps } from "../../utils/hocs/WithLoaderHOC";
import SelectAccountContainer from "../HomePage/SelectAccountContainer";

type Props = WithLoaderProps & PropsFromRedux & WithTranslation;

const SuperUserBadge: FunctionComponent = () => (
  <span className="font-semibold p-2 text-xs rounded bg-purple-700 text-white">
    SuperUser
  </span>
);

const SettingsPage: FunctionComponent<Props> = ({ t, user }) => {
  return (
    <>
      <Header>{t("settingsPage.title")}</Header>
      <main className="p-2 flex flex-col gap-4">
        {user?.role === "Superuser" && (
          <div className="flex align-middle items-center gap-2">
            <SuperUserBadge />
            <p className="font-semibold">{t("settingsPage.selectAccount")}</p>
            <SelectAccountContainer />
          </div>
        )}
        <div className="flex align-middle items-center gap-2">
          <p className="font-semibold">{t("settingsPage.fullName")}</p>
          {user?.full_name}
        </div>

        <div className="flex align-middle items-center gap-2">
          <p className="font-semibold">{t("settingsPage.email")}</p>
          {user?.email}
        </div>

        <div className="flex align-middle items-center gap-2">
          <p className="font-semibold">{t("settingsPage.account")}</p>
          {user?.account.name}
        </div>
      </main>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    selectedAccount: state.accounts.selectedAccount
      ? {
          ...state.accounts.selectedAccount,
          id: String(state.accounts.selectedAccount.id),
        }
      : null,
    user: state.users.user,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<PayloadAction<Array<Account>>>
) => {
  return {
    saveAccounts: (accounts: Array<Account>) =>
      dispatch(accountsActions.saveAccounts(accounts)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withTranslation()(connector(SettingsPage));
