import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import classNames from "classnames";
import React, { FunctionComponent } from "react";
import { FieldSummaryWithExtraDetails } from "../../../types/fields";
import "./GauchoAppSelection.scss";

type Props = {
  options: { id: number; name: string }[];
  label: string;
  selectId: number;
  onChange: (event: React.ChangeEvent<any>) => void;
  disabled: boolean;
};

const FieldSelection: FunctionComponent<Props> = ({
  options,
  onChange,
  selectId,
  label,
  disabled,
}) => {
  return (
    <div className="gauchoApp_fieldSelector">
      <FormControl
        className={classNames(
          disabled
            ? "gauchoApp_fieldSelect_form gauchoApp_fieldSelect_form_disabled"
            : "gauchoApp_fieldSelect_form"
        )}
        disabled={disabled}
      >
        <InputLabel
          shrink
          htmlFor="simple-select"
          className={classNames(
            disabled
              ? "gauchoApp_fieldSelect_label gauchoApp_fieldSelect_label_disabled"
              : "gauchoApp_fieldSelect_label"
          )}
        >
          {label}
        </InputLabel>
        <Select
          className="gauchoApp_fieldSelect_select"
          labelId="simple-select-label"
          id="simple-select"
          value={selectId}
          label={label}
          onChange={(event) => onChange(event)}
          disableUnderline={true}
        >
          {options.map((option) => {
            return (
              <MenuItem value={option.id} key={option.id}>
                {option.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default FieldSelection;
