import classNames from "classnames";
import React, { FunctionComponent } from "react";
import { SelectOption } from "../../../../components/Input/Input";
import { User } from "../../../../types/auth";
import { PageTitle } from "../../../../components/PageTitle/PageTitle.component";
import { Select } from "../../../../components/Select/Select";

type UserPageProps = {
  currentUser: User;
  userDetailsSection: {
    title: string;
    languageOption: {
      onChangeLanguage: (event: React.ChangeEvent<HTMLSelectElement>) => void;
      title: string;
      selectedLanguage: string;
      options: SelectOption[];
    };
  };
};

const UserPage: FunctionComponent<UserPageProps> = ({
  currentUser,
  userDetailsSection,
}) => (
  <main>
    <header className={classNames("p-8 bg-gray-300")}>
      <p className={"m-auto w-full text-center"}>
        <span className="font-semibold text-lg">{currentUser.full_name} </span>
        <span className="text-gray-500">@{currentUser.username}</span>
      </p>
    </header>
    <section className={"m-auto w-1/2 mt-4"}>
      <PageTitle>{userDetailsSection.title}</PageTitle>
      <div className="mt-2">
        <h3>{userDetailsSection.languageOption.title}</h3>
        <Select
          className="mt-1"
          onChange={userDetailsSection.languageOption.onChangeLanguage}
          selectedValue={userDetailsSection.languageOption.selectedLanguage}
          options={userDetailsSection.languageOption.options}
        />
      </div>
    </section>
  </main>
);

export default UserPage;
