import {
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import moment from "moment";
import React, { ChangeEvent, FormEvent, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import {
  HealthCalendarEntityKey,
  HealthCalendarNewEventData,
} from "../../../../types/health-calendar";

type Props = {
  tabs: HealthCalendarEntityKey[];
  openModal: boolean;
  setOpenModal: (openModal: boolean) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  handleChange: (
    event: ChangeEvent<{ name?: unknown; value: unknown }>
  ) => void;
  handleCancel: () => void;
  newEventData: HealthCalendarNewEventData;
};
export const LoadEventModal: FunctionComponent<Props> = ({
  tabs,
  openModal,
  setOpenModal,
  handleSubmit,
  handleChange,
  handleCancel,
  newEventData,
}) => {
  const formInitData = {
    entityType: "ALL",
    date: "",
    accountable: "",
    details: "",
  };

  const { t } = useTranslation();

  const currentDate = moment().format("YYYY-MM");
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      form: {
        width: 400,
        backgroundColor: "white",
        gap: "24px",
        display: "flex",
        flexDirection: "column",
        padding: "32px",
      },
      label: {
        textAlign: "left",
        width: "100%",
        fontSize: "13px",
      },
      input: {
        width: "100%",
      },
      textarea: {
        resize: "none",
        border: "1px solid grey",
      },
    })
  );

  const MUIStyles = useStyles();
  return (
    <Dialog
      open={openModal}
      onClose={() => setOpenModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className="rounded-lg border bg-card text-card-foreground shadow-sm flex flex-col p-4">
        <h1 className="whitespace-nowrap text-2xl font-semibold leading-none tracking-tight">
          {t("admin.mainPage.healthCalendar.event.create.newEvent")}
        </h1>
        <form onSubmit={handleSubmit}>
          <DialogContent className={MUIStyles.form}>
            <div className="flex flex-col space-y-1.5">
              <label
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                htmlFor="title"
              >
                {t("admin.mainPage.healthCalendar.event.create.title")}
              </label>
              <input
                className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                id="name"
                placeholder="Name of your project"
                onChange={handleChange}
                name={"title"}
              />
            </div>
            <div className="flex flex-col space-y-1.5">
              <label
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                htmlFor="accountable"
              >
                {t("admin.mainPage.healthCalendar.event.create.accountable")}
              </label>
              <input
                className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                id="name"
                placeholder="Name of your project"
                onChange={handleChange}
                name={"accountable"}
              />
            </div>
            <label
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 mb-[-20px]"
              htmlFor="accountable"
            >
              {t("admin.mainPage.healthCalendar.event.create.date")}
            </label>
            <TextField
              id="date"
              type="month"
              className={MUIStyles.input}
              defaultValue={currentDate}
              onChange={handleChange}
              name={"date"}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <textarea
              className="flex min-h-[80px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 resize-none"
              onChange={handleChange}
              name={"details"}
            />
          </DialogContent>
          <DialogActions>
            <button
              onClick={handleCancel}
              type="button"
              className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2"
            >
              {t("admin.mainPage.healthCalendar.event.create.cancel")}
            </button>
            <button
              color="primary"
              type="submit"
              className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2"
            >
              {t("admin.mainPage.healthCalendar.event.create.accept")}
            </button>
          </DialogActions>
        </form>
      </div>
    </Dialog>
  );
};
