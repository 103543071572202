import classNames from "classnames";
import React, { FunctionComponent } from "react";

const FAB: FunctionComponent<{
  className?: string;
  isLink?: boolean;
  href?: string;
  onClick?: any;
  disabled?: boolean;
  type?: "submit" | "reset" | "button" | undefined;
  modifier?: "large" | "small" | "mini";
}> = ({
  children,
  className,
  isLink = false,
  href = "",
  type = undefined,
  modifier = "small",
  onClick,
  disabled = false,
}) => {
  const fullClassName = classNames(
    "w-max rounded-2xl font-semibold text-sm bg-violet-600 text-violet-100",
    className
  );

  const stateLayerClassName = classNames(
    "flex items-center rounded-2xl space-x-1 bg-violet-100 bg-opacity-0 hover:bg-opacity-5 active:bg-opacity-10",
    {
      "p-2": modifier === "mini",
      "p-4": modifier === "small",
      "p-8": modifier === "large",
    }
  );

  const ContentStateLayer = () => (
    <span className={stateLayerClassName}>{children}</span>
  );

  if (isLink) {
    return (
      <a href={href} className={fullClassName}>
        <ContentStateLayer />
      </a>
    );
  }

  return (
    <button
      onClick={onClick}
      type={type}
      className={fullClassName}
      disabled={disabled}
    >
      <ContentStateLayer />
    </button>
  );
};

export { FAB };
