const NANDI_URL = {
  MAIN: "/",
  LOGOUT: "/logout",
  PASSWORD_RECOVERY: "/passwordRecovery",
  PASSWORD_RECOVERY_WITH_TOKEN: "/resetPassword/:recovery_token",
  APP: {
    ALERTS: "/app/alerts",
    MEMBERS: "/app/members",
    MEMBER_BY_ID: "/app/members/:memberId",
    GENERATE_MEMBER_BY_ID: (memberId: string) => `/app/members/${memberId}`,
    GENERATE_MEMBER_GRAPH_BY_ID: (memberId: string, graph: string) =>
      `/app/members/${memberId}/graph/${graph}`,
    HOME: "/app/home",
    MAP: "/app/map",
    MONITOR: "/app/monitor",
    MONITOR_BY_ID: "/app/monitor/:id",
    MONITOR_BY_ID_MAP: "/app/monitor/:id/map",
    GENERATE_MONITOR_BY_ID_MAP: (paddockId: string) =>
      `/app/monitor/${paddockId}/map`,
    SETTINGS: "/app/settings",
    GAUCHOAPP: "/app/gauchoapp/:action",
    GAUCHOAPPDOWNLOAD: "/app/gauchoapp/download",
    GAUCHOAPPUPLOAD: "/app/gauchoapp/upload",
  },
  ADMIN: {
    ACCOUNTS: "/admin/accounts",
    ACCOUNT_BY_ID: "/admin/accounts/:accountId",
    GENERATE_ACCOUNT_BY_ID: (accountId: string) =>
      `/admin/accounts/${accountId}`,
    ALERTS: "/admin/alerts",
    ALERT_BY_ID: "/admin/alerts/:alertId",
    GENERATE_ALERT_BY_ID: (alertId: string) => `/admin/alerts/${alertId}`,
    CATTLE: "/admin/cattle",
    CATTLE_BY_ID: "/admin/cattle/:memberId",
    GENERATE_CATTLE_BY_ID: (memberId: string) => `/admin/cattle/${memberId}`,
    MAIN: "/admin",
    MAIN_OLD: "/admin-old",
    REPORTS: "/admin/reports",
    SENSORS: "/admin/sensors",
    SENSORS_UPLOAD: "/admin/sensors/upload",
    USER: "/admin/user",
    USERS: "/admin/users",
    PADDOCK_BY_ID: "/admin/monitor/:paddockId",
    PADDOCK_BY_ID_OLD: "/admin/monitor-old/:paddockId",
    GENERATE_BY_PADDOCK_ID: (paddockId: string) =>
      `/admin/monitor/${paddockId}`,
    INSIGHTS: "/admin/insights",
    DASHBOARD: "/admin/dashboard",
    INVENTORY: "/admin/inventory",
  },
};

const getParams = (search: string, key: string, defaultValue: string) => {
  let params = new URLSearchParams(search);
  let param = params.get(key);
  if (param != null) return param;
  return defaultValue;
};

export { getParams };

export default NANDI_URL;
