import classNames from "classnames";
import React, { FunctionComponent } from "react";

type SelectProps = {
  className?: string;
  options: Array<SelectOption>;
  selectedValue: any;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
};

type SelectOption = {
  value: any;
  displayValue: string;
};

export const Select: FunctionComponent<SelectProps> = ({
  options,
  className,
  selectedValue,
  onChange,
}) => {
  if (options.length === 1) {
    return (
      <div className={classNames(className)}>{options[0].displayValue}</div>
    );
  }
  return (
    <select
      onChange={onChange}
      className={classNames(
        className,
        "w-full max-w-lg h-full rounded-sm	py-1.5 px-2 bg-gray-100 focus:border focus:border-gray-300"
      )}
      value={selectedValue}
    >
      {options.map((option, index) => (
        <option value={option.value} key={index}>
          {option.displayValue}
        </option>
      ))}
    </select>
  );
};
