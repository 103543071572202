import React, { FunctionComponent, useEffect, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { connect, ConnectedProps } from "react-redux";

import styles from "./AlertsMainPage.module.scss";

import {
  Alert,
  sortAlertsAlphabetically,
  sortAlertsByTime,
} from "../../../types/alerts";

// components
import { Icon } from "../../../components/Icon";
import { Loader } from "../../../components/Loader/Loader";
import SelectItemModal from "../../../components/SelectItemModal";
import { FieldAlertsListElement } from "../../../components/FieldAlertsListElement/FieldAlertsListElement";
// hocs & hooks
import withModalHOC, { WithModalProps } from "../../../utils/hocs/withModalHOC";
import withLoaderHOC, {
  WithLoaderProps,
} from "../../../utils/hocs/WithLoaderHOC";
import { useServices } from "../../../services/service-providers/service-provider";
import { isMsgError } from "../../../lib/error";
import NANDI_URL from "../../../lib/url";
import { RootState } from "../../../store/store";

type AlertsMainPageState = {
  alerts: Array<Alert>;
  displaySortMethodModal: boolean;
  selectedSortMethod: number;
};

type Props = RouteComponentProps &
  WithModalProps &
  PropsFromRedux &
  WithTranslation &
  WithLoaderProps;

const initialState: AlertsMainPageState = {
  alerts: [],
  displaySortMethodModal: false,
  selectedSortMethod: 1,
};

type SortMethod = {
  id: string;
  name: string;
  action: any;
};

const sortMethods: { [sortMethodId: number]: SortMethod } = {
  1: {
    id: "DATE",
    name: "Time",
    action: (members: Array<Alert>) => {
      return members.sort(sortAlertsByTime);
    },
  },
  2: {
    id: "ALPHA",
    name: "A-Z",
    action: (members: Array<Alert>) => {
      return members.sort(sortAlertsAlphabetically);
    },
  },
};

const AlertsMainPage: FunctionComponent<Props> = ({
  t,
  isLoading,
  setError,
  setLoading,
  user,
}) => {
  const { alertsService } = useServices();
  const [state, setState] = useState(initialState);

  const getAlerts = async () => {
    setLoading(true);
    const accountId = user?.account_id;
    const data = await alertsService.getAlertsV2({
      account_id: String(accountId),
    });
    setState({
      ...state,
      alerts: data?.sort(sortAlertsByTime),
    });
    setLoading(false);
  };

  const changeSelectedSortMethod = (sortMethodId: number): void => {
    setState({
      ...state,
      alerts: sortMethods[sortMethodId].action(state.alerts),
      selectedSortMethod: sortMethodId,
    });
  };

  const changeModalState = (): void => {
    setState({
      ...state,
      displaySortMethodModal: !state.displaySortMethodModal,
    });
  };

  useEffect(() => {
    getAlerts();
  }, []);

  return (
    <>
      <SelectItemModal
        displayModal={state.displaySortMethodModal}
        items={[
          { id: 1, name: "Time" },
          {
            id: 2,
            name: "A-Z",
          },
        ]}
        selectedItem={state.selectedSortMethod}
        onAction={changeSelectedSortMethod}
        onClose={changeModalState}
      />
      <div>
        <div className={styles.sortContainer}>
          <div className={styles.sortTitle} onClick={changeModalState}>
            {t("general.sort")}
          </div>
          <div className={styles.arrowIconContainer}>
            <Icon iconName={"arrow_down"} />
          </div>
        </div>
        <div className={styles.alertsList}>
          <Loader isLoading={isLoading}>
            {state.alerts.length > 0
              ? state.alerts.map((alert: any) => (
                  <FieldAlertsListElement
                    className={styles.alertPreview}
                    alert={alert}
                    key={alert.id}
                    link={`${NANDI_URL.APP.ALERTS}/${alert.id}`}
                  />
                ))
              : null}
          </Loader>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    user: state.users.user,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedAlertsMainPage = connector(withLoaderHOC(AlertsMainPage));

export default withTranslation()(ConnectedAlertsMainPage);
