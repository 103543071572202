import React, { ChangeEvent, FormEvent, FunctionComponent } from "react";

import { FieldSummary } from "../../../types/fields";
import { Button } from "../../../components/Button/Button";
import { useTranslation } from "react-i18next";
import { PageTitle } from "../../../components/PageTitle/PageTitle.component";
import { Notification } from "../../../components/Notification/Notification";
import { NotificationStatus } from "../../../types/notifications";
import FieldsInventoryTable from "./FieldsInventoryTable";

type Props = {
  fields: Array<FieldSummary>;
  onChange: (e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => void;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  inventory: Inventory;
  tableData: Array<GetInventory> | [];
};

const InventoryPage: FunctionComponent<Props> = ({
  fields,
  onChange,
  inventory,
  onSubmit,
  tableData,
}) => {
  const { t } = useTranslation();
  const disabledButton = inventory.fieldId === 0 || inventory.memberType === "";
  return (
    <div className="w-full flex flex-col items-center justify-center mt-20">
      <PageTitle>{t("admin.inventoryPage.title")}</PageTitle>

      <form className="flex flex-col gap-4 w-[600px] mt-4" onSubmit={onSubmit}>
        <Notification status={NotificationStatus.INFORMATION}>
          {t("admin.inventoryPage.disclaimer")}
        </Notification>
        <select
          onChange={onChange}
          className="w-full h-9"
          value={inventory.fieldId}
          name="fieldId"
        >
          <option>{t("admin.inventoryPage.selectField")}</option>
          {fields.map((field) => (
            <option key={field.id} value={field.id}>
              {field.name}
            </option>
          ))}
        </select>
        <select
          onChange={onChange}
          className="w-full h-9"
          value={inventory.memberType ?? ""}
          name="memberType"
        >
          <option>{t("admin.inventoryPage.selectCattleType")}</option>
          <option key={"cow"} value={"COW"}>
            {t("entityTypes.COW")}
          </option>
          <option key={"bull"} value={"BULL"}>
            {t("entityTypes.BULL")}
          </option>
        </select>
        <div className="flex flex-col gap-2 w-full">
          <label htmlFor="quantity">
            {t("admin.inventoryPage.insertQuantity")}
          </label>
          <input
            value={inventory.quantity}
            onChange={onChange}
            type="number"
            id="quantity"
            name="quantity"
            className=" px-4 py-2 text-gray-700 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none w-full"
          />
        </div>
        <Button disabled={disabledButton}>
          {t("admin.inventoryPage.saveCta")}
        </Button>
      </form>
      {tableData.length > 0 && (
        <FieldsInventoryTable fieldsInventory={tableData} />
      )}
    </div>
  );
};

export default InventoryPage;
