import moment from "moment";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Loader } from "../../../components/Loader/Loader";
import { useMember } from "../../../context/member";
import { MemberEvent } from "../../../lib/utils/member-event.utils";
import { useServices } from "../../../services/service-providers/service-provider";
import { Alert } from "../../../types/alerts";
import withLoaderHOC, {
  WithLoaderProps,
} from "../../../utils/hocs/WithLoaderHOC";
import { CattleMemberPage } from "./layout/CattleMemberPage";
import { User, UserRole } from "../../../types/auth";
import { WithTranslation } from "react-i18next";
import {
  createGeoJsonData,
  FieldSummaryWithExtraDetails,
  Property,
} from "../../../types/fields";
import { AccountAttribute } from "../../types/Account";

type Props = WithLoaderProps & WithTranslation;

const PageContainer: FunctionComponent<
  {
    memberId: string;
    selectedAccount: {
      name: string;
      id: string;
      disabled: boolean;
      demo: boolean;
    };
  } & Props
> = ({ memberId, selectedAccount, isLoading, t, setLoading, setError }) => {
  const [state, setState] = useState<{
    fields: Array<FieldSummaryWithExtraDetails>;
    currentFieldName: string;
    weather: AccountAttribute | null;
    geoJsonData: GeoJSON.FeatureCollection<GeoJSON.Geometry, Property>;
    member: MemberWithDetails | null;
    alerts: Array<Alert> | null;
    weightEvents: Array<MemberEvent> | null;
    vaccinationEvents: Array<MemberEvent> | null;
    deltaActivityV3: DailyActivity | null;
    deltaActivityAvgSuperUser: DailyActivityWithAvg | null;
    dailyCattleJumpBull: DailyCattleJumpBull | null;
    dailyBullJumps: DailyActivityV2 | null;
    paddockAssignmentEvents: Array<MemberEvent> | null;
    sensorPairingEvents: Array<MemberEvent> | null;
    caravanaHistoryEvents: Array<MemberEvent> | null;
    pregnancyEvents: Array<MemberEvent> | null;
    contactWithCowAggrEvents: Array<MemberEvent> | null;
    contactWithBullAggrEvents: Array<MemberEvent> | null;
    timeline: Timeline | null;
    user: User | null;
  }>({
    member: null,
    alerts: null,
    weightEvents: null,
    vaccinationEvents: null,
    dailyBullJumps: null,
    deltaActivityV3: null,
    deltaActivityAvgSuperUser: null,
    dailyCattleJumpBull: null,
    paddockAssignmentEvents: null,
    sensorPairingEvents: null,
    caravanaHistoryEvents: null,
    pregnancyEvents: null,
    contactWithCowAggrEvents: null,
    contactWithBullAggrEvents: null,
    timeline: null,
    user: null,
    geoJsonData: {
      type: "FeatureCollection",
      features: [],
    },
    weather: null,
    fields: [],
    currentFieldName: "",
  });
  const {
    fieldsService,
    membersService,
    accountsService,
    timelineService,
    usersService,
  } = useServices();
  const memberContext = useMember();

  const loadPage = async () => {
    if (selectedAccount && selectedAccount.id) {
      setLoading(true);
      try {
        memberContext.dispatch({ type: "request" });
        const user = await usersService.getCurrentUser();
        const member = await membersService.getMemberByIdV2(Number(memberId));
        const alerts = await membersService.getMemberAlertsByIdV2(
          Number(memberId)
        );
        const memberEvents = await membersService.getMemberEventsDisk(memberId);

        const deltaActivityV3 = await membersService.getDeltaActivityByIdV3(
          Number(memberId)
        );
        const deltaActivityAvgSuperUser =
          user?.role === UserRole.SUPERUSER
            ? await membersService.getDeltaActivityAvgSuperUser(
                Number(memberId)
              )
            : null;
        const dailyCattleJumpBull = await membersService.getDailyCattleJumpBull(
          Number(memberId)
        );

        const dailyBullJumps = await membersService.getDailyBullJumpsCounter(
          Number(memberId)
        );
        const timeline = await timelineService.getByMemberId(Number(memberId));

        const vaccinationEvents = MemberEvent.getByType(
          memberEvents,
          "VACCINATION"
        ).sort(MemberEvent.sortByTs);

        const weightEvents = MemberEvent.getByType(memberEvents, "WEIGHT").sort(
          MemberEvent.sortByTs
        );

        const pregnancyEvents =
          member.member_type === "COW"
            ? MemberEvent.getByType(memberEvents, "PREGNANCY").sort(
                MemberEvent.sortByTs
              )
            : [];

        const paddockAssignmentEvents = MemberEvent.getByType(
          memberEvents,
          "PADDOCK_ASSIGNMENT"
        ).sort(MemberEvent.sortByTs);

        const sensorPairingEvents = MemberEvent.getByType(
          memberEvents,
          "SENSOR_PAIRING"
        ).sort(MemberEvent.sortByTs);

        const caravanaHistoryEvents = MemberEvent.getByType(
          memberEvents,
          "CARAVANA_EDITED"
        ).sort(MemberEvent.sortByTs);

        const contactWithCowAggrEvents =
          member.member_type === "BULL"
            ? MemberEvent.getByType(memberEvents, "CONTACT_WITH_COW_AGGR").sort(
                MemberEvent.sortByTs
              )
            : null;

        const contactWithBullAggrEvents =
          member.member_type === "COW"
            ? MemberEvent.getByType(
                memberEvents,
                "CONTACT_WITH_BULL_AGGR"
              ).sort(MemberEvent.sortByTs)
            : null;

        const fields = await fieldsService.getFieldsWithMembersInventory(
          selectedAccount.id
        );
        const fieldAttachments = await fieldsService.getFieldAttachments(
          selectedAccount.id
        );

        const weather = await accountsService.getAccountWeather(
          Number(selectedAccount.id)
        );

        const geoJsonInfo = createGeoJsonData({
          fields,
          ref: "admin",
          members: [member],
          fieldAttachments,
        });

        memberContext.dispatch({ type: "success", member: member });
        setState({
          ...state,
          member,
          alerts,
          weightEvents,
          vaccinationEvents,
          deltaActivityV3,
          deltaActivityAvgSuperUser,
          dailyCattleJumpBull,
          dailyBullJumps,
          paddockAssignmentEvents,
          sensorPairingEvents,
          pregnancyEvents,
          contactWithCowAggrEvents,
          contactWithBullAggrEvents,
          timeline,
          user,
          fields,
          geoJsonData: geoJsonInfo,
          weather,
          caravanaHistoryEvents,
        });
        setLoading(false);
      } catch (e) {
        memberContext.dispatch({
          type: "failure",
          error: "Couldn't get the member",
        });
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    loadPage();
  }, [memberId, selectedAccount]);

  useEffect(() => {
    if (memberId) document.title = `Nandi | Cattle ${memberId}`;
  }, [memberId]);

  const getCurrentFieldName = () => {
    const lastPosition = state.member?.last_position;
    let fieldName = undefined;
    if (lastPosition && state.fields.length > 0) {
      const [currentLon, currentLat] = lastPosition.split(":");
      state.fields.forEach((x) => {
        let inside = false;

        for (
          let i = 0, j = x.outlines.length - 1;
          i < x.outlines.length;
          j = i++
        ) {
          let iLatitude = x.outlines[i].latitude;
          let iLongitude = x.outlines[i].longitude;
          let jLatitude = x.outlines[j].latitude;
          let jLongitude = x.outlines[j].longitude;

          let intersect =
            iLongitude > Number(currentLon) !==
              jLongitude > Number(currentLon) &&
            Number(currentLat) <
              ((jLatitude - iLatitude) * (Number(currentLon) - iLongitude)) /
                (jLongitude - iLongitude) +
                iLatitude;
          if (intersect) fieldName = x.name;
        }
      });
    }

    return fieldName ?? "outside";
  };

  useEffect(() => {
    setState({ ...state, currentFieldName: getCurrentFieldName() });
  }, [state.fields]);

  return (
    <Loader isLoading={isLoading}>
      {state.member ? (
        <CattleMemberPage
          currentFieldName={state.currentFieldName}
          fields={state.fields}
          geoJsonData={state.geoJsonData}
          member={state.member}
          alerts={state.alerts}
          dailyBullJumps={state.dailyBullJumps}
          weightEvents={state.weightEvents}
          vaccinationEvents={state.vaccinationEvents}
          deltaActivityV3={state.deltaActivityV3}
          deltaActivityAvgSuperUser={state.deltaActivityAvgSuperUser}
          paddockAssignmentEvents={state.paddockAssignmentEvents}
          sensorPairingEvents={state.sensorPairingEvents}
          caravanaHistoryEvents={state.caravanaHistoryEvents}
          pregnancyEvents={state.pregnancyEvents}
          contactWithCowAggrEvents={state.contactWithCowAggrEvents}
          contactWithBullAggrEvents={state.contactWithBullAggrEvents}
          timeline={state.timeline}
          user={state.user}
        />
      ) : null}
    </Loader>
  );
};

const CattleMemberPageContainer = withLoaderHOC(PageContainer);

export { CattleMemberPageContainer };
