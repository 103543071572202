import React, { FunctionComponent } from "react";

import styles from "./HeaderHomePage.module.scss";
import nandi_logo from "../../assets/nandi_logo.svg";
import { HeaderMembersInfoContainer } from "./HeaderMembersInfoContainer/HeaderMembersInfoContainer";
import { OldMemberCount } from "../../types/members";
import { useTranslation } from "react-i18next";

interface HeaderHomePageProps {
  membersCount: Array<OldMemberCount>;
  username?: string;
}

export const HeaderHomePage: FunctionComponent<HeaderHomePageProps> = ({
  username,
  membersCount,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.header}>
      <div className={styles.expandableHeader}>
        <div className={styles.logoContainer}>
          <img src={nandi_logo} className={styles.imgHeader} alt="logo_icon" />
          <div className={styles.userTitle}>
            {t("homepage.header.greetings")} {username}
          </div>
        </div>
      </div>
      <HeaderMembersInfoContainer membersCount={membersCount} />
    </div>
  );
};
