import L, { LatLngTuple, Layer, LeafletMouseEvent } from "leaflet";
import React, { FunctionComponent } from "react";
import { MapContainer, TileLayer, GeoJSON } from "react-leaflet";

interface MapComponentProps {
  geoJsonData: GeoJSON.FeatureCollection<GeoJSON.Polygon, FieldProperty>;
}

interface FieldProperty {
  id: number;
  name: string;
  status: string;
}

export const PaddocksMap: FunctionComponent<MapComponentProps> = (props) => {
  const featuresBounds = L.geoJSON(props.geoJsonData).getBounds();
  let mapCenter: LatLngTuple = [-37.572672, -62.052659];
  if (
    !(
      featuresBounds &&
      Object.keys(featuresBounds).length === 0 &&
      featuresBounds.constructor === Object
    )
  ) {
    const center = featuresBounds.getCenter();
    mapCenter = [center.lat, center.lng];
  }
  const countryStyle = {
    fillColor: "white",
    fillOpacity: 0.3,
    color: "white",
    weight: 1,
  };

  const onFieldClick = (event: LeafletMouseEvent): void => {};

  const onEachField = (
    feature: GeoJSON.Feature<GeoJSON.Polygon, FieldProperty>,
    layer: Layer
  ) => {
    const tooltip = L.tooltip({ permanent: true });
    tooltip.setContent(feature.properties.name);
    layer.bindTooltip(tooltip).openTooltip();
    if (feature.properties.status === "ALERT") {
      (layer as any).options.color = "#e81579";
      (layer as any).options.fillColor = "#e81579";
    }
    layer.on({
      click: onFieldClick,
    });
  };

  return (
    <MapContainer
      key={mapCenter.toString()}
      center={mapCenter}
      zoom={14}
      zoomControl={false}
      scrollWheelZoom={true}
      className="w-full h-full"
      whenCreated={(map) => {
        if (
          !(
            featuresBounds &&
            Object.keys(featuresBounds).length === 0 &&
            featuresBounds.constructor === Object
          )
        ) {
          map.fitBounds(featuresBounds);
        }
      }}
    >
      <TileLayer
        attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
      />
      <GeoJSON
        key={JSON.stringify(props.geoJsonData)}
        onEachFeature={onEachField}
        style={countryStyle}
        data={props.geoJsonData}
      />
    </MapContainer>
  );
};
