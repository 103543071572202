import React, { FunctionComponent } from 'react';

import styles from './ErrorModal.module.scss';

interface ErrorModalProps {
  errorMessage: string | null;
}

export const ErrorModal: FunctionComponent<ErrorModalProps> = ({
  errorMessage,
}) => {
  return (
    <div className={[styles.modalContent].join(' ')}>
      <div className={styles.modalTitle}>{errorMessage}</div>
    </div>
  );
};
