import request from "./request";
import { ITimelineService } from "./services-types";

export class TimelineService implements ITimelineService {
  getByMemberId = async (memberId: number): Promise<Timeline> => {
    const timeline = await request({
      method: "get",
      url: `/timelines/${memberId}`,
      baseURL: process.env.REACT_APP_NEW_API_URL,
    });
    return timeline;
  };
}
