import React, { useContext, useState } from "react";
import { FunctionComponent } from "react";
import { isMsgError } from "../../../lib/error";
import { ProductionMessagesService } from "../../../services/messages-service";
import { ServicesContext } from "../../../services/service-providers/service-provider";
import { FieldSummaryWithExtraDetails } from "../../../types/fields";
import { Button } from "../../Button/Button";
import GauchoAppSelection from "../GauchoAppSelection/GauchoAppSelection";
import "./AddReport.scss";

type Props = {
  rodeos: Rodeo[];
  fields: FieldSummaryWithExtraDetails[];
};
const AddReport: FunctionComponent<Props> = ({ rodeos, fields }) => {
  const [selectedField, setSelectedField] = useState(0);
  const [selectedRodeo, setSelectedRodeo] = useState(0);
  const [selectedOption, setSelectedOption] = useState(1);
  const [report, setReport] = useState("");

  const { messageService } = useContext(ServicesContext);

  const onChangeField = (e: any) => {
    setSelectedField(e.target.value);
  };
  const onChangeOption = (e: any) => {
    setSelectedOption(e.target.value);
    setSelectedField(0);
    setSelectedRodeo(0);
  };
  const onChangeRodeo = (e: any) => {
    setSelectedRodeo(e.target.value);
  };

  const handleChange = (e: any) => {
    setReport(e.target.value);
  };

  const saveReport = async () => {
    if ((selectedField != 0 || selectedRodeo != 0) && report.length > 0) {
      try {
        const message = await messageService.createMessage(
          selectedRodeo,
          selectedField,
          report
        );
      } catch (error) {
        if (isMsgError(error)) console.log(error);
      }
    }
  };

  return (
    <div>
      <div>
        <p>Cargar informe</p>
      </div>
      <form onSubmit={saveReport}>
        <div>
          <GauchoAppSelection
            options={[
              { id: 1, name: "Potrero" },
              { id: 2, name: "Rodeo" },
            ]}
            disabled={false}
            label={"Seleccion"}
            selectId={selectedOption}
            onChange={onChangeOption}
          />
          <GauchoAppSelection
            options={fields}
            label={"Nº Potrero"}
            disabled={selectedOption != 1}
            selectId={selectedField}
            onChange={onChangeField}
          />
          <GauchoAppSelection
            options={rodeos}
            label={"Nº Rodeo"}
            disabled={selectedOption != 2}
            selectId={selectedRodeo}
            onChange={onChangeRodeo}
          />
          <textarea
            className="gauchoApp_addReport_textarea"
            onChange={handleChange}
            placeholder={"Ingrese el informe"}
          ></textarea>
          <Button className="gauchoApp_button">Crear Informe</Button>
        </div>
      </form>
    </div>
  );
};

export default AddReport;
