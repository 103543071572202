import request from "./request";
import { IFilesService } from "./services-types";

export class ProductionFilesService implements IFilesService {
  savePicture = async (fileEntry: Blob, fileName: string): Promise<void> => {
    const formData = new FormData();
    formData.append("file", fileEntry, fileName);

    return await request({
      method: "post",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      url: `/api/files/uploadfile/`,
    });
  };
}
