import moment from "moment";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
} from "chart.js";
import { RouteComponentProps } from "react-router";
import { Loader } from "../../../components/Loader/Loader";
import { MemberEvent } from "../../../lib/utils/member-event.utils";
import { useServices } from "../../../services/service-providers/service-provider";
import withLoaderHOC, {
  WithLoaderProps,
} from "../../../utils/hocs/WithLoaderHOC";
import { Chart } from "../../../lib/utils/chart";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

type MemberGraphPageState = {
  member: MemberWithDetails | null;
  memberEvents: Array<MemberEvent> | null;
  pedometerEvents: Array<MemberEvent> | null;
  contactWithDrinkerEvents: Array<MemberEvent> | null;
};

interface MatchParams {
  id: string;
  member_id: string;
  graph: string;
}

type Props = RouteComponentProps<MatchParams> & WithLoaderProps;

const MemberGraph: FunctionComponent<Props> = ({
  match,
  isLoading,
  setLoading,
}) => {
  const [state, setState] = useState<MemberGraphPageState>({
    member: null,
    memberEvents: null,
    pedometerEvents: null,
    contactWithDrinkerEvents: null,
  });

  const { member_id, graph } = match.params;
  const { membersService } = useServices();

  const getMemberById = async () => {
    setLoading(true);
    try {
      const member = await membersService.getMemberByIdV2(Number(member_id));
      const memberEvents = await membersService.getMemberEventsDisk(member_id);

      const pedometer = MemberEvent.getByType(
        memberEvents,
        "PEDOMETER_BY_ACC"
      ).sort(MemberEvent.sortByTs);

      const pedometerEvents: MemberEvent[] = Object.values(
        pedometer.reduce((a, c) => {
          const startOfDay = moment.unix(c.ts).startOf("day").unix();
          a[startOfDay] = a[startOfDay] || {
            ts: startOfDay,
            value: c.value,
          };
          a[startOfDay].value = c.value;

          return a;
        }, Object.create(null))
      );

      const contactWithDrinker = MemberEvent.getByType(
        memberEvents,
        "RSSI_ACCURACY_DETECTED"
      ).sort(MemberEvent.sortByTs);

      const contactWithDrinkerEvents: MemberEvent[] = Object.values(
        contactWithDrinker.reduce((a, c) => {
          const startOfDay = moment.unix(c.ts).startOf("day").unix();
          a[startOfDay] = a[startOfDay] || { ts: startOfDay, value: 0 };
          if (c.value === "IMMEDIATE") {
            a[startOfDay].value++;
          }
          return a;
        }, Object.create(null))
      );
      console.log(
        "dates ",
        pedometer,
        Chart.getEventsDates(pedometerEvents).formated
      );
      setState({
        member,
        memberEvents,
        pedometerEvents,
        contactWithDrinkerEvents,
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMemberById();
  }, []);

  if (isLoading) {
    return (
      <div className="p-4">
        <Loader isLoading={isLoading}>
          <></>
        </Loader>
      </div>
    );
  }

  return (
    <main>
      {state.pedometerEvents &&
      state.pedometerEvents.length > 0 &&
      graph === "walking" ? (
        <Bar
          options={{ responsive: true, aspectRatio: 1 }}
          className="mt-4"
          data={{
            labels: Chart.getEventsDates(state.pedometerEvents).formated,
            datasets: [
              Chart.generateDatasetsFromLinearMemberEvents(
                state.pedometerEvents,
                "Activity Per Day"
              ),
            ],
          }}
        />
      ) : null}

      {graph === "drink" &&
      state.contactWithDrinkerEvents &&
      state.contactWithDrinkerEvents.length > 0 ? (
        <Bar
          options={{ responsive: true, aspectRatio: 1 }}
          className="mt-4"
          data={{
            labels: Chart.getEventsDates(state.contactWithDrinkerEvents)
              .formated,
            datasets: [
              Chart.generateDatasetsFromLinearMemberEvents(
                state.contactWithDrinkerEvents,
                "Contacts with drinker"
              ),
            ],
          }}
        />
      ) : null}
    </main>
  );
};

const MemberGraphPage = withLoaderHOC(MemberGraph);

export { MemberGraphPage };
