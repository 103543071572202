interface ApiError {
  response: {
    status: number;
    data: {
      detail: string;
    };
  };
}

export const isApiError = (x: any): x is ApiError => {
  try {
    return typeof x.response.status === "number";
  } catch {
    // not managed response, should log event in the future
    return false;
  }
};

export const isMsgError = (x: any): x is string => {
  return typeof x === "string";
};

export const genericError = () => "Something bad happened!";
