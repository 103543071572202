import React, { useState } from "react";
import { LoadingBackground } from "../../components/LoadingBackground/LoadingBackground";

const withIsLoading = (WrappedComponent: any) => {
  const HOC = (props: any) => {
    const [isLoading, setIsLoading] = useState(true);

    const setLoadingState = (isComponentLoading: boolean) => {
      setIsLoading(isComponentLoading);
    };

    return (
      <>
        {isLoading && <LoadingBackground />}
        <WrappedComponent {...props} setLoading={setLoadingState} />
      </>
    );
  };

  HOC.displayName = `withIsLoading(${WrappedComponent.name})`;

  return HOC;
};

export default withIsLoading;
