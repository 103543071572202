import React, { FunctionComponent } from "react";
import { Button } from "../../../../components/Button/Button";
import { User } from "../../../../types/auth";
import { Icon } from "../../../../components/Icon";
import { Table, TableColumn } from "../../../components/Table/Table";
import styles from "./users.module.scss";

const tableConstants = (): Array<TableColumn> => {
  return [
    {
      title: "Name",
      key: "name",
      render: (user: User) => {
        return (
          <div className="flex flex-col">
            <span className="text-sm font-medium text-gray-900">
              {user.full_name}
            </span>
            <span className="text-sm text-gray-500">{user.email}</span>
          </div>
        );
      },
    },
    {
      title: "Username",
      key: "username",
      render: (user: User) => {
        return (
          <div>
            <span className="text-sm text-gray-500">@{user.username}</span>
          </div>
        );
      },
    },
    {
      title: "Status",
      key: "status",
      render: (user: User) => {
        return (
          <span>
            {user.disabled ? (
              <span className="px-2 text-sm text-gray-500 font-semibold rounded-full bg-red-100 text-red-800">
                Inactive
              </span>
            ) : (
              <span className="px-2 text-sm text-gray-500 font-semibold rounded-full bg-green-100 text-green-800">
                Active
              </span>
            )}
            {user.tel_number && user.tel_country_code ? (
              <span className="px-2 ml-2 text-sm font-semibold rounded-full bg-blue-100 text-blue-800">
                SMS
              </span>
            ) : null}
          </span>
        );
      },
    },
    {
      title: "Role",
      key: "role",
      render: (user: User) => {
        return (
          <div className="flex justify-center">
            <Icon iconName={"app_user"} />
            <Icon
              className={
                user.role === "User"
                  ? styles.notEnabledIcon
                  : styles.enabledIcon
              }
              iconName={"dashboard_user"}
            />
          </div>
        );
      },
    },
  ];
};

type UsersPageProps = {
  title: string;
  users: Array<User>;
  onUserRowClicked: (index: number) => void;
  onChangeModalState: () => void;
};

const UsersPage: FunctionComponent<UsersPageProps> = ({
  title,
  users,
  onUserRowClicked,
  onChangeModalState,
}) => (
  <>
    <h2 className="mt-4 font-semibold">{title}</h2>
    <Table
      className="mt-2"
      isPaginationEnabled={false}
      onRowClicked={onUserRowClicked}
      columns={tableConstants()}
      items={users}
    />
    <div className={styles.buttonContainer}>
      <div className={styles.buttonSize}>
        <Button onClick={onChangeModalState}>Add New User</Button>
      </div>
    </div>
  </>
);

export default UsersPage;
