import React, { FunctionComponent, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Button } from "../../components/Button/Button";
import { HeaderHomePage } from "../../components/HeaderHomePage/HeaderHomePage";
import { useServices } from "../../services/service-providers/service-provider";
import { RootState } from "../../store/store";
import withLoaderHOC, { WithLoaderProps } from "../../utils/hocs/WithLoaderHOC";
import { Alert } from "../../types/alerts";
import { OldMemberCount } from "../../types/members";
import { Loader } from "../../components/Loader/Loader";
import { FieldAlertsListElement } from "../../components/FieldAlertsListElement/FieldAlertsListElement";
import { WithTranslation, withTranslation } from "react-i18next";
import { isMsgError } from "../../lib/error";
import NANDI_URL from "../../lib/url";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../store/hooks/redux-hooks";
import { FieldSummaryWithExtraDetails } from "../../types/fields";
import PaddockSummaryCard from "../../components/PaddockSummaryCard";
import SelectAccountContainer from "./SelectAccountContainer";

type HomePageState = {
  totalMembersCount: Array<OldMemberCount>;
  alerts: Array<Alert>;
  fields: Array<FieldSummaryWithExtraDetails>;
};

type Props = WithLoaderProps & WithTranslation & PropsFromRedux;

const HomePage: FunctionComponent<Props> = ({
  user,
  isLoading,

  t,
  setLoading,
  setError,
}) => {
  const { selectedAccount } = useAppSelector((s) => s.accounts);
  const [state, setState] = useState<HomePageState>({
    totalMembersCount: [],
    alerts: [],
    fields: [],
  });

  const { membersService, alertsService, fieldsService } = useServices();

  console.log(user);

  const getData = async () => {
    try {
      const [members, alerts, fields] = await Promise.all([
        membersService.getMembersSummary(),
        alertsService.getAlertsV2({
          account_id: String(user?.account_id),
        }),
        fieldsService.getFields(String(selectedAccount.id)),
      ]);
      setState({
        ...state,
        totalMembersCount: members,
        alerts,
        fields,
      });
      setLoading(false);
    } catch (error) {
      if (isMsgError(error)) setError(error);
      console.log(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    getData();
  }, []);

  return (
    <>
      <HeaderHomePage
        username={user?.full_name}
        membersCount={state.totalMembersCount}
      />
      <div className={"mt-4 pb-[66px]"}>
        <Loader isLoading={isLoading}>
          <div className={"font-semibold pl-4 pr-4"}>
            {t("homepage.newAlertsTitle")}
          </div>
          <div>
            {state.alerts.map((alert) => (
              <FieldAlertsListElement
                alert={alert}
                key={alert.id}
                link={`${NANDI_URL.APP.ALERTS}/${alert.id}`}
              />
            ))}
          </div>
          <div className={"pl-[25%] pr-[25%] mt-2 w-full"}>
            <Link to={NANDI_URL.APP.ALERTS}>
              <Button>{t("homepage.allAlertsButton")}</Button>
            </Link>
          </div>
          <div className="mt-4 grid gap-4 pl-2 pr-2">
            {state.fields.map((field) => (
              <Link key={field.id} to={`${NANDI_URL.APP.MONITOR}/${field.id}`}>
                <PaddockSummaryCard field={field} />
              </Link>
            ))}
          </div>
        </Loader>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    user: state.users.user,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedHomePage = connector(withLoaderHOC(HomePage));

export default withTranslation()(ConnectedHomePage);
