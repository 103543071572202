import React, { FunctionComponent } from "react";

import * as Icons from "../../assets";

type IconProps = {
  iconName: string;
  className?: string;
  // All other props
  [x: string]: any;
};

export const Icon: FunctionComponent<IconProps> = ({
  className,
  iconName,
  ...rest
}) => {
  switch (iconName) {
    case "account_circle":
      return <Icons.AccountCircle className={className} />;
    case "account_box":
      return <Icons.AccountBoxIcon className={className} />;
    case "alerts":
      return <Icons.AlertsIcon className={className} />;
    case "alerts-v2":
      return <Icons.AlertsIconV2 className={className} />;
    case "activity-alerts":
      return <Icons.ActivityAlertIcon className={className} />;
    case "connectivity-alerts":
      return <Icons.ConnectivityAlertIcon className={className} />;
    case "alerts-v2":
      return <Icons.AlertsIconV2 className={className} />;
    case "alerts_footer":
      return <Icons.AlertsFooterIcon className={className} />;
    case "app_user":
      return <Icons.AppUserIcon className={className} />;
    case "user_icon":
      return <Icons.UserIcon className={className} />;
    case "area":
      return <Icons.AreaIcon className={className} />;
    case "arrow_back":
      return <Icons.ArrowBackIcon className={className} />;
    case "arrow_right":
      return <Icons.ArrowRightIcon className={className} />;
    case "edit":
      return <Icons.EditIcon className={className} />;
    case "renewed":
      return <Icons.RenewedIcon className={className} />;
    case "add":
      return <Icons.AddIcon className={className} />;
    case "forbidden":
      return <Icons.ForbiddenIcon className={className} />;
    case "arrow_down":
      return <Icons.ArrowDownIcon className={className} />;
    case "arrow_forward":
      return <Icons.ArrowForwardIcon className={className} />;
    case "bull":
      return <Icons.BullIcon className={className} />;
    case "inventory-bull":
      return <Icons.InventoryBullIcon className={className} />;
    case "contact":
      return <Icons.ContactIcon className={className} />;
    case "cow":
      return <Icons.CowIcon className={className} />;
    case "inventory-cow":
      return <Icons.InventoryCowIcon className={className} />;
    case "chevron-left":
      return <Icons.ChevronLeft className={className} {...rest} />;
    case "chevron-right":
      return <Icons.ChevronRight className={className} {...rest} />;
    case "dashboard_user":
      return <Icons.DashboardUserIcon className={className} />;
    case "delete":
      return <Icons.DeleteIcon className={className} />;
    case "drink":
      return <Icons.DrinkIcon className={className} />;
    case "field":
      return <Icons.AreaIcon className={className} />;
    case "home":
      return <Icons.HomeIcon className={className} />;
    case "logo":
      return <Icons.Logo className={className} />;
    case "login_logo":
      return <Icons.LogInLogoIcon className={className} />;
    case "admin_logo":
      return <Icons.AdminLogoIcon className={className} />;
    case "login_logo_v2":
      return <Icons.LogInLogoIconV2 className={className} />;
    case "logout":
      return <Icons.LogoutIcon className={className} />;
    case "map":
      return <Icons.MapIcon className={className} />;
    case "menu":
      return <Icons.MenuIcon className={className} />;
    case "monitor":
      return <Icons.MonitorIcon className={className} />;
    case "problem":
      return <Icons.ProblemIcon className={className} />;
    case "time":
      return <Icons.TimeIcon className={className} />;
    case "search":
      return <Icons.SearchIcon className={className} />;
    case "sun":
      return <Icons.SunIcon className={className} />;
    case "e_tag_id":
      return <Icons.ETagIdIcon className={className} />;
    case "walking":
      return <Icons.WalkingIcon className={className} />;
    case "drinker":
      return <Icons.DrinkIcon className={className} />;
    case "people":
      return <Icons.PeopleIcon className={className} />;
    case "save":
      return <Icons.SaveIcon className={className} />;
    case "sensor":
      return <Icons.SensorIcon className={className} />;
    case "bar_chart":
      return <Icons.BarChartIcon className={className} />;
    case "vaccine":
      return <Icons.Vaccine className={className} />;
    case "weight":
      return <Icons.Weight className={className} />;
    case "medical":
      return <Icons.MedicalIcon className={className} />;
    case "settings":
      return <Icons.SettingsIcon className={className} />;
    case "position":
      return <Icons.PositionIcon className={className} />;
    case "header_gauchoapp":
      return <Icons.GauchoAppHeaderIcon className={className} />;
    case "home_gauchoapp":
      return <Icons.GauchoAppHomeIcon className={className} />;
    case "calendar":
      return <Icons.CalendarIcon className={className} />;
    default:
      return null;
  }
};
