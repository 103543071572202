import classNames from "classnames";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Icon } from "../../Icon";

import styles from "./FooterLink.module.scss";

interface FooterLinkProps {
  linkName: string;
  linkIcon: string;
  linkTo: string;
  className?: string;
}

export const FooterLink: FunctionComponent<FooterLinkProps> = ({
  className,
  linkIcon,
  linkTo,
  linkName,
}) => {
  const { t } = useTranslation();
  return (
    <NavLink
      className={classNames(styles.footerLinkContainer, className)}
      activeClassName={styles.active}
      to={linkTo}
    >
      <div className={styles.footerLinkIcon}>
        <Icon iconName={linkIcon} />
      </div>
      <div className={styles.footerLinkName}>{t(`footer.${linkName}`)}</div>
    </NavLink>
  );
};
