import React, { FunctionComponent, useState } from "react";
import { Icon } from "../Icon";
import styles from "./InputSearch.module.scss";

interface InputProps {
  className?: string;
  color: string;
  value: string;
  alwaysOpen: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const InputSearch: FunctionComponent<InputProps> = (props) => {
  const [expandContainer, setExpandContainer] = useState(props.alwaysOpen);
  let colorStyle = "";
  switch (props.color) {
    case "fieldsSearch":
      colorStyle = styles.fieldsSearchColor;
      break;
    case "sensorsSearch":
      colorStyle = styles.sensorsSearchColor;
      break;
    case "mapSearch":
      colorStyle = styles.mapSearchColor;
      break;
    default:
      colorStyle = styles.fieldsSearchColor;
      break;
  }

  return (
    <div
      className={[
        styles.inputContainer,
        colorStyle,
        expandContainer ? styles.expandedInputContainer : "",
      ].join(" ")}
    >
      <div className={styles.iconContainer}>
        <Icon iconName={"search"} />
      </div>
      <input
        className={styles.inputSearch}
        value={props.value}
        onChange={props.onChange}
        onFocus={() => setExpandContainer(true)}
        onBlur={() => (!props.alwaysOpen ? setExpandContainer(false) : {})}
      />
    </div>
  );
};
