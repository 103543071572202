import React from "react";
import { FunctionComponent } from "react";
import { Icon } from "../../Icon";
import "./HeaderGauchoApp.scss";

type HeaderProps = {};

export const HeaderGauchoApp: FunctionComponent<HeaderProps> = ({}) => {
  return (
    <div className="headerGauchoApp_container">
      <Icon iconName={"header_gauchoapp"} className={"headerGauchoApp_icon"} />
    </div>
  );
};
