import { Alert, AlertEvent, AlertsRunResult } from "../types/alerts";
import request from "./request";
import { IAlertsService } from "./services-types";

export class ProductionAlertsService implements IAlertsService {
  getAlerts = async (
    filter: string,
    account_id?: string
  ): Promise<Array<Alert>> => {
    const alerts: Array<Alert> = await request({
      method: "get",
      url: "/api/alerts",
      params: {
        alerts_type: filter,
        include_deleted: false,
        account_id: account_id,
      },
    }).then((jsonAlerts) => {
      return jsonAlerts.map((jsonAlert: any) => new Alert(jsonAlert));
    });
    return alerts;
  };

  getAlertsV2 = async ({
    account_id,
    field_id,
    alert_type,
    current_status,
    skip,
    take
  }: {
    account_id?: string;
    field_id?: string;
    alert_type?: string;
    current_status?: string[];
    skip?: string;
    take?: string;
  }): Promise<Array<Alert>> => {
    return await request({
      method: "get",
      url: "/alerts",
      baseURL: process.env.REACT_APP_NEW_API_URL,
      params: {
        account_id,
        field_id,
        alert_type,
        current_status,
        skip,
        take
      },
    });
  };

 getAlertsCount = async ({
  account_id,
  field_id,
  alert_type,
  current_status,
}: {
  account_id?: string;
  field_id?: string;
  alert_type?: string;
  current_status?: string[];
}): Promise<{count:number}> => {
  const totalAlerts = await request({
    method: "get",
    url: `/alerts/count`,
    baseURL: process.env.REACT_APP_NEW_API_URL,
    params: {
      account_id,
      field_id,
      alert_type,
      current_status
    },
  });
  return totalAlerts;
}

  getAlertById = async (alertId: string): Promise<Alert> => {
    const alert = await request({
      method: "get",
      url: `/alerts/${alertId}`,
      baseURL: process.env.REACT_APP_NEW_API_URL,
    });
    return alert;
  };

  getAlertByIdEvents = async (alertId: string): Promise<Array<AlertEvent>> => {
    const alertEvents = await request({
      method: "get",
      url: `/alerts/${alertId}/events`,
      baseURL: process.env.REACT_APP_NEW_API_URL,
    });
    return alertEvents;
  };

  updateAlert = async (
    alertId: string,
    readStatus: boolean
  ): Promise<Alert> => {
    const alert = request({
      method: "post",
      url: `/api/alerts/update`,
      data: {
        alert_id: alertId,
        read_status: readStatus,
      },
    }).then((jsonAlert) => new Alert(jsonAlert));
    return alert;
  };

  deleteAlert = async (alertId: string): Promise<void> => {
    await request({
      method: "post",
      url: `/api/alerts/delete`,
      params: {
        alert_id: alertId,
      },
    });
  };

  closeAlert = async (alertId: string): Promise<Alert> => {
    const alert = await request({
      method: "PUT",
      url: `/alerts/${alertId}/close`,
      baseURL: process.env.REACT_APP_NEW_API_URL,
    });
    return alert;
  };

  getAlertsRunResult = async (
    accountId: string
  ): Promise<Array<AlertsRunResult>> => {
    const alertsRunResult = await request({
      method: "get",
      url: `/alerts/run-result/${accountId}`,
      baseURL: process.env.REACT_APP_NEW_API_URL,
    });
    return alertsRunResult;
  };
}
