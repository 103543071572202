import React, { FunctionComponent, ReactNode, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../store/hooks/redux-hooks";
import { accountsActions } from "../../store/slices/accounts-slice";
import { RootState } from "../../store/store";
import { Icon } from "../Icon";
import { NavLink, useHistory } from "react-router-dom";
import { User, UserRole } from "../../types/auth";
import NANDI_URL from "../../lib/url";
import { useTranslation } from "react-i18next";
import { Select } from "../shadcn/Select";
import { UserDropdown } from "./UserDropdown";

type TopNavbarLink = {
  label: ReactNode;
  iconName: string;
  href: string;
  key: string;
};

interface TopNavbarProps {
  links: Array<TopNavbarLink>;
  user: User | null;
  onLogout: () => void;
}

type SelectOption = {
  value: any;
  displayValue: string;
};

const TopNavbar: FunctionComponent<TopNavbarProps> = ({
  links,
  onLogout,
  user,
}) => {
  const [accounts, selectedAccount] = useAppSelector((state: RootState) => {
    return [state.accounts.accounts, state.accounts.selectedAccount];
  });

  const notSuperUserOptions = (t: any) => [
    {
      value: NANDI_URL.ADMIN.MAIN,
      displayValue: accounts[0].name,
    },
    {
      value: NANDI_URL.ADMIN.ACCOUNTS,
      displayValue: t("admin.sideBar.accounts"),
    },
    {
      value: NANDI_URL.ADMIN.USERS,
      displayValue: t("admin.sideBar.users"),
    },
    {
      value: NANDI_URL.ADMIN.USER,
      displayValue: t("admin.sideBar.user"),
    },
  ];
  // state for select in case the user role is not superuser
  const [selectedPage, setSelectedPage] = useState<SelectOption | undefined>(
    undefined
  );

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const onChangeSelectedOption = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const account = accounts.find(
      (account) => account.id === Number.parseInt(event.target.value)
    );
    if (account) {
      dispatch(accountsActions.setSelectedAccount(account));
      localStorage.setItem("account_id", event.target.value);
    }
  };

  const onChangeSelectedPage = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedPage(
      notSuperUserOptions(t).filter((x) => x.value === event.target.value)[0]
    );
    history.push(event.target.value);
  };

  const isSuperUser = user?.role === UserRole.SUPERUSER;
  return (
    <nav className="bg-white px-2 pb-2 flex items-center mt-2 justify-between drop-shadow-md max-h-16">
      <NavLink
        to="/admin"
        className="flex rounded p-2 items-center hover:text-pink-700 cursor-pointer"
        exact
        activeClassName="text-pink-900"
      >
        <Icon
          className="w-full h-full fill-current max-w-[120px]"
          iconName="admin_logo"
        />
        {isSuperUser ? (
          <span className="font-semibold p-1 mx-4 text-xs rounded bg-purple-700 text-white">
            {t("admin.sideBar.superUser")}
          </span>
        ) : null}
      </NavLink>
      <div className="flex items-center gap-[1.75rem]">
        {links.map((link) => {
          if (link.key !== "DASHBOARD" || isSuperUser) {
            return (
              <div className="flex items-center gap-2" key={link.key}>
                <NavLink
                  className={
                    "text-gray-900 flex font-medium flex-col items-center hover:text-pink-700 cursor-pointer px-4 py-2 hover:bg-[#F4F4F5] rounded-md"
                  }
                  activeClassName="bg-[#F4F4F5] text-pink-700"
                  to={link.href}
                >
                  <span>{link.label}</span>
                </NavLink>
              </div>
            );
          }
        })}
      </div>
      <div className="flex items-center">
        {isSuperUser &&
        accounts &&
        accounts.map((account) => ({
          value: account.id,
          displayValue: account.name,
        })).length > 0 ? (
          <div className="mr-5">
            <Select
              iconType="chevron-up-down"
              selectedOption={{
                displayValue: selectedAccount?.name,
                value: selectedAccount?.id,
              }}
              onChange={onChangeSelectedOption}
              options={accounts.map((account) => ({
                value: account.id,
                displayValue: account.name,
              }))}
            />
          </div>
        ) : null}
        {!isSuperUser ? (
          <div className="mr-5">
            <Select
              selectedOption={
                selectedPage ?? {
                  displayValue: selectedAccount?.name,
                  value: selectedAccount?.id,
                }
              }
              onChange={onChangeSelectedPage}
              options={notSuperUserOptions(t)}
            />
          </div>
        ) : null}
        <UserDropdown onLogout={onLogout} user={user} />
      </div>
    </nav>
  );
};

export default TopNavbar;
