import React, { FunctionComponent, useEffect, useState } from "react";
import { InputSearch } from "../../../components/InputSearch/InputSearch";
import { MembersListElement } from "../../../components/MembersListElement/MembersListElement";

import styles from "./MembersByTypePage.module.scss";
import {
  parseMemberType,
  sortMembersAlphabetically,
  sortMembersByAlert,
} from "../../../types/members";
import withLoaderHOC, {
  WithLoaderProps,
} from "../../../utils/hocs/WithLoaderHOC";
import { Icon } from "../../../components/Icon";
import SelectItemModal from "../../../components/SelectItemModal";
import { Loader } from "../../../components/Loader/Loader";
import { useServices } from "../../../services/service-providers/service-provider";
import { getMembersTypeName } from "../../../types/members";
import { WithTranslation, withTranslation } from "react-i18next";
import { isMsgError } from "../../../lib/error";
import NANDI_URL from "../../../lib/url";
import { Link } from "react-router-dom";

type MembersByTypePageState = {
  searchFieldValue: string;
  displayModal: boolean;
  filter: Array<Item>;
  selectedFilter: number;
  members: Array<Member>;
  sortMethods: Array<Item>;
  selectedSortMethod: number;
  modalSelected: number;
};

export type Item = {
  id: number;
  name: string;
  action: (members: Array<Member>) => Array<Member>;
};

type Propy = {
  memberEntityType: string;
  fieldId: string;
};

type Props = Propy & WithTranslation & WithLoaderProps;

const initialState: MembersByTypePageState = {
  searchFieldValue: "",
  displayModal: false,
  selectedFilter: 1,
  members: [],
  filter: [
    {
      id: 1,
      name: "All",
      action: (members: Array<Member>) => {
        return members;
      },
    },
    {
      id: 2,
      name: "Only alerts",
      action: (members: Array<Member>) => {
        return members.filter((member) => member.status === "ALERT");
      },
    },
  ],
  sortMethods: [
    {
      id: 1,
      name: "A-Z",
      action: (members: Array<Member>) => {
        return members.sort(sortMembersAlphabetically);
      },
    },
    {
      id: 2,
      name: "Alerts",
      action: (members: Array<Member>) => {
        return members.sort(sortMembersByAlert);
      },
    },
  ],
  selectedSortMethod: 1,
  modalSelected: 1,
};

const MembersByTypePage: FunctionComponent<Props> = ({
  fieldId,
  memberEntityType,
  t,
  isLoading,
  setLoading,
  setError,
}) => {
  const [state, setState] = useState(initialState);
  const { fieldsService, membersService } = useServices();

  const getMembersByType = async () => {
    const memberType: MemberType = parseMemberType(memberEntityType);
    try {
      const data = !isNaN(parseInt(fieldId))
        ? await fieldsService.getMembersByFieldIdAndMemberType(
            fieldId,
            memberType
          )
        : await membersService.getMembers([
            { key: "member_type", value: memberType },
          ]);
      setState({
        ...state,
        members: data.filter(x=>x.status !== "ARCHIV"),
      });
      setLoading(false);
    } catch (error) {
      if (isMsgError(error)) setError(error);
      console.log(error);
    }
  };

  useEffect(() => {
    getMembersByType();
  }, []);

  const onInputSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      searchFieldValue: event.target.value,
    });
  };

  const changeModalState = (modalType: number): void => {
    setState({
      ...state,
      modalSelected: modalType,
      displayModal: !state.displayModal,
    });
  };

  const changeSelectedFilter = (filterId: number): void => {
    setState({
      ...state,
      selectedFilter: filterId,
    });
  };

  const changeSelectedSortMethod = (sortMethodId: number): void => {
    setState({
      ...state,
      selectedSortMethod: sortMethodId,
    });
  };

  const getFilteredAndSortedMembers = (): Array<Member> => {
    const filteredMembers = state.filter
      .find((filter) => filter.id === state.selectedFilter)
      ?.action(state.members);

    const filteredAndSortedMembers = state.sortMethods
      .find((sortMethod) => sortMethod.id === state.selectedSortMethod)
      ?.action(filteredMembers!!);

    return filteredAndSortedMembers!!;
  };

  return (
    <>
      {state.modalSelected === 1 ? (
        <SelectItemModal
          displayModal={state.displayModal}
          items={state.filter}
          selectedItem={state.selectedFilter}
          onAction={changeSelectedFilter}
          onClose={changeModalState}
        />
      ) : (
        <SelectItemModal
          displayModal={state.displayModal}
          items={state.sortMethods}
          selectedItem={state.selectedSortMethod}
          onAction={changeSelectedSortMethod}
          onClose={changeModalState}
        />
      )}
      <div>
        <div className={styles.sensorsCountContainer}>
          {memberEntityType && memberEntityType !== "all"
            ? t(
                `entityTypes.${getMembersTypeName(
                  memberEntityType.toUpperCase()
                )}`
              )
            : t("entityTypes.All")}{" "}
          {!isLoading ? <b>{state.members.length}</b> : ""}
        </div>
        <div className={styles.searchBoxContainer}>
          <InputSearch
            color="sensorsSearch"
            alwaysOpen={true}
            value={state.searchFieldValue}
            onChange={onInputSearchChange}
          />
        </div>
        <div className={styles.sensorsListContainer}>
          <div className={styles.filtersContainer}>
            <div onClick={() => changeModalState(1)} className={styles.filter}>
              {t(
                `modalFilter.${
                  state.filter.find(
                    (filter) => filter.id === state.selectedFilter
                  )?.name
                }`
              )}
              <Icon iconName={"arrow_forward"} />
            </div>
            <div onClick={() => changeModalState(2)} className={styles.filter}>
              {t("general.sort")}
              <Icon iconName={"arrow_forward"} />
            </div>
          </div>
          <div className={styles.sensorsList}>
            <Loader isLoading={isLoading}>
              {state.members
                ? getFilteredAndSortedMembers()
                    .filter((member) =>
                      member.tag_id?.includes(state.searchFieldValue)
                    )
                    .map((member) => (
                      <Link
                        to={NANDI_URL.APP.GENERATE_MEMBER_BY_ID(
                          String(member.id)
                        )}
                        key={member.id}
                      >
                        <MembersListElement member={member} />
                      </Link>
                    ))
                : null}
            </Loader>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(withLoaderHOC(MembersByTypePage));
