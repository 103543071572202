import React, { FunctionComponent } from "react";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";

import NANDI_URL from "./lib/url";

// components
import { Footer } from "./components/Footer/Footer";

// pages
import AlertsPage from "./pages/AlertsPage/AlertsPage";
import HomePage from "./pages/HomePage/HomePage";
import AllMembersPage from "./pages/AllMembersPage/AllMembersPage";
import FieldPage from "./pages/FieldPage/FieldPage";
import MapPage from "./pages/MapPage/MapPage";
import MonitorPage from "./pages/MonitorPage/MonitorPage";
import LogOutPage from "./pages/AuthPage/LogOutPage";
import SettingsPage from "./pages/SettingsPage/SettingsPage";
import GauchoApp from "./pages/GauchoApp/GauchoApp";

const App: FunctionComponent = () => {
  return (
    <HashRouter>
      <Switch>
        <Route path={NANDI_URL.APP.HOME} exact component={HomePage} />
        <Route path={NANDI_URL.APP.SETTINGS} exact component={SettingsPage} />
        <Route path={NANDI_URL.APP.ALERTS} component={AlertsPage} />
        <Route path={NANDI_URL.APP.MEMBERS} component={AllMembersPage} />
        <Route path={NANDI_URL.APP.MONITOR} exact component={MonitorPage} />
        <Route path={NANDI_URL.APP.MONITOR_BY_ID} component={FieldPage} />
        <Route path={NANDI_URL.APP.MAP} exact component={MapPage} />
        <Route path={NANDI_URL.LOGOUT} exact component={LogOutPage} />
        <Route path={NANDI_URL.APP.GAUCHOAPP} exact component={GauchoApp} />
        <Redirect from={NANDI_URL.MAIN} to={NANDI_URL.APP.HOME} />
      </Switch>
      <Footer />
    </HashRouter>
  );
};

export default App;
