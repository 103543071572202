import React, { useState } from "react";

type SwitchDemoProps = {
  initialState?: boolean;
  onChange?: (checked: boolean) => void;
};

export default function Switch({
  initialState = false,
  onChange,
}: SwitchDemoProps) {
  const [isChecked, setIsChecked] = useState<boolean>(initialState);

  const handleToggle = () => {
    const newState = !isChecked;
    setIsChecked(newState);
    if (onChange) {
      onChange(newState);
    }
  };

  return (
    <button
      role="switch"
      aria-checked={isChecked}
      onClick={handleToggle}
      className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:opacity-80 ${
        isChecked ? "bg-primary" : "bg-gray-200"
      }`}
    >
      <span
        className={`inline-block h-5 w-5 transform rounded-full bg-white transition-transform ${
          isChecked ? "translate-x-6" : "translate-x-1"
        }`}
      />
    </button>
  );
}
