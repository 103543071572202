import React, {
  ChangeEvent,
  FormEvent,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { connect, ConnectedProps } from "react-redux";
import { Loader } from "../../../components/Loader/Loader";
import { ServicesContext } from "../../../services/service-providers/service-provider";
import { isMsgError } from "../../../lib/error";
// Context
import { RootState } from "../../../store/store";
import withLoaderHOC, {
  WithLoaderProps,
} from "../../../utils/hocs/WithLoaderHOC";
import InventoryPage from ".";
import { FieldSummary } from "../../../types/fields";
import { set } from "js-cookie";

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = {} & PropsFromRedux;

const PageContainer: FunctionComponent<WithLoaderProps & Props> = ({
  isLoading,
  setLoading,
  setError,
  selectedAccount,
}) => {
  const initInventory: Inventory = {
    fieldId: 0,
    accountId: Number(selectedAccount.id),
    memberType: "",
    quantity: 0,
  };
  const { fieldsService } = useContext(ServicesContext);
  const [fields, setFields] = useState<Array<FieldSummary>>([]);
  const [inventory, setInventory] = useState<Inventory>(initInventory);
  const [tableData, setTableData] = useState<Array<GetInventory>>([]);

  const handleOnchange = (
    e: ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const name = e.target.name;

    setInventory({
      ...inventory,
      [name]:
        (e.target.name === "quantity" && e.target.value !== "") ||
        e.target.name === "fieldId"
          ? Number(e.target.value)
          : e.target.value,
    });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    await fieldsService
      .createFieldInventory(inventory)
      .then(async (fields) => {
        setLoading(false);
        setInventory(initInventory);
        getFieldsInventory();
      })
      .catch((err) => setLoading(false));
  };

  const getFields = async () => {
    setLoading(true);
    await fieldsService
      .getFieldsList(false, String(selectedAccount.id))
      .then((fields) => {
        setFields(fields);
        setLoading(false);
      })
      .catch((err) => {
        if (isMsgError(err)) setError(err);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getFieldsInventory = async () => {
    setLoading(true);
    await fieldsService
      .getFieldsInventory(String(selectedAccount.id))
      .then((fieldsInventory) => {
        setTableData(fieldsInventory);
        setLoading(false);
      })
      .catch((err) => {
        if (isMsgError(err)) setError(err);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getFields();
    getFieldsInventory();
  }, [selectedAccount.id]);

  return (
    <Loader isLoading={isLoading}>
      <InventoryPage
        fields={fields}
        onChange={handleOnchange}
        inventory={inventory}
        onSubmit={handleSubmit}
        tableData={tableData}
      />
    </Loader>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    selectedAccount: state.accounts.selectedAccount,
    user: state.users.user,
  };
};

const connector = connect(mapStateToProps);

const InventoryPageContainer = connector(withLoaderHOC(PageContainer));

export { InventoryPageContainer };
