import React, { FunctionComponent } from "react";
import { LoaderSpin, LoaderSpinWithChildren } from "../LoaderSpin/LoaderSpin";

interface LoaderProps {
  children: any;
  isLoading: boolean;
}

export const Loader: FunctionComponent<LoaderProps> = (props) =>
  props.isLoading ? <LoaderSpin /> : props.children;

export const LoaderWithRender: FunctionComponent<LoaderProps> = (props) =>
  props.isLoading ? <LoaderSpinWithChildren /> : props.children;
