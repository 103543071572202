import React, { useState } from "react";
import { FunctionComponent } from "react";
import { FieldSummaryWithExtraDetails } from "../../types/fields";
import { Button } from "../Button/Button";
import FieldSelection from "./GauchoAppSelection/GauchoAppSelection";
import "./GauchoApp.scss";
import AddReport from "./AddReport/AddReport";
import moment from "moment";
import { useTranslation } from "react-i18next";

type Props = {
  name: string;
  rodeos: Rodeo[];
  fields: FieldSummaryWithExtraDetails[];
  action: string;
};

const GauchoAppContainer: FunctionComponent<Props> = ({
  name,
  rodeos,
  fields,
  action,
}) => {
  const [selectedField, setSelectedField] = useState(fields[0].id);
  const { t } = useTranslation();
  const onChange = (e: any) => {
    setSelectedField(e.target.value);
  };
  const currentMonth = moment()
    .subtract("month")
    .startOf("month")
    .format("MMMM");

  return (
    <>
      <div className="gauchoApp_container">
        {action == "download" && (
          <>
            <div>
              <p>Hola, {name}</p>
            </div>
            <div className="guachoApp_fieldSelection_section">
              <FieldSelection
                options={fields}
                selectId={selectedField}
                onChange={onChange}
                label={"Nº Potrero"}
                disabled={false}
              />
              <Button className="gauchoApp_button">
                Descargar hoja de{" "}
                {t("general.months." + currentMonth.toLowerCase())}
              </Button>
            </div>
          </>
        )}
        {action == "upload" && <AddReport rodeos={rodeos} fields={fields} />}
      </div>
    </>
  );
};

export default GauchoAppContainer;
