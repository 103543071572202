import React, { useEffect } from "react";
import { useDevTools } from "../../store/devToolsStore";

const DevToolsPage = () => {
  const onHandleDevTools = () => {
    const accessTokenType = localStorage.getItem("token_type");
    const accessToken = localStorage.getItem("access_token");
    const authHeader = `${accessTokenType} ${accessToken}`;

    console.log({ accessToken, accessTokenType, authHeader });
  };

  const { isDebug, setIsDebug } = useDevTools();

  const handleSetIsDebug = () => {
    setIsDebug((isDebug: any) => !isDebug);
  };

  useEffect(() => {
    if (window) {
      (window as any).setIsDebug = handleSetIsDebug;
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isDebug) return null;

  return (
    <div
      onClick={onHandleDevTools}
      className={
        "absolute border p-4 rounded bg-gray-100 text-white-100 bottom-[4rem] right-[1rem]"
      }
    >
      <p>Debug</p>
    </div>
  );
};

export default DevToolsPage;
