import React, { FunctionComponent } from "react";

import nandi_logo from "../../assets/nandi_logo.svg";
import styles from "./Header.module.scss";

const Header: FunctionComponent = ({ children }) => (
  <header className={styles.header}>
    <div className={styles.logoContainer}>
      <img src={nandi_logo} className={styles.imgHeader} alt="logo_icon" />
    </div>
    <div className={styles.areaName}>{children}</div>
  </header>
);

export default Header;
