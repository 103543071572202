import React, { FunctionComponent } from "react";

import styles from "./HeaderExpanded.module.scss";
import nandi_logo from "../../assets/nandi_logo.svg";
import { InputSearch } from "../InputSearch/InputSearch";

interface HeaderProps {
  searchFieldValue: string;
  onInputSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const HeaderExpanded: FunctionComponent<HeaderProps> = ({
  searchFieldValue,
  onInputSearchChange,
}) => {
  return (
    <div className={styles.header}>
      <div>
        <img src={nandi_logo} className={styles.imgHeader} alt="logo_icon" />
      </div>
      <div className={styles.expandableHeader}>
        <div className={styles.searchBar}>
          <InputSearch
            alwaysOpen={false}
            color="mapSearch"
            value={searchFieldValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              onInputSearchChange(event)
            }
          />
        </div>
      </div>
    </div>
  );
};
