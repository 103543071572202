import React, { FunctionComponent } from "react";

import { Icon } from "../Icon";
import { MemberInfo } from "./MemberInfo/MemberInfo";
import { FieldSummaryWithExtraDetails } from "../../types/fields";
import classNames from "classnames";

interface PaddockSummaryCardProps {
  className?: string;
  field: FieldSummaryWithExtraDetails;
  onClick?: () => void;
}

const PaddockSummaryCard: FunctionComponent<PaddockSummaryCardProps> = ({
  children,
  className,
  field,
  onClick,
}) => {
  const isFieldGeneralStatusAlert = field.members.some(
    (member) => member.status === "ALERT"
  );

  return (
    <div
      onClick={onClick}
      className={classNames(
        className,
        "cursor-pointer p-2 bg-[#f8f8f8] rounded-lg flex flex-col"
      )}
    >
      <section className="flex">
        <div className="flex items-center w-[20px]">
          <div
            className={classNames("h-[13px] w-[13px] rounded-full", {
              "bg-pink-600": isFieldGeneralStatusAlert,
              "bg-green-500": !isFieldGeneralStatusAlert,
            })}
          />
        </div>
        <div className="w-full flex items-center">
          <div className="w-full">
            <p>{field.name}</p>
          </div>
          <div className="w-[fit-content] grid grid-cols-3 gap-4">
            {field.membersByGroup.map((memberByGroup) => (
              <MemberInfo
                key={memberByGroup.memberType}
                memberCount={memberByGroup}
              />
            ))}
          </div>
          <div>{field.dimensions} ha</div>
          <div className="flex items-center">
            <Icon
              className="fill-current text-black"
              iconName="arrow_forward"
            />
          </div>
        </div>
      </section>
      {children ? <section>{children}</section> : null}
    </div>
  );
};

export default PaddockSummaryCard;
