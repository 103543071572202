import classNames from "classnames";
import React, { FunctionComponent, MouseEventHandler } from "react";

import styles from "./Button.module.scss";
import { LoaderSpin } from "../LoaderSpin/LoaderSpin";

interface ButtonProps {
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
  theme?: "blue" | "red";
  isLoading?: boolean;
}

export const Button: FunctionComponent<ButtonProps> = ({
  disabled,
  className,
  onClick,
  children,
  theme = "blue",
  isLoading = false,
}) => (
  <button
    disabled={disabled || isLoading}
    className={classNames(
      theme === "blue" ? "bg-gradient-to-r from-blue-800 to-blue-900" : null,
      theme === "red" ? "bg-red-500" : null,
      styles.button,
      className,
      "relative"
    )}
    onClick={onClick}
  >
    {isLoading ? (
      <div className="relative top-[-35px]">
        <LoaderSpin />
      </div>
    ) : (
      children
    )}
  </button>
);
