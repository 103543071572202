import { Validation } from "../types/form-types";
const checkValidity = (value: string, rules: Validation) => {
  let isValid = true;
  if (!Object.keys(rules).length) {
    return true;
  }

  if (!rules.required && value === "") {
    return true;
  }

  if (rules.required) {
    isValid = value.trim() !== "" && isValid;
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isEmail) {
    isValid = emailIsValid(value) && isValid;
  }

  if (rules.isPassword) {
    isValid = passwordIsValid(value) && isValid;
  }

  return isValid;
};

const emailIsValid = (email: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

const passwordIsValid = (password: string) => {
  const mediumRegex = new RegExp(
    "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})"
  );
  return mediumRegex.test(password);
};
export { checkValidity };
