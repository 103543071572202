import React, { useState } from "react";
import { AlertsPageModal } from "../../components/AlertsPageModal/AlertsPageModal";

export type WithModalProps = {
  setModalDisplayState: (displayState: boolean) => void;
  setConfirmModalAction: (action: () => void) => void;
};

type ModalState = {
  isOpen: boolean;
  confirmAction: { (): void } | null;
};

const withModalHOC = (WrappedComponent: any) => {
  const HOC = (props: any) => {
    const [modalState, setModalState] = useState<ModalState>({
      isOpen: false,
      confirmAction: null,
    });

    const setModalDisplayState = (openModal: boolean) => {
      setModalState({
        ...modalState,
        isOpen: openModal,
      });
    };

    const setConfirmModalAction = (action: () => void) => {
      setModalState({
        ...modalState,
        confirmAction: action,
      });
    };

    return (
      <>
        <AlertsPageModal
          displayModal={modalState.isOpen}
          onClose={() => setModalDisplayState(false)}
          onDelete={modalState.confirmAction}
        />
        <WrappedComponent
          {...props}
          setConfirmModalAction={setConfirmModalAction}
          setModalDisplayState={setModalDisplayState}
        />
      </>
    );
  };

  HOC.displayName = `withModal(${WrappedComponent.name})`;

  return HOC;
};
export default withModalHOC;
