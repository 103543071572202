import React, { FunctionComponent } from "react";
import { PageTitle } from "../../../components/PageTitle/PageTitle.component";
import { useFields } from "../../../context/fields";
import { NDVIMultiChart } from "../../pages/paddock/layout/ndvi-multi-chart";

const ReportsPage: FunctionComponent = () => {
  const {
    state: { fields },
  } = useFields();
  return (
    <div className="w-full p-2">
      <PageTitle>Reports</PageTitle>
      {fields && fields.some((f) => f.ndvi_tile && f.ndvi_tile != "") ? (
        <section className="col-span-4">
          <PageTitle>NDVI Chart</PageTitle>
          <NDVIMultiChart
            paddocks={fields
              .filter((f) => f.ndvi_tile && f.ndvi_tile != "")
              .map((f) => ({ paddockName: f.name, polygonId: f.ndvi_tile }))}
          />
        </section>
      ) : null}
    </div>
  );
};

export default ReportsPage;
