import React, { FunctionComponent, FocusEvent } from "react";
import styles from "./Input.module.scss";

interface InputProps {
  className?: string;
  elementType: string;
  elementConfig: ElementConfig;
  value: any;
  invalid?: boolean;
  touched?: boolean;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement & HTMLSelectElement>
  ) => void;
  onBlur?: (e: FocusEvent) => void;
}

interface ElementConfig {
  type: string;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  options?: Array<any>;
}

export type SelectOption = {
  value: any;
  displayValue: string;
};

export const Input: FunctionComponent<InputProps> = (props) => {
  let finalClassName = `${styles.input} ${props.className}`;
  let inputElementClass = `${styles.inputElement}`;
  if (props.invalid) {
    inputElementClass += ` ${styles.invalid}`;
  }

  let inputElement;
  switch (props.elementType) {
    case "checkbox":
      inputElement = (
        <div className={styles.checkboxElement}>
          <input
            className={""}
            {...props.elementConfig}
            checked={props.value}
            onChange={props.onChange}
            onBlur={props.onBlur}
            id={props.elementConfig.label}
          />
          {props.elementConfig.placeholder}
        </div>
      );
      break;
    case "input":
      inputElement = (
        <input
          className={inputElementClass}
          {...props.elementConfig}
          value={props.value}
          onChange={props.onChange}
          onBlur={props.onBlur}
          id={props.elementConfig.label}
        />
      );
      break;
    case "select":
      inputElement = (
        <select
          className={inputElementClass}
          value={props.value}
          onChange={props.onChange}
        >
          {props.elementConfig.options!!.map((option: SelectOption) => (
            <option key={option.value} value={option.value}>
              {option.displayValue}
            </option>
          ))}
        </select>
      );
      break;
  }

  return <div className={finalClassName}>{inputElement}</div>;
};
