import React, { FunctionComponent, ReactNode } from "react";

import { Icon } from "../Icon";
import { useTranslation } from "react-i18next";

type FieldDetailsCardProps = {
  className: string;
  memberInteraction: {
    type: string;
    count: ReactNode;
  };
};

export const FieldDetailsCard: FunctionComponent<FieldDetailsCardProps> = ({
  className,
  memberInteraction,
}) => {
  const { t } = useTranslation();

  const finalStyle = `flex justify-evenly rounded p-4 items-center bg-gray-100 ${className}`;

  return (
    <div className={finalStyle}>
      <Icon className="w-[24px]" iconName={memberInteraction.type} />
      <div className="ml-4 w-full">
        <div className="font-semibold text-blue-700">
          {t(`memberInteractionTypes.${memberInteraction.type}`)}
        </div>
        <div>{memberInteraction.count}</div>
      </div>
      <Icon
        className="w-[30px] h-[13px] fill-current	text-color-blue"
        iconName="arrow_forward"
      />
    </div>
  );
};
