import React, { FC, useEffect, useState } from "react";

import { Image } from "../../../../../components/Image/Image";
import { useServices } from "../../../../../services/service-providers/service-provider";
import classNames from "classnames";

const MemberProfilePicture: FC<{
  handleProfileImageOnClick: () => void;
  member: Member;
  className?: string;
  profilePicture: string | undefined;
}> = ({ member, handleProfileImageOnClick, className, profilePicture }) => {


  return (
    <>
      {profilePicture ? (
        <div
          onClick={handleProfileImageOnClick}
          className={classNames(
            "w-[190px] h-[190px] m-auto",
            className
          )}
        >
          <Image
            className="w-full h-full rounded-full"
            imageLink={profilePicture}
            alt={member.tag_id}
          />
        </div>
      ) : null}
    </>
  );
};

export default MemberProfilePicture;
