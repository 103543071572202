import moment from "moment";
import React, { FunctionComponent } from "react";
import { ArrowForwardIcon } from "../../../../../assets";
import { Button } from "../../../../../components/Button/Button";
import { TableColumn } from "../../../../components/Table/Table";

const Title: FunctionComponent = ({ children }) => (
  <h2 className="font-bold text-center">{children}</h2>
);

const Main: FunctionComponent<{
  displayButtons?: boolean;
  onAdd?: () => void;
  onClose?: () => void;
}> = ({ children, displayButtons = true, onAdd, onClose }) => {
  return (
    <div className="p-12 bg-white relative">
      {children}
      {displayButtons ? (
        <section className="m-auto mt-4 items-center grid grid-cols-2 gap-4 max-w-[50%] min-w-[200px]">
          <Button className="mt-4" onClick={onClose}>
            Cerrar
          </Button>
          <Button className="mt-4" onClick={onAdd}>
            Agregar
          </Button>
        </section>
      ) : null}
    </div>
  );
};

const Attribute: FunctionComponent<{
  label: React.ReactNode;
  value: React.ReactNode;
}> = ({ label, value }) => (
  <div>
    <p>
      <span className="font-semibold">{label}</span>: {value}
    </p>
  </div>
);

const vaccinationTableConstants = (): Array<TableColumn> => {
  return [
    {
      title: "Date of Application",
      key: "date",
      render: (item: MemberEvent) => {
        return <span>{moment.unix(item.ts).format("DD/MM/YYYY")}</span>;
      },
    },
    {
      title: "Vaccine",
      key: "data",
      render: (item: MemberEvent) => {
        return <span>{item.value}</span>;
      },
    },
    {
      title: "",
      key: "go",
      render: () => {
        return (
          <span>
            <ArrowForwardIcon className="w-[24px] h-[24px] fill-current text-gray-300 m-auto text-center" />
          </span>
        );
      },
    },
  ];
};

const weightTableConstants = (): Array<TableColumn> => {
  return [
    {
      title: "Date",
      key: "date",
      render: (item: MemberEvent) => {
        return <span>{moment.unix(item.ts).format("DD/MM/YYYY")}</span>;
      },
    },
    {
      title: "Weight",
      key: "data",
      render: (item: MemberEvent) => {
        return <span>{item.value}</span>;
      },
    },
    {
      title: "",
      key: "go",
      render: () => {
        return (
          <span>
            <ArrowForwardIcon className="w-[24px] h-[24px] fill-current text-gray-300 m-auto text-center" />
          </span>
        );
      },
    },
  ];
};

const paddocksTableConstants = (): Array<TableColumn> => {
  return [
    {
      title: "Date",
      key: "date",
      render: (item: MemberEvent) => {
        return <span>{moment.unix(item.ts).format("DD/MM/YYYY")}</span>;
      },
    },
    {
      title: "Paddock",
      key: "paddock",
      render: (item: MemberEvent) => {
        return <span>{item.value}</span>;
      },
    },
  ];
};

const sensorPairingTableConstants = (): Array<TableColumn> => {
  return [
    {
      title: "Date",
      key: "date",
      render: (item: MemberEvent) => {
        return <span>{moment.unix(item.ts).format("DD/MM/YYYY")}</span>;
      },
    },
    {
      title: "Sensor",
      key: "sensor",
      render: (item: MemberEvent) => {
        return <span>{item.value}</span>;
      },
    },
  ];
};

const caravanaHistoryTableConstants = (): Array<TableColumn> => {
  return [
    {
      title: "Date",
      key: "date",
      render: (item: MemberEvent) => {
        return <span>{moment.unix(item.ts).format("DD/MM/YYYY")}</span>;
      },
    },
    {
      title: "Caravana",
      key: "caravana",
      render: (item: MemberEvent) => {
        return <span>{item.value}</span>;
      },
    },
  ];
};

const pregnancyTableConstants = (): Array<TableColumn> => {
  return [
    {
      title: "Date",
      key: "date",
      render: (item: MemberEvent) => {
        return <span>{moment.unix(item.ts).format("DD/MM/YYYY")}</span>;
      },
    },
    {
      title: "Resultado",
      key: "result",
      render: (item: MemberEvent) => {
        return <span>{item.value}</span>;
      },
    },
    {
      title: "",
      key: "go",
      render: () => {
        return (
          <span>
            <ArrowForwardIcon className="w-[24px] h-[24px] fill-current text-gray-300 m-auto text-center" />
          </span>
        );
      },
    },
  ];
};

export {
  Title,
  Main,
  Attribute,
  vaccinationTableConstants,
  weightTableConstants,
  paddocksTableConstants,
  sensorPairingTableConstants,
  pregnancyTableConstants,
  caravanaHistoryTableConstants,
};
