import React, { FunctionComponent } from "react";
import { Loader } from "../../../../components/Loader/Loader";
import { BatteryStatus } from "../../../components/BatteryStatus/BatteryStatus";
import { Table, TableColumn } from "../../../components/Table/Table";
import { useTranslation } from "react-i18next";
import { Icon } from "../../../../components/Icon";

type MainSensorsPageProps = {
  isLoading: boolean;
  sensors: Array<Sensor>;
  onChangeFilter: () => void;
  onDelete: (id: number, name: string) => void;
};

const MainSensorsPage: FunctionComponent<MainSensorsPageProps> = ({
  sensors,
  isLoading,
  onChangeFilter,
  onDelete,
}) => {
  const { t } = useTranslation();

  const tableConstants = (): Array<TableColumn> => {
    return [
      {
        title: "BTN ID",
        key: "id",
        render: (sensor: Sensor) => {
          return (
            <div className="flex gap-9 items-center">
              <div
                className="bg-red-500 p-2 rounded-full cursor-pointer"
                onClick={() => onDelete(Number(sensor.id), sensor.name)}
              >
                <Icon
                  iconName="delete"
                  className="fill-current self-end block text-black group-hover:text-[#586A84] w-5 h-5"
                />
              </div>
              <span>{sensor.name}</span>
            </div>
          );
        },
      },
      {
        title: t("admin.sensors.sensorType"),
        key: "sensor_type",
        render: (sensor: Sensor) => {
          return <span>{sensor.sensor_type}</span>;
        },
      },
      {
        title: t("admin.sensors.status"),
        key: "status",
        render: (sensor: Sensor) => {
          return <span>{sensor.status}</span>;
        },
      },
      {
        title: t("admin.sensors.battery"),
        key: "battery",
        render: (sensor: Sensor) => {
          return (
            <BatteryStatus key={sensor.status} percentage={sensor.battery} />
          );
        },
      },
    ];
  };

  return (
    <>
      <Loader isLoading={isLoading}>
        <Table
          className="mt-4"
          onChangeFilter={onChangeFilter}
          columns={tableConstants()}
          items={sensors}
        />
      </Loader>
    </>
  );
};

export default MainSensorsPage;
