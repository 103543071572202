import classNames from "classnames";
import React, { FunctionComponent } from "react";

type BatteryStatusProps = {
  percentage: number;
};

const getBatteryColor = (percentage: number) => {
  if (percentage < 30) {
    return "bg-red-400";
  } else if (percentage < 60) {
    return "bg-yellow-400";
  } else {
    return "bg-green-400";
  }
};

export const BatteryStatus: FunctionComponent<BatteryStatusProps> = ({
  percentage,
}) => {
  return (
    <div
      style={{ width: `${percentage}%` }}
      className={classNames(getBatteryColor(percentage))}
    >
      {`${percentage}%`}
    </div>
  );
};
