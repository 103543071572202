import { useEffect, useState } from "react";
import { User, UserRole } from "../../types/auth";

type FeaturesMap = {
  [key in Features]: { default: boolean; result: boolean };
};

export enum Features {
  SENSORS_PAGE = "SENSORS_PAGE",
  SENSORS_UPLOAD = "SENSORS_UPLOAD",
  ACCOUNTS_UPLOAD = "ACCOUNTS_UPLOAD",
  ACCOUNTS_PAGE = "ACCOUNTS_PAGE",
  REPORTS_PAGE = "REPORTS_PAGE",
  USERS_PAGE = "USERS_PAGE",
  ACCOUNT_ATTRIBUTES_EDIT = "ACCOUNT_ATTRIBUTES_EDIT",
  ACCOUNT_ATTRIBUTES = "ACCOUNT_ATTRIBUTES",
  INSIGHTS_PAGE = "INSIGHTS_PAGE",
}

const features: FeaturesMap = {
  SENSORS_PAGE: { default: false, result: false },
  REPORTS_PAGE: { default: false, result: false },
  ACCOUNTS_PAGE: { default: false, result: false },
  ACCOUNTS_UPLOAD: { default: false, result: false },
  SENSORS_UPLOAD: { default: true, result: true },
  USERS_PAGE: { default: false, result: false },
  ACCOUNT_ATTRIBUTES_EDIT: { default: false, result: false },
  ACCOUNT_ATTRIBUTES: { default: false, result: false },
  INSIGHTS_PAGE: { default: false, result: false },
};

const useFeatureFlag = (user?: User | null) => {
  const [state, setState] = useState<{ features: FeaturesMap }>({ features });

  useEffect(() => {
    setState((prevState) => {
      let newFeatures: FeaturesMap = features;
      for (var key in features) {
        // toggle everything according to the user and defaults
        newFeatures[key as Features] = {
          ...prevState.features[key as Features],
          result: isUserAllowed(key as Features, user),
        };
      }
      return {
        features: newFeatures,
      };
    });
  }, []);

  const evaluateFeatureFlag = (key?: Features) => {
    if (!key) {
      return true;
    } else {
      return state.features[key].result;
    }
  };

  return { evaluateFeatureFlag };
};

const USER_FEATURE: { [feature in Features]: { [role in UserRole]: boolean } } =
  {
    SENSORS_PAGE: {
      Anon: false,
      User: false,
      Admin: false,
      Superuser: true,
    },
    SENSORS_UPLOAD: {
      Anon: false,
      User: false,
      Admin: false,
      Superuser: true,
    },
    ACCOUNT_ATTRIBUTES_EDIT: {
      Anon: false,
      User: false,
      Admin: false,
      Superuser: true,
    },
    ACCOUNT_ATTRIBUTES: {
      Anon: false,
      User: false,
      Admin: true,
      Superuser: true,
    },
    ACCOUNTS_PAGE: {
      Anon: false,
      User: false,
      Admin: true,
      Superuser: true,
    },
    ACCOUNTS_UPLOAD: {
      Anon: false,
      User: false,
      Admin: false,
      Superuser: true,
    },
    REPORTS_PAGE: {
      Anon: false,
      User: false,
      Admin: true,
      Superuser: true,
    },
    USERS_PAGE: {
      Anon: false,
      User: false,
      Admin: true,
      Superuser: true,
    },
    INSIGHTS_PAGE: {
      Anon: false,
      User: false,
      Admin: true,
      Superuser: true,
    },
  };

const isUserAllowed = (feature: Features, user?: User | null) => {
  return USER_FEATURE[feature][user ? user.role : UserRole.ANON];
};

export { useFeatureFlag, isUserAllowed };
