import React, {
  Reducer,
  createContext,
  FunctionComponent,
  useReducer,
} from "react";
// Types
import { FieldSummaryWithExtraDetails } from "../../types/fields";

type State = {
  fields: Array<FieldSummaryWithExtraDetails> | null;
  isLoading: boolean;
};
type Action =
  | { type: "request" }
  | { type: "success"; fields: Array<FieldSummaryWithExtraDetails> }
  | { type: "failure"; error: string };
type Dispatch = (action: Action) => void;

const FieldsContext = createContext<
  | {
      state: State;
      dispatch: Dispatch;
    }
  | undefined
>(undefined);

const fieldsReducer: Reducer<State, Action> = (
  state: State,
  action: Action
): State => {
  switch (action.type) {
    case "request": {
      return { fields: null, isLoading: true };
    }
    case "success": {
      return { fields: action.fields, isLoading: false };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const fieldsInitialState = {
  fields: null,
  isLoading: false,
};

export const FieldsProvider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(fieldsReducer, fieldsInitialState);
  const value = { state, dispatch };

  return (
    <FieldsContext.Provider value={value}>{children}</FieldsContext.Provider>
  );
};

export const useFields = () => {
  const context = React.useContext(FieldsContext);
  if (context === undefined) {
    throw new Error("useFields must be used within a FieldsProvider");
  }
  return context;
};
