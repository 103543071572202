import React, { FunctionComponent, useContext, useState } from "react";

const DevToolsContext = React.createContext<
  | {
      isDebug: boolean;
      setIsDebug: any;
    }
  | undefined
>(undefined);

const DevToolsProvider: FunctionComponent = ({ children }) => {
  const [isDebug, setIsDebug] = useState(false);

  const value = { isDebug, setIsDebug };

  return (
    <DevToolsContext.Provider value={value}>
      {children}
    </DevToolsContext.Provider>
  );
};

const useDevTools = () => {
  const context = useContext(DevToolsContext);

  if (context === undefined) {
    throw new Error("using DevToolsContext outside of provider");
  }

  return context;
};

export { DevToolsProvider, useDevTools };
