import React, {
  FunctionComponent,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { useHistory } from "react-router-dom";
import update from "immutability-helper";

import { Button } from "../../../components/Button/Button";
import { Icon } from "../../../components/Icon";
import { Input } from "../../../components/Input/Input";
import { Loader } from "../../../components/Loader/Loader";
import { FAB } from "../../../molecules/button";
import { Link } from "../../../molecules/link";
import { ServicesContext } from "../../../services/service-providers/service-provider";
import { RootState } from "../../../store/store";
import {
  createGeoJsonDataFromCoordinates,
  extractGoogleCoords,
  FieldCreate,
  Property,
} from "../../../types/fields";
import { FormField } from "../../../types/form-types";
import withLoaderHOC, {
  WithLoaderProps,
} from "../../../utils/hocs/WithLoaderHOC";
import { checkValidity } from "../../../utils/utils";
import { PaddocksMap } from "../../components/PaddocksMap/PaddocksMap";
import { AccountLayout } from "../../pages/account";
import { NewAccountLayout } from "../../pages/account/new_account";
import { Account, AccountAttribute } from "../../types/Account";

import styles from "./AccountsPage.module.scss";
import { Features, isUserAllowed } from "../../../lib/feature-flag";
import NANDI_URL from "../../../lib/url";
import { useAppDispatch } from "../../../store/hooks/redux-hooks";
import { accountsActions } from "../../../store/slices/accounts-slice";
import { isMsgError } from "../../../lib/error";
import * as XLSX from "xlsx";
import moment from "moment";

type AccountPageState = {
  account: Account | null;
  selectedAccountAttributes: Array<AccountAttribute>;
  isEdit: boolean;
  rows: Array<MemberEventCreate>;
};

type AccountsPageState = AccountPageState & {
  geoJsonData: GeoJSON.FeatureCollection<GeoJSON.Polygon, Property>;
  account_name_field: FormField;
  account_map_field: FormField;
  file?: string;
};

type Props = WithLoaderProps &
  PropsFromRedux & { accountId: string; signUpFlow: true };

const initialState = (): AccountsPageState => {
  return {
    account: null,
    selectedAccountAttributes: [],
    rows: [],
    isEdit: false,
    geoJsonData: {
      type: "FeatureCollection",
      features: [],
    },
    account_name_field: {
      id: 1,
      elementType: "input",
      elementConfig: {
        type: "text",
        placeholder: "Account Name*",
      },
      value: "",
      validation: {
        required: true,
        message: "Field required",
      },
      valid: false,
      touched: false,
    },
    account_map_field: {
      id: 2,
      elementType: "input",
      elementConfig: {
        type: "text",
        placeholder: "Account Map",
      },
      value: "",
      validation: {
        required: true,
        message: "Field required",
      },
      valid: false,
      touched: false,
    },
  };
};

const AccountsPage: FunctionComponent<Props> = ({
  user,
  selectedAccount,
  isLoading,
  setLoading,
  setError,
}) => {
  const { accountsService, fieldsService, membersService } =
    useContext(ServicesContext);
  const history = useHistory();
  const { t } = useTranslation();
  const [state, setState] = useState<AccountsPageState>(initialState());
  const [displayCreate, setDisplayCreate] = useState(false);

  const fileHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      parseDocument(event.target.files!![0]);
    }
  };

  const parseDocument = (file: File) => {
    const fileReader = new FileReader();
    fileReader.onload = async (e: any) => {
      const result = await extractGoogleCoords(e.target.result);
      setState({
        ...state,
        geoJsonData: createGeoJsonDataFromCoordinates(result),
      });
    };
    fileReader.onabort = (ev) => {
      console.log(ev);
    };
    fileReader.readAsText(file);
    fileReader.onerror = (ex) => {
      console.log(ex);
    };
  };

  const onTouchEventHandler = (event: React.FocusEvent<Element>) => {
    const updatedAccountName = {
      ...state.account_name_field,
      valid: checkValidity(
        state.account_name_field.value,
        state.account_name_field.validation
      ),
      touched: true,
    };

    const updatedAccountMap = {
      ...state.account_map_field,
      valid: checkValidity(
        state.account_map_field.value,
        state.account_map_field.validation
      ),
      touched: true,
    };
    setState({
      ...state,
      account_name_field: updatedAccountName,
      account_map_field: updatedAccountMap,
    });
  };

  const inputChangedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedForm = {
      ...state.account_name_field,
      value: event.target.value,
      valid: checkValidity(
        event.target.value,
        state.account_name_field.validation
      ),
      touched: true,
    };
    setState({ ...state, account_name_field: updatedForm });
  };

  const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      setLoading(true);
      const createAccount = {
        name: state.account_name_field.value,
      };
      const newAccount = await accountsService.createAccount(createAccount);
      const addedAccountInformationPromise = [];
      if (state.geoJsonData.features.length > 0) {
        const createFieldsArray: Array<FieldCreate> =
          state.geoJsonData.features.map((feature) => ({
            name: feature.properties.name,
            account_id: newAccount.id,
            outlines: feature.geometry.coordinates[0].map(
              (position, index) => ({
                order: index,
                latitude: position[0],
                longitude: position[1],
              })
            ),
          }));
        addedAccountInformationPromise.push(
          fieldsService.createAccountFields(createFieldsArray)
        );
      }

      Promise.all(addedAccountInformationPromise).then((r) => {
        history.push(
          `${NANDI_URL.ADMIN.GENERATE_ACCOUNT_BY_ID(String(newAccount.id))}`
        );
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const isFormInvalid =
    !state.account_name_field.valid || state.geoJsonData.features.length === 0;

  const onClickHandleDisplay = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setDisplayCreate(true);
  };

  const loadPageInfo = async () => {
    try {
      setLoading(true);
      const accountAttributes = await accountsService.getAccountAttributesById(
        selectedAccount.id
      );
      setState({ ...state, selectedAccountAttributes: accountAttributes });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadPageInfo();
  }, [selectedAccount]);

  const decorateLat = (key: string, val: string): ReactNode =>
    key === "ranch_location" && val !== "" ? (
      <Link target="_blank" href={`https://www.google.com/maps/place/${val}`}>
        {t("admin.accountsPage.attributes.ranch_location_link")}
      </Link>
    ) : null;

  const onChangeHandleAttribute = (e: React.ChangeEvent<HTMLInputElement>) => {
    var attributeIndex = state.selectedAccountAttributes.findIndex((attr) => {
      return attr.attribute_name === e.target.name;
    });
    const updatedAttribute = update(
      state.selectedAccountAttributes[attributeIndex],
      {
        attribute_value: { $set: e.target.value },
      }
    );

    const newState = update(state, {
      selectedAccountAttributes: {
        $splice: [[attributeIndex, 1, updatedAttribute]],
      },
    });

    setState(newState);
  };

  const onClickToggleEdit = () => {
    const newState = update(state, {
      isEdit: {
        $set: !state.isEdit,
      },
    });
    setState(newState);
  };

  const handleSaveAttributes = async () => {
    setLoading(true);
    const accountAttributes = await accountsService.updateAccountAttributesById(
      selectedAccount.id,
      state.selectedAccountAttributes
    );
    setState({
      ...state,
      selectedAccountAttributes: accountAttributes,
      isEdit: false,
    });
    setLoading(false);
  };
  const csvHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files!![0];
    var reader = new FileReader();

    reader.onload = (e: any) => {
      var data = e.target.result;
      // there's a bug in here with parsing stuff with leading zeros
      var workbook = XLSX.read(data, {
        type: "binary",
        cellDates: true,
        dateNF: "yyyy-mm-dd",
      });

      workbook.SheetNames.forEach((sheetName) => {
        const json_object = XLSX.utils.sheet_to_json(
          workbook.Sheets[sheetName]
        );

        const memberEvents = json_object.map((x: any) => {
          let result: MemberEventCreate | undefined;

          if (x?.hasOwnProperty("weight")) {
            result = {
              value: x.weight ?? "",
              ts: x.date
                ? String(moment(x.date, "DD/MM/YYYY").utcOffset(-180).unix())
                : "",
              tag_id: x.caravana ? String(x.caravana) : "",
              type: "WEIGHT",
            };
          }
          if (x?.hasOwnProperty("vaccine")) {
            result = {
              value: x.vaccine ?? "",
              ts: x.date ? String(moment(x.date).utcOffset(-180).unix()) : "",
              tag_id: x.caravana ? String(x.caravana) : "",
              type: "VACCINE",
            };
          }
          if (x?.hasOwnProperty("result")) {
            result = {
              value: x.result ?? "",
              ts: x.date ? String(moment(x.date).utcOffset(-180).unix()) : "",
              tag_id: x.caravana ? String(x.caravana) : "",
              type: "PREGNANCY",
            };
          }
          return result;
        });

        setState((prevState) => ({
          ...prevState,
          rows: memberEvents as MemberEventCreate[],
        }));
      });
    };

    reader.onerror = (ex) => {
      console.log(ex);
    };

    reader.readAsBinaryString(file);
  };

  const handleCreateMemberEvents = async () => {
    setLoading(true);
    await membersService
      .createMemberEventsByMemberId(state.rows)
      .then((data) => {
        setLoading(false);
      })
      .catch((error) => {
        if (isMsgError(error)) setError(error);
        setLoading(false);
      });
  };

  if (!isUserAllowed(Features.ACCOUNTS_UPLOAD, user)) {
    return (
      <main className="p-4">
        <Loader isLoading={isLoading}>
          <AccountLayout
            isAllowedToEdit={isUserAllowed(
              Features.ACCOUNT_ATTRIBUTES_EDIT,
              user
            )}
            title={selectedAccount ? selectedAccount.name : "New Account"}
            subtitle={t("admin.accountsPage.applicantSubtitle")}
            accountAttributes={state.selectedAccountAttributes.map((a) => ({
              ...a,
              attribute_name_translation: t(
                `admin.accountsPage.attributes.${a.attribute_name}`
              ),
            }))}
            isEdit={state.isEdit}
            onChange={onChangeHandleAttribute}
            decorate={decorateLat}
            onClickToggleEdit={onClickToggleEdit}
            handleSaveAttributes={handleSaveAttributes}
          />
        </Loader>
      </main>
    );
  }

  return (
    <main className="p-4">
      <Loader isLoading={isLoading}>
        <div className="mb-4">
          <input
            type="file"
            onChange={csvHandler}
            accept=".xlsx,.csv"
            className="p-3"
          />
          <button
            onClick={handleCreateMemberEvents}
            className=" p-3 rounded-lg bg-violet-600 text-violet-100 font-semibold"
            disabled={state.rows.length === 0}
          >
            Upload member event
          </button>
        </div>
        {!displayCreate && selectedAccount ? (
          <AccountLayout
            title={selectedAccount ? selectedAccount.name : "New Account"}
            subtitle={t("admin.accountsPage.applicantSubtitle")}
            accountAttributes={state.selectedAccountAttributes.map((a) => ({
              ...a,
              attribute_name_translation: t(
                `admin.accountsPage.attributes.${a.attribute_name}`
              ),
            }))}
            isAllowedToEdit={isUserAllowed(
              Features.ACCOUNT_ATTRIBUTES_EDIT,
              user
            )}
            isEdit={state.isEdit}
            onChange={onChangeHandleAttribute}
            decorate={decorateLat}
            onClickToggleEdit={onClickToggleEdit}
            handleSaveAttributes={handleSaveAttributes}
          />
        ) : null}
        {displayCreate ? (
          <div className="flex flex-row">
            <div className={styles.formContainer}>
              <NewAccountLayout title="Create Account" />
              <form className="p-4" onSubmit={submitHandler}>
                <div className={styles.formInput}>
                  <Input
                    elementType={state.account_name_field.elementType}
                    elementConfig={state.account_name_field.elementConfig}
                    value={state.account_name_field.value}
                    invalid={
                      !state.account_name_field.valid &&
                      state.account_name_field.touched
                    }
                    touched={state.account_name_field.touched}
                    onChange={inputChangedHandler}
                    onBlur={onTouchEventHandler}
                  />
                  <div className={styles.inputValidationMessage}>
                    {!state.account_name_field.valid &&
                    state.account_name_field.touched
                      ? state.account_name_field.validation.message
                      : ""}
                  </div>
                </div>
                <input
                  type="file"
                  value={state.file}
                  onChange={fileHandler}
                  accept=".kml,.kmz"
                />
                <ul>
                  {state.geoJsonData.features.map((feature, index) => {
                    return (
                      <li className="block p-2 mt-2" key={index}>
                        <b>{feature.properties.name}</b>
                      </li>
                    );
                  })}
                </ul>
                <div className="mt-4">
                  <Button className="w-[40%]" disabled={isFormInvalid}>
                    Save Account
                  </Button>
                </div>
              </form>
            </div>
            <div className="w-1/2 min-h-screen">
              <PaddocksMap geoJsonData={state.geoJsonData} />
            </div>
          </div>
        ) : null}

        <section className="mt-4">
          <FAB
            type="button"
            onClick={!displayCreate ? onClickHandleDisplay : null}
          >
            <Icon className="fill-current" iconName="account_box" />
            <span>New Account</span>
          </FAB>
        </section>
      </Loader>
    </main>
  );
};

const AccountRenderPage: FunctionComponent<Props> = ({
  signUpFlow,
  accountId,
  user,
  isLoading,
  setLoading,
}) => {
  const dispatch = useAppDispatch();
  const { accountsService } = useContext(ServicesContext);
  const { t } = useTranslation();
  const [state, setState] = useState<AccountPageState>(initialState());

  const loadPageInfo = async () => {
    setLoading(true);
    const account = await accountsService.getAccountById(accountId);
    const selectedAccountAttributes =
      await accountsService.getAccountAttributesById(accountId);

    dispatch(accountsActions.setSelectedAccount(account));
    setState({ ...state, account, selectedAccountAttributes });
    setLoading(false);
  };

  useEffect(() => {
    loadPageInfo();
  }, [accountId]);

  const decorateLat = (key: string, val: string): ReactNode =>
    key === "ranch_location" && val !== "" ? (
      <Link target="_blank" href={`https://www.google.com/maps/place/${val}`}>
        {t("admin.accountsPage.attributes.ranch_location_link")}
      </Link>
    ) : null;

  const onChangeHandleAttribute = (e: React.ChangeEvent<HTMLInputElement>) => {
    var attributeIndex = state.selectedAccountAttributes.findIndex((attr) => {
      return attr.attribute_name === e.target.name;
    });
    const updatedAttribute = update(
      state.selectedAccountAttributes[attributeIndex],
      {
        attribute_value: { $set: e.target.value },
      }
    );

    const newState = update(state, {
      selectedAccountAttributes: {
        $splice: [[attributeIndex, 1, updatedAttribute]],
      },
    });

    setState(newState);
  };

  const onClickToggleEdit = () => {
    const newState = update(state, {
      isEdit: {
        $set: !state.isEdit,
      },
    });
    setState(newState);
  };

  const handleSaveAttributes = async () => {
    try {
      setLoading(true);
      const accountAttributes =
        await accountsService.updateAccountAttributesById(
          accountId,
          state.selectedAccountAttributes
        );
      setState({
        ...state,
        selectedAccountAttributes: accountAttributes,
        isEdit: false,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  if (!state.account) {
    return (
      <main className="p-4">
        <Loader isLoading={isLoading}>
          <div></div>
        </Loader>
      </main>
    );
  }

  return (
    <main className="p-4">
      <Loader isLoading={isLoading}>
        <AccountLayout
          isAllowedToEdit={isUserAllowed(
            Features.ACCOUNT_ATTRIBUTES_EDIT,
            user
          )}
          title={state.account.name}
          subtitle={t("admin.accountsPage.applicantSubtitle")}
          accountAttributes={state.selectedAccountAttributes.map((a) => ({
            ...a,
            attribute_name_translation: t(
              `admin.accountsPage.attributes.${a.attribute_name}`
            ),
          }))}
          isEdit={state.isEdit}
          onChange={onChangeHandleAttribute}
          decorate={decorateLat}
          onClickToggleEdit={onClickToggleEdit}
          handleSaveAttributes={handleSaveAttributes}
        />
      </Loader>
    </main>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    user: state.users.user,
    selectedAccount: state.accounts.selectedAccount,
  };
};

const mapDispatchToProps = () => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withLoaderHOC(AccountsPage));

const AccountPage = connector(withLoaderHOC(AccountRenderPage));

export { AccountPage };
