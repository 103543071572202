import React from "react";

type ImageProps = {
  className?: string;
  imageLink: string;
  alt?: string;
};

const Image = ({ className, imageLink, alt }: ImageProps) => (
  <img className={className} src={imageLink} alt={alt} />
);

const ImageWithPlaceholder = ({ alt, imageLink, className }: ImageProps) => {
  return (
    <div>
      <img alt={alt} className={className} src={imageLink} />
    </div>
  );
};

export { Image, ImageWithPlaceholder };
