import classNames from "classnames";
import moment from "moment";
import React, { FunctionComponent } from "react";

type Props = {
  agroStatsInfo: AgroStatsInfo;
  className?: string;
};

export const NDVICard: FunctionComponent<Props> = ({
  className,
  agroStatsInfo: { data, dt },
}) => (
  <section
    className={classNames(
      className,
      "col-span-1 p-4 rounded bg-[#27293d] text-white"
    )}
  >
    <h1>NDVI {moment.unix(dt).format("DD/MM/YYYY")}</h1>
    <ul className="mt-1 flex space-x-2">
      <li className="text-sm">max: {data.max.toFixed(2)}</li>
      <li className="text-sm">min: {data.min.toFixed(2)}</li>
      <li className="text-sm">mean: {data.mean.toFixed(2)}</li>
    </ul>
  </section>
);

type MapReferenceProps = {
  membersByStatus: {
    HEAT: Member[];
    ALERT: Member[];
    COWS: Member[];
    BULLS: Member[];
  };
};

export const MapReference: FunctionComponent<MapReferenceProps> = ({
  membersByStatus: { HEAT, ALERT, COWS, BULLS },
}) => (
  <section className="mt-4 bg-white p-1 rounded">
    <div className="flex flex-col w-full">
      <p className="flex items-center">
        <span className="block w-4 h-4 rounded-full bg-[#A52A2A]" />
        <span className="ml-2">
          {HEAT.length === 1 ? `1 cow in heat` : `${HEAT.length} cows in heat`}
        </span>
      </p>
      <p className="flex items-center">
        <span className="block w-4 h-4 rounded-full bg-[#E81579]" />
        <span className="ml-2">{`${ALERT.length} in alert`}</span>
      </p>
      <p className="flex items-center">
        <span className="block w-4 h-4 rounded-full bg-[#0000FF]" />
        <span className="ml-2">{`${BULLS.length} bulls`}</span>
      </p>
      <p className="flex items-center">
        <span className="block w-4 h-4 rounded-full bg-[#FFC0CB]" />
        <span className="ml-2">{`${COWS.length} cows`}</span>
      </p>
    </div>
  </section>
);

export const ReportSelection: FunctionComponent<{
  onChange: (e: any) => any;
  value: string;
}> = ({ value, onChange }) => (
  <select onChange={onChange} className="ml-1" value={value}>
    <option value={-1} disabled>
      Seleccionar Reporte
    </option>
    <option value="ndvi">Histórico NDVI</option>
    <option value="yearly-ndvi">NDVI Por Año</option>
  </select>
);
