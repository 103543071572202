import request from "./request";
import { IStorageService } from "./services-types";

export class ProductionStorageService implements IStorageService {
  get = async (path: string): Promise<string> => {
    return await request({
      method: "GET",
      url: `/storage/${path}`,
      baseURL: process.env.REACT_APP_NEW_API_URL,
    });
  };

  post = async (formData: FormData, memberId: number) => {
    return await request({
      method: "POST",
      url: `/storage?member_id=${memberId}`,
      data: formData,
      baseURL: process.env.REACT_APP_NEW_API_URL,
    });
  };

  getAllImages = async (memberId: number) => {
    return await request({
      method: "GET",
      url: `/storage/all-by-member/${memberId}`,
      baseURL: process.env.REACT_APP_NEW_API_URL,
    });
  };
}
