import React, { FunctionComponent, useEffect, useState } from "react";

import { FieldDetailsCard } from "../../../components/FieldDetailsCard/FieldDetailsCard";
import { RouteComponentProps, withRouter } from "react-router";
import { FieldDetailsAlertsBox } from "../../../components/FieldDetailsAlertsBox/FieldDetailsAlertsBox";
import { MemberCard } from "../../../components/MemberCard/MemberCard";

import withLoaderHOC, {
  WithLoaderProps,
} from "../../../utils/hocs/WithLoaderHOC";
import { Loader } from "../../../components/Loader/Loader";

import { useServices } from "../../../services/service-providers/service-provider";
import { WithTranslation, withTranslation } from "react-i18next";
import { isMsgError } from "../../../lib/error";
import moment from "moment";
import { MemberEvent } from "../../../lib/utils/member-event.utils";
import { Link } from "react-router-dom";
import NANDI_URL from "../../../lib/url";
import { Modal, useModal } from "../../../components/Modal/Modal.component";
import { Icon } from "../../../components/Icon";
import { Table, TableRow } from "../../../admin-site/components/Table/Table";
import { ArrowBackIcon, CheckMarkIcon } from "../../../assets";

import * as Historic from "../../../admin-site/pages/cattle/layout/historic";
import { MemberDetailPhoto } from "./Photo/MemberDetailPhoto";

type MemberDetailPageState = {
  member: MemberWithDetails | null;
  memberEvents: Array<MemberEvent> | null;
  pedometerEvents: Array<MemberEvent> | null;
  contactWithDrinkerEvents: Array<MemberEvent> | null;
  vaccinationEvents: Array<MemberEvent> | null;
  weightEvents: Array<MemberEvent> | null;
  pregnancyEvents: Array<MemberEvent> | null;
  sensorPairingEvents: Array<MemberEvent> | null;
  paddockAssignmentEvents: Array<MemberEvent> | null;
};

interface MatchParams {
  id: string;
  memberId: string;
}

type Props = RouteComponentProps<MatchParams> &
  WithTranslation &
  WithLoaderProps;

const HistoryButton = ({
  text,
  iconName,
  onClick,
}: {
  text?: any;
  iconName: string;
  onClick: () => void;
}) => {
  return (
    <>
      <div
        className="shadow p-2 flex flex-col items-center rounded cursor-pointer"
        onClick={onClick}
      >
        <Icon className="fill-current text-pink-600" iconName={iconName} />
        {text ? (
          <span
            className="mt-2"
            style={{ fontSize: "10px", textAlign: "center" }}
          >
            {text}
          </span>
        ) : null}
      </div>
    </>
  );
};

const MemberDetailPage: FunctionComponent<Props> = ({
  match,
  isLoading,
  history,
  t,
  setLoading,
  setError,
}) => {
  const [state, setState] = useState<MemberDetailPageState>({
    member: null,
    memberEvents: null,
    pedometerEvents: null,
    contactWithDrinkerEvents: null,
    vaccinationEvents: null,
    weightEvents: null,
    pregnancyEvents: null,
    sensorPairingEvents: null,
    paddockAssignmentEvents: null,
  });
  const { membersService } = useServices();
  const profilePictureModal = useModal();
  const vaccinesHistoryModal = useModal();
  const weightsHistoryModal = useModal();
  const paddockAssignmentModal = useModal();
  const sensorPairingModal = useModal();
  const pregnancyModal = useModal();

  const [expandedEvent, setExpandedEvent] = useState<{
    memberEvent: MemberEvent;
    memberEventAttributes: MemberEventAttribute[];
  } | null>(null);
  const [expandedRow, setExpandedRow] = useState<number>(-1);

  const handleRowOnClose = () => {
    setExpandedEvent(null);
    setExpandedRow(-1);
  };

  const handleRowOnClick = async (mEvent: MemberEvent) => {
    setExpandedRow(mEvent.id);
    setExpandedEvent({
      memberEvent: mEvent,
      memberEventAttributes: mEvent.attributes,
    });
  };

  const handleModalClose = () => {
    setExpandedEvent(null);
    setExpandedRow(-1);
  };

  const handleVaccineModalClose = () => {
    handleModalClose();
    vaccinesHistoryModal.setIsVisible(false);
  };

  const handleWeightModalClose = () => {
    handleModalClose();
    weightsHistoryModal.setIsVisible(false);
  };

  const handlePregnancyModalClose = () => {
    handleModalClose();
    pregnancyModal.setIsVisible(false);
  };

  const isExpanded = expandedRow !== -1;

  const getMemberById = async () => {
    const memberId = match.params.memberId;
    setLoading(true);
    try {
      const member = await membersService.getMemberByIdV2(Number(memberId));
      const memberEvents = await membersService.getMemberEventsDisk(memberId);

      const pedometer = MemberEvent.getByType(
        memberEvents,
        "PEDOMETER_BY_ACC"
      ).sort(MemberEvent.sortByTs);

      const sensorPairingEvents = MemberEvent.getByType(
        memberEvents,
        "SENSOR_PAIRING"
      ).sort(MemberEvent.sortByTs);

      const pedometerEvents: MemberEvent[] = Object.values(
        pedometer.reduce((a, c) => {
          const startOfDay = moment.unix(c.ts).startOf("day").unix();
          a[startOfDay] = a[startOfDay] || {
            timestamp: startOfDay,
            value: c.value,
          };
          a[startOfDay].value = c.value;

          return a;
        }, Object.create(null))
      );

      const contactWithDrinker = MemberEvent.getByType(
        memberEvents,
        "RSSI_ACCURACY_DETECTED"
      ).sort(MemberEvent.sortByTs);

      const vaccinationEvents = MemberEvent.getByType(
        memberEvents,
        "VACCINATION"
      ).sort(MemberEvent.sortByTs);

      const weightEvents = MemberEvent.getByType(memberEvents, "WEIGHT").sort(
        MemberEvent.sortByTs
      );

      const contactWithDrinkerEvents: MemberEvent[] = Object.values(
        contactWithDrinker.reduce((a, c) => {
          const startOfDay = moment.unix(c.ts).startOf("day").unix();
          a[startOfDay] = a[startOfDay] || {
            timestamp: startOfDay,
            value: 0,
          };
          if (c.value === "IMMEDIATE") {
            a[startOfDay].value++;
          }
          return a;
        }, Object.create(null))
      );

      const contactWithCowAggrEvents =
        member.member_type === "BULL"
          ? MemberEvent.getByType(memberEvents, "CONTACT_WITH_COW_AGGR").sort(
              MemberEvent.sortByTs
            )
          : null;

      const contactWithBullAggrEvents =
        member.member_type === "COW"
          ? MemberEvent.getByType(memberEvents, "CONTACT_WITH_BULL_AGGR").sort(
              MemberEvent.sortByTs
            )
          : null;
      const pregnancyEvents =
        member.member_type === "COW"
          ? MemberEvent.getByType(memberEvents, "PREGNANCY").sort(
              MemberEvent.sortByTs
            )
          : [];
      const paddockAssignmentEvents = MemberEvent.getByType(
        memberEvents,
        "PADDOCK_ASSIGNMENT"
      ).sort(MemberEvent.sortByTs);
      setState({
        member,
        memberEvents,
        pedometerEvents,
        contactWithDrinkerEvents,
        vaccinationEvents,
        weightEvents,
        pregnancyEvents,
        paddockAssignmentEvents,
        sensorPairingEvents,
      });
    } catch (error) {
      if (isMsgError(error)) setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMemberById();
  }, []);

  const goToAllAlertsPage = (): void => {
    history.push(`${match.url}/alerts`);
  };

  if (!state.member || isLoading) {
    return (
      <div className="p-4">
        <Loader isLoading={isLoading}>
          <></>
        </Loader>
      </div>
    );
  }

  return (
    <>
      {vaccinesHistoryModal.isVisible && (
        <Modal onClose={handleVaccineModalClose}>
          <Historic.Main
            displayButtons={!isExpanded}
            onAdd={() => console.log("adding")}
            onClose={handleVaccineModalClose}
          >
            <Historic.Title>Vacunas</Historic.Title>
            {state.vaccinationEvents && !isExpanded ? (
              <Table
                className="mt-4"
                columns={Historic.vaccinationTableConstants()}
                items={state.vaccinationEvents}
                renderTableRow={false}
              >
                {state.vaccinationEvents.map((vaccinationEvent) => (
                  <TableRow
                    key={vaccinationEvent.id}
                    onClick={() => handleRowOnClick(vaccinationEvent)}
                  >
                    {Historic.vaccinationTableConstants().map((col, key) => (
                      <td key={key}>{col.render(vaccinationEvent)}</td>
                    ))}
                  </TableRow>
                ))}
              </Table>
            ) : null}
            {isExpanded ? (
              <section>
                <ArrowBackIcon
                  className="absolute top-4 left-4 cursor-pointer w-[24px] h-[24px] fill-current text-gray-400"
                  onClick={handleRowOnClose}
                />
                {expandedEvent?.memberEvent ? (
                  <>
                    <Historic.Attribute
                      label={t(`admin.cattlePage.attributes.vaccines.VACCINE`)}
                      value={expandedEvent.memberEvent.value}
                    />
                    <Historic.Attribute
                      label={t(`admin.cattlePage.attributes.vaccines.DATE`)}
                      value={moment
                        .unix(expandedEvent.memberEvent.ts)
                        .format("DD/MM/YYYY")}
                    />
                  </>
                ) : null}
                {expandedEvent?.memberEventAttributes.map(
                  ({ attribute, value }) => (
                    <Historic.Attribute
                      label={t(
                        `admin.cattlePage.attributes.vaccines.${attribute}`
                      )}
                      value={value}
                    />
                  )
                )}
              </section>
            ) : null}
          </Historic.Main>
        </Modal>
      )}
      {weightsHistoryModal.isVisible && (
        <Modal onClose={handleWeightModalClose}>
          <Historic.Main
            displayButtons={!isExpanded}
            onAdd={() => console.log("adding")}
            onClose={handleWeightModalClose}
          >
            <Historic.Title>Pesajes</Historic.Title>
            {state.weightEvents && !isExpanded ? (
              <Table
                className="mt-4"
                columns={Historic.weightTableConstants()}
                items={state.weightEvents}
                renderTableRow={false}
              >
                {state.weightEvents.map((weightEvent) => (
                  <TableRow
                    key={weightEvent.id}
                    onClick={() => handleRowOnClick(weightEvent)}
                  >
                    {Historic.weightTableConstants().map((col, key) => (
                      <td key={key}>{col.render(weightEvent)}</td>
                    ))}
                  </TableRow>
                ))}
              </Table>
            ) : null}
            {isExpanded ? (
              <section>
                <ArrowBackIcon
                  className="absolute top-4 left-4 cursor-pointer w-[24px] h-[24px] fill-current text-gray-400"
                  onClick={handleRowOnClose}
                />
                {expandedEvent?.memberEvent ? (
                  <>
                    <Historic.Attribute
                      label={t(`admin.cattlePage.attributes.weights.WEIGHT`)}
                      value={expandedEvent.memberEvent.value}
                    />
                    <Historic.Attribute
                      label={t(`admin.cattlePage.attributes.weights.DATE`)}
                      value={moment
                        .unix(expandedEvent.memberEvent.ts)
                        .format("DD/MM/YYYY")}
                    />
                  </>
                ) : null}
                {expandedEvent?.memberEventAttributes.map(
                  ({ attribute, value }) => (
                    <Historic.Attribute
                      label={t(
                        `admin.cattlePage.attributes.weights.${attribute}`
                      )}
                      value={value}
                    />
                  )
                )}
              </section>
            ) : null}
          </Historic.Main>
        </Modal>
      )}
      {paddockAssignmentModal.isVisible && (
        <Modal onClose={() => paddockAssignmentModal.setIsVisible(false)}>
          <Historic.Main
            onAdd={() => console.log("adding")}
            onClose={() => paddockAssignmentModal.setIsVisible(false)}
          >
            <Historic.Title>Potreros</Historic.Title>
            {state.paddockAssignmentEvents ? (
              <Table
                className="mt-4"
                columns={Historic.paddocksTableConstants()}
                items={state.paddockAssignmentEvents}
              />
            ) : null}
          </Historic.Main>
        </Modal>
      )}
      {sensorPairingModal.isVisible && (
        <Modal onClose={() => sensorPairingModal.setIsVisible(false)}>
          <Historic.Main
            onAdd={() => console.log("adding")}
            onClose={() => sensorPairingModal.setIsVisible(false)}
            displayButtons={false}
          >
            <Historic.Title>Sensores</Historic.Title>
            {state.sensorPairingEvents ? (
              <Table
                className="mt-4"
                columns={Historic.sensorPairingTableConstants()}
                items={state.sensorPairingEvents}
              />
            ) : null}
          </Historic.Main>
        </Modal>
      )}
      {state.member.member_type === "COW" && pregnancyModal.isVisible && (
        <Modal onClose={handlePregnancyModalClose}>
          <Historic.Main
            onAdd={() => console.log("adding")}
            onClose={handlePregnancyModalClose}
            displayButtons={!isExpanded}
          >
            <Historic.Title>Testeos de Preñez</Historic.Title>
            {state.pregnancyEvents && !isExpanded ? (
              <Table
                className="mt-4"
                columns={Historic.pregnancyTableConstants()}
                items={state.pregnancyEvents}
                renderTableRow={false}
              >
                {state.pregnancyEvents.map((event) => (
                  <TableRow
                    key={event.id}
                    onClick={() => handleRowOnClick(event)}
                  >
                    {Historic.pregnancyTableConstants().map((col, key) => (
                      <td key={key}>{col.render(event)}</td>
                    ))}
                  </TableRow>
                ))}
              </Table>
            ) : null}
            {isExpanded ? (
              <section>
                <ArrowBackIcon
                  className="absolute top-4 left-4 cursor-pointer w-[24px] h-[24px] fill-current text-gray-400"
                  onClick={handleRowOnClose}
                />
                {expandedEvent?.memberEvent ? (
                  <>
                    <Historic.Attribute
                      label={t(
                        `admin.cattlePage.attributes.pregnancy.PREGNANCY`
                      )}
                      value={expandedEvent.memberEvent.value}
                    />
                    <Historic.Attribute
                      label={t(`admin.cattlePage.attributes.pregnancy.DATE`)}
                      value={moment
                        .unix(expandedEvent.memberEvent.ts)
                        .format("DD/MM/YYYY")}
                    />
                  </>
                ) : null}
                {expandedEvent?.memberEventAttributes.map(
                  ({ attribute, value }) => (
                    <Historic.Attribute
                      label={t(
                        `admin.cattlePage.attributes.pregnancy.${attribute}`
                      )}
                      value={value}
                    />
                  )
                )}
              </section>
            ) : null}
          </Historic.Main>
        </Modal>
      )}
      <div className="p-4">
        {state.member ? <MemberCard member={state.member} /> : null}
        {state.member.member_type === "COW" &&
        state.member.status === "HEAT" ? (
          <p
            className="mt-4 w-fit bg-red-700 rounded p-2 pt-1 pb-1 text-white"
            title="This means this cattle unit is currently in heat."
          >
            Cow in Heat
          </p>
        ) : null}
        {state.member ? (
          <section className="w-full m-auto mt-4 grid grid-cols-5 gap-2 content-center">
            <HistoryButton
              text={state.member.fields?.name}
              iconName="map"
              onClick={() => paddockAssignmentModal.setIsVisible(true)}
            />
            <HistoryButton
              text="Vaccines"
              iconName="vaccine"
              onClick={() => vaccinesHistoryModal.setIsVisible(true)}
            />
            {state.member.weight ? (
              <HistoryButton
                text={`${state.member.weight} kgs`}
                iconName="weight"
                onClick={() => weightsHistoryModal.setIsVisible(true)}
              />
            ) : null}
            <HistoryButton
              text={state.member.sensors?.name}
              iconName="sensor"
              onClick={() => sensorPairingModal.setIsVisible(true)}
            />
            {state.member.member_type === "COW" ? (
              <HistoryButton
                text="Pregnancy"
                iconName="medical"
                onClick={() => pregnancyModal.setIsVisible(true)}
              />
            ) : null}
          </section>
        ) : null}
        <MemberDetailPhoto member={state.member} />
        <div className="mt-4">
          <div className="font-semibold">
            {t("memberDetailsPage.alertsTitle")}
          </div>
        </div>
        <div className="mt-4">
          <div className="font-semibold">
            {t("memberDetailsPage.todayTitle")}
          </div>
          <div>
            {state.pedometerEvents && state.pedometerEvents.length > 0 ? (
              <Link
                to={`${NANDI_URL.APP.GENERATE_MEMBER_GRAPH_BY_ID(
                  String(state.member.id),
                  "walking"
                )}`}
              >
                <FieldDetailsCard
                  className="mt-2"
                  memberInteraction={{
                    type: "walking",
                    count:
                      state.pedometerEvents?.[state.pedometerEvents.length - 1]
                        .value,
                  }}
                />
              </Link>
            ) : null}
            {state.contactWithDrinkerEvents &&
            state.contactWithDrinkerEvents.length > 0 ? (
              <Link
                to={`${NANDI_URL.APP.GENERATE_MEMBER_GRAPH_BY_ID(
                  String(state.member.id),
                  "drink"
                )}`}
              >
                <FieldDetailsCard
                  className="mt-2"
                  memberInteraction={{
                    type: "drink",
                    count:
                      state.contactWithDrinkerEvents[
                        state.contactWithDrinkerEvents.length - 1
                      ].value,
                  }}
                />
              </Link>
            ) : null}
            <FieldDetailsCard
              className="mt-2"
              memberInteraction={{
                type: "contact",
                count: 0,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(withRouter(withLoaderHOC(MemberDetailPage)));
