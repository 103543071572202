import React, { FunctionComponent } from "react";
import { Table, TableColumn, TableRow } from "../../components/Table/Table";
import { useTranslation } from "react-i18next";

type Props = {
  fieldsInventory: Array<GetInventory>;
};

const tableConstants: Array<TableColumn> = [
  {
    title: "admin.inventoryPage.paddock",
    key: "field_name",
    render: (fieldInventory: GetInventory) => {
      return <>{fieldInventory.field_name}</>;
    },
  },
  {
    title: "admin.inventoryPage.cattle",
    key: "member_type",
    render: (fieldInventory: GetInventory) => {
      return <>{fieldInventory.member_type}</>;
    },
  },
  {
    title: "admin.inventoryPage.quantity",
    key: "quantity",
    render: (fieldInventory: GetInventory) => {
      return <>{fieldInventory.quantity}</>;
    },
  },
];

const FieldsInventoryTable: FunctionComponent<Props> = ({
  fieldsInventory,
}) => {
  const { t } = useTranslation();
  const tableConstantsTranslate = tableConstants.map((item) => {
    return { ...item, title: t(`${item.title}`) };
  });

  const fieldsInventoryTranslate: Array<GetInventory> = fieldsInventory.map(
    (item) => {
      return {
        ...item,
        member_type:
          item.member_type === "BULL"
            ? t("entityTypes.Bulls")
            : item.member_type === "COW"
            ? t("entityTypes.Cows")
            : item.member_type,
      };
    }
  );

  return (
    <main>
      <Table
        className="mt-32 mb-32"
        columns={tableConstantsTranslate}
        renderTableRow={false}
        items={fieldsInventory}
        isPaginationEnabled={false}
      >
        {fieldsInventoryTranslate.map(
          (fieldInventory: GetInventory, index: number) => (
            <TableRow key={index}>
              {tableConstantsTranslate.map((col, key) => (
                <td key={key}>{col.render(fieldInventory)}</td>
              ))}
            </TableRow>
          )
        )}
      </Table>
    </main>
  );
};

export default FieldsInventoryTable;
