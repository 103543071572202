import moment from "moment";
import { Color } from "./color";

const Chart = {
  getAvailableChartsPerCattle: (
    member: Member,
    t: any,
    isSuperUser: boolean
  ) => {
    const options = [
      {
        value: "steps-v3",
        displayValue: t("admin.cattlePage.chartOptions.activity2"),
      },
      {
        value: "steps",
        displayValue: t("admin.cattlePage.chartOptions.activity"),
      },
      {
        value: "alerts",
        displayValue: t("admin.cattlePage.chartOptions.alerts"),
      },
      {
        value: "timeline",
        displayValue: t("admin.cattlePage.chartOptions.timeline"),
      },
    ];

    const getConditionalOptions = () => {
      let conditionalOptions = [];
      if (isSuperUser) {
        conditionalOptions.push({
          value: "contacts-with-cattle",
          displayValue:
            member.member_type === "BULL"
              ? t("admin.cattlePage.chartOptions.cowsContacts")
              : t("admin.cattlePage.chartOptions.bullContacts"),
        });
      }
      if (member.member_type === "BULL") {
        conditionalOptions.push({
          value: "daily-bull-jumps",
          displayValue: t("admin.cattlePage.cattleID.bullJumps"),
        });
      }
      return conditionalOptions;
    };

    return {
      type: "select",
      label: "chartOnDisplay",
      options: [...options].concat(getConditionalOptions()),
    };
  },

  getEventsDates: (memberEvents: Array<MemberEvent>) => {
    return {
      timestamps: memberEvents.map((x) => x.ts),
      formated: memberEvents.map((x) => moment.unix(x.ts).format("DD/MM/YYYY")),
    };
  },
  getAtoBDates: () => {
    return {
      timestamps: [1644980400, 1645066800, 1645153200, 1645239600],
      formated: ["16/02/2022", "17/02/2022", "18/02/2022", "19/02/2022"],
    };
  },
  generateDatasetsFromLinearMemberEvents: (
    memberEvents: Array<MemberEvent>,
    label: string,
    options?: {
      backgroundColorFn: (
        mEvent: MemberEvent,
        mEvents: Array<MemberEvent>
      ) => string;
    }
  ) => {
    return {
      label: label,
      data: memberEvents.map((mEvent) => Number(mEvent.value)),
      backgroundColor: options?.backgroundColorFn
        ? memberEvents.map((mEvent) =>
            options.backgroundColorFn(mEvent, memberEvents)
          )
        : Color.generateUniqueColor(),
    };
  },
};

export { Chart };
