import React, { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Loader } from "../../../components/Loader/Loader";
import { useServices } from "../../../services/service-providers/service-provider";
import { RootState } from "../../../store/store";
import { Alert } from "../../../types/alerts";
import { User, UserFilter, UserRole } from "../../../types/auth";
import withLoaderHOC, {
  WithLoaderProps,
} from "../../../utils/hocs/WithLoaderHOC";
import { TableColumn } from "../../components/Table/Table";
import { AlertsPage } from "./layout/AlertsPage";
import moment from "moment";
import { Button, Tooltip } from "@material-ui/core";
import { alertTypeTooltipStyles } from "../../MUI-styles/alertTooltip";
import NANDI_URL from "../../../lib/url";

export const getAlertsTableColumnsSummary = (t: any): Array<TableColumn> => {
  return [
    {
      title: "#",
      key: "id",
      render: (alert: Alert) => <>#{alert.id}</>,
    },
    {
      title: "eid",
      key: "eid",
      render: (alert: Alert) => <>{alert.member.tag_id}</>,
    },
    {
      title: t("alertsTable.statusTitle"),
      key: "status",
      render: (alert: Alert) => (
        <>{t(`alertsTable.status.${alert.current_status}`)}</>
      ),
    },
    {
      title: t("alertsTable.typeTitle"),
      key: "type",
      render: (alert: Alert) => (
        <>
          <Tooltip
            title={`${t(
              `alertsTable.description.${alert.alert_type} - ${alert.entity_type}`,
              { fieldName: alert.field?.name ?? " - " }
            )}`}
            arrow
          >
            <Button>{alert.alert_type}</Button>
          </Tooltip>
        </>
      ),
    },
  ];
};

export const getAlertsTableColumns = (
  t: any,
  alertTypeStyles: any
): Array<TableColumn> => {
  return [
    {
      title: "#",
      key: "id",
      render: (alert: Alert) => <>#{alert.id}</>,
    },
    {
      title: t("alertsTable.eid"),
      key: "eid",
      render: (alert: Alert) => (
        <>{alert.member ? <>{alert.member.tag_id}</> : <p>-</p>}</>
      ),
    },
    {
      title: t("alertsTable.statusTitle"),
      key: "status",
      render: (alert: Alert) => (
        <>{t(`alertsTable.status.${alert.current_status}`)}</>
      ),
    },
    {
      title: t("alertsTable.typeTitle"),
      key: "type",
      render: (alert: Alert) => (
        <p className="text-left">{t(`alertTypes.${alert.alert_type}`)}</p>
      ),
    },
    {
      title: t("alertsTable.batteryStatus.title"),
      key: "battery",
      render: (alert: Alert) => {
        const batery = alert?.member?.sensors?.battery;
        return (
          <div className="flex justify-center">
            {alert.member === null || batery < 20 ? (
              <div className="bg-[#FEEBEB] rounded-[10px] py-[2px] px-2 text-[#E10E0E] text-[13px] font-medium">
                {t("alertsTable.batteryStatus.status.noBattery")}
              </div>
            ) : alert.member !== null && batery < 70 && batery > 20 ? (
              <div className="bg-[#FFFBEB] rounded-[10px] py-[2px] px-2 text-[#D97706] text-[13px] font-medium">
                50%
              </div>
            ) : alert.member !== null && batery > 70 ? (
              <div className="bg-[#DAF8E6] rounded-[10px] py-[2px] px-2 text-[#1A8245] text-[13px] font-medium">
                OK
              </div>
            ) : null}
          </div>
        );
      },
    },
    {
      title: t("alertsTable.dateTitle"),
      key: "date",
      render: (alert: Alert) => {
        const date = moment(alert.date).toDate();
        return <>{moment(date, "MMM D, YYYY").format("MMM D, YYYY")}</>;
      },
    },
    {
      title: t("alertsTable.lastUpdatedDateTitle"),
      key: "last_updated_date",
      render: (alert: Alert) => {
        const lastUpdateDate = moment(alert.last_updated_date).toDate();
        return (
          <>{moment(lastUpdateDate, "MMM D, YYYY").format("MMM D, YYYY")}</>
        );
      },
    },
    {
      title: t("alertsTable.action"),
      key: "action",
      render: (alert: Alert) => {
        const lastUpdateDate = moment(alert.last_updated_date).toDate();
        return (
          <Link
            to={NANDI_URL.ADMIN.GENERATE_CATTLE_BY_ID(String(alert.entity_id))}
            className="border-solid border-[1px] border-[#E2E8F0] rounded-md text-sm font-medium text-[#0F172A] p-2"
          >
            {t("alertsTable.cta.view")}
          </Link>
        );
      },
    },
  ];
};

const getAlertTypeOptions = (t: any) => {
  return [
    { value: "all", displayValue: t("alertsTable.typeFilter.options.all") },
    {
      value: "CATTLE_WITHOUT_EVENTS_48_HS",
      displayValue: t("alertsTable.typeFilter.options.cattleWithoutEvents48Hs"),
    },
    {
      value: "CATTLE_LOW_ACTIVITY_24_HS",
      displayValue: t("alertsTable.typeFilter.options.cattleWithoutEvents24Hs"),
    },
  ];
};

const getAlertStatusOptions = (t: any) => {
  return [
    { value: "all", displayValue: t("alertsTable.statusFilter.options.all") },
    {
      value: "CLOSED",
      displayValue: t("alertsTable.statusFilter.options.closed"),
    },
    {
      value: "CREATED",
      displayValue: t("alertsTable.statusFilter.options.created"),
    },
    {
      value: "SOLVED",
      displayValue: t("alertsTable.statusFilter.options.solved"),
    },
    {
      value: "RENEWED",
      displayValue: t("alertsTable.statusFilter.options.renewed"),
    },
  ];
};

type CurrentType = { value: string | undefined; displayValue: string };

type AlertsPageState = {
  alerts: Array<Alert>;
  totalAlerts: number;
  users: Array<User>;
};

export const useAlerts = () => {
  const { t } = useTranslation();
  const alertTypeStyles = alertTypeTooltipStyles();
  const title = t("alertsTable.title");
  const noAlertsMessage = t("alertsTable.noAlerts");
  const alertsTableColumns = getAlertsTableColumns(t, alertTypeStyles);
  return { title, noAlertsMessage, alertsTableColumns };
};

const AlertsPageConnector: FunctionComponent<
  WithLoaderProps & PropsFromRedux
> = ({ selectedAccount, isLoading, setLoading, user }) => {
  const [state, setState] = useState<AlertsPageState>({
    alerts: [],
    totalAlerts: 0,
    users: [],
  });

  const { alertsService, usersService } = useServices();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const alertType = searchParams.get("alert_type");
  const { t } = useTranslation();
  const typeFilterInitValue: string =
    getAlertTypeOptions(t).filter((x) => x.value === alertType)?.[0]
      ?.displayValue ?? getAlertTypeOptions(t)[0]?.displayValue;

  const [typeFilter, setTypeFilter] = useState<CurrentType>({
    value: alertType ?? undefined,
    displayValue: typeFilterInitValue,
  });

  const currentStatus = searchParams.get("current_status")?.split(" ");

  const statusFilterInitValue =
    getAlertStatusOptions(t).filter((x) => x.value === currentStatus?.[0])?.[0]
      ?.displayValue ?? getAlertStatusOptions(t)[0].displayValue;

  const [statusFilter, setStatusFilter] = useState<CurrentType>({
    value: currentStatus?.[0] ?? undefined,
    displayValue: statusFilterInitValue,
  });

  const [pageNumber, setPageNumber] = useState<number>(1);
  const alertsPerPage = 10;
  const alertTypeStyles = alertTypeTooltipStyles();

  const fieldId = searchParams.get("field_id");

  const alertsTableColumns = () => {
    return getAlertsTableColumns(t, alertTypeStyles);
  };

  const requestParams = {
    account_id: selectedAccount.id,
    alert_type: alertType,
    field_id: fieldId,
    current_status: statusFilter.value,
    take: alertsPerPage,
    skip: (pageNumber - 1) * alertsPerPage,
  };

  const filteredRequestParams = Object.entries(requestParams).reduce(
    (obj: any, [key, value]) => {
      if (value !== null && value !== "all" && value) {
        obj[key] = value;
      }
      return obj;
    },
    {}
  );

  const loadPageInfo = async () => {
    setLoading(true);
    const alertsData = await alertsService.getAlertsV2({
      ...filteredRequestParams,
    });

    const totalAlerts = await alertsService.getAlertsCount({
      ...filteredRequestParams,
    });

    const usersData = await usersService.getUsers(
      UserFilter.CAN_RECEIVE_ALERTS,
      selectedAccount.id
    );

    setState((prevState) => ({
      ...prevState,
      totalAlerts: totalAlerts.count,
      alerts: alertsData,
      users: usersData,
    }));
    setLoading(false);
  };

  useEffect(() => {
    loadPageInfo();
  }, [selectedAccount.id, typeFilter, statusFilter, pageNumber]);

  return (
    <Loader isLoading={isLoading}>
      <AlertsPage
        totalPages={Math.ceil(state.totalAlerts / alertsPerPage)}
        totalAlerts={state.totalAlerts}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        title={t("alertsTable.title")}
        alertsTableColumns={alertsTableColumns()}
        alerts={state.alerts}
        noAlertsMessage={t("alertsTable.noAlerts")}
        currentType={typeFilter}
        setCurrentType={setTypeFilter}
        typeOptions={getAlertTypeOptions(t)}
        currentStatus={statusFilter}
        setCurrentStatus={setStatusFilter}
        statusOptions={getAlertStatusOptions(t)}
      />
    </Loader>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    selectedAccount: {
      ...state.accounts.selectedAccount,
      id: String(state.accounts.selectedAccount.id),
    },
    user: state.users.user,
  };
};

const mapDispatchToProps = () => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export const AlertsPageContainer = connector(
  withLoaderHOC(AlertsPageConnector)
);
