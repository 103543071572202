import request from "./request";
import { IAgroService } from "./services-types";

export class AgroService implements IAgroService {
  getByPolygonId = async (
    polygonId: string,
    startDate: number,
    endDate: number
  ): Promise<AgroStatsInfo[]> => {
    return await request({
      method: "GET",
      url: `/agro/${polygonId}`,
      params: { startDate, endDate },
      baseURL: process.env.REACT_APP_NEW_API_URL,
    });
  };
}
