import React, { FunctionComponent } from "react";

import { Bar } from "react-chartjs-2";

type Props = {
  pedometerEvents?: MemberEvent[];
  stacked?: boolean;
  labels: string[];
  datasets: {
    data: number[];
    label: string;
    backgroundColor: string | string[];
  }[];
};

const ActivityChartComponent: FunctionComponent<Props> = ({
  labels,
  datasets,
  stacked,
}) => (
  <Bar
    options={{
      responsive: true,
      maintainAspectRatio: false,
      scales: stacked
        ? { x: { stacked: true }, y: { stacked: true } }
        : undefined,
    }}
    className="mt-4 max-h-[350px]"
    data={{
      labels: labels,
      datasets: datasets,
    }}
  />
);

export default ActivityChartComponent;
