import React, { FunctionComponent } from "react";
import { User } from "../../../../types/auth";

type Account = {
  id: number;
  name: string;
  disabled: boolean;
  demo: boolean;
};

type Props = {
  accounts: Account[];
  onChange: any;
  user: User;
};
const SelectAccountComponent: FunctionComponent<Props> = ({
  accounts,
  onChange,
  user,
}) => {
  return (
    <div>
      <select id="accounts" onChange={onChange}>
        {accounts.map((account) => {
          return (
            <option
              value={account.id}
              key={account.id}
              selected={account.id === user.account.id}
            >
              {account.name}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default SelectAccountComponent;
