import React, { useContext, useEffect, useState } from "react";
import { FunctionComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, useLocation } from "react-router-dom";
import GauchoAppContainer from "../../components/GauchoApp";
import { FooterGauchoApp } from "../../components/GauchoApp/FooterGauchoApp/FooterGauchoApp";
import { HeaderGauchoApp } from "../../components/GauchoApp/HeaderGauchoApp/HeaderGauchoApp";
import { Loader } from "../../components/Loader/Loader";
import { isMsgError } from "../../lib/error";
import { ServicesContext } from "../../services/service-providers/service-provider";
import { RootState } from "../../store/store";
import { FieldSummaryWithExtraDetails } from "../../types/fields";
import withLoaderHOC, { WithLoaderProps } from "../../utils/hocs/WithLoaderHOC";
import "./GauchoApp.scss";

type GauchoAppPageState = {
  rodeos: Array<Rodeo>;
  fields: Array<FieldSummaryWithExtraDetails>;
};

interface MatchParams {
  action: string;
}

type Props = WithTranslation &
  WithLoaderProps &
  PropsFromRedux &
  RouteComponentProps<MatchParams>;

const initialState: GauchoAppPageState = {
  rodeos: [],
  fields: [],
};

const GauchoApp: FunctionComponent<Props> = ({
  match,
  isLoading,
  user,
  t,
  setLoading,
  setError,
}) => {
  const { rodeoService, fieldsService } = useContext(ServicesContext);
  const [state, setState] = useState(initialState);

  const getRodeos = async () => {
    try {
      const rodeos = await rodeoService.getRodeo(String(user?.account_id), "");
      return rodeos;
    } catch (error) {
      if (isMsgError(error)) setError(error);
      console.log(error);
    }
  };

  const getFields = async () => {
    try {
      const fields = await fieldsService.getFields(String(user?.account_id));
      return fields;
    } catch (error) {
      if (isMsgError(error)) setError(error);
      console.log(error);
    }
  };

  const fillData = async () => {
    let rodeos = await getRodeos();
    let fields = await getFields();
    rodeos &&
      fields &&
      setState({
        ...state,
        rodeos: rodeos,
        fields: fields,
      });
    setLoading(false);
  };
  useEffect(() => {
    fillData();
  }, []);

  return (
    <>
      <HeaderGauchoApp />
      <div className="gauchoApp_page">
        <Loader isLoading={isLoading}>
          {state.fields.length > 0 && (
            <GauchoAppContainer
              name={user?.full_name || ""}
              rodeos={state.rodeos}
              fields={state.fields}
              action={match.params.action}
            />
          )}
        </Loader>
      </div>

      <FooterGauchoApp />
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    user: state.users.user,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedGauchoAppPage = connector(withLoaderHOC(GauchoApp));

export default withTranslation()(ConnectedGauchoAppPage);
